import React from "react";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import { NavLink } from "react-router-dom";
import { Plus } from "react-iconly";
import AdsTable from "./AdsTable/AdsTable";

function AdsAdmin() {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit container-profile-article">
                <h1 className="mon-profil">Ads</h1>
                <NavLink to="/admin/ads-add" className="btn btn-add-listing">
                  <Plus set="light" primaryColor="#B0B0B0" />
                  <span className="pl-2">Add ads</span>
                </NavLink>
              </div>
              <div className="users-table">
                <AdsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsAdmin;
