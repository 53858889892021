import axios from "axios";
import { docsUrl, slider } from "./Endpoints";

export default {
  async onAddSlider(data, config) {
    const response = await axios.post(slider.onPostSlider(), data, config);
    return response.data;
  },
  async onEditSlider(id, data, config) {
    const response = await axios.put(slider.onPutSlider(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteSlider(id, config) {
    const response = await axios.delete(slider.deleteSlider(id), config);
    // console.log(response)
    return response.data;
  },
};

export const DocumentService = {
  async onAdd(data, config) {
    const response = await axios.post(docsUrl.list(), data, config);
    return response.data;
  },
  async onGet(projectId, config) {
    const response = await axios.get(docsUrl.showProject(projectId), config);
    return response.data;
  },
  async onEdit(id, data, config) {
    const response = await axios.put(docsUrl.show(id), data, config);
    return response.data;
  },
  async onDelete(id, config) {
    const response = await axios.delete(docsUrl.show(id), config);
    return response.data;
  },
};
