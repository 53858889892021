import api from "../../Http/global-vars";
import { FETCH_AMENETIES, FETCH_AMENETIES_FRONT } from "../types";

export const ameneties = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "amenetie/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_AMENETIES,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const amenetiesToFront = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "amenetievisitor/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data results", data.results)
        dispatch({
          type: FETCH_AMENETIES_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
