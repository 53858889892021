import api from "../../Http/global-vars";
import { FETCH_PROPERTY_REVIEW, FETCH_REVIEWS } from "../types";

export const fetchReviews = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "review/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_REVIEWS,
          payload: data.results,
          // ,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const fetchReviewsByProperty = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "reviewbyproperty/" + id;

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_PROPERTY_REVIEW,
          payload: data,
          // ,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

// export const fetchReviewsProjectsFront = (id: number) => {

//   return async (dispatch: (arg0: { type: string; payload: any }) => void) => {

//     const url = api + "reviewbyproject/" + id;

//     await fetch(url)
//       .then(async (res) => {
//         const data = await res.json();
//         dispatch({
//           type: FETCH_REVIEWS_PROJECTS_FRONT,
//           payload: data,
//           // ,
//         });
//       })
//       .catch((error) => console.log("error", error?.response));

//   };
// };
