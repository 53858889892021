import api from "../../Http/global-vars";
import { LocationFormData } from "../../repositories/user/LocationFormData";
import { FETCH_TAFDATA_ADMIN, FETCH_TAFDATA_FRONT } from "../types";

export const fetchTafdata = () => {
  return async (
    dispatch: (arg0: { type: string; payload: LocationFormData }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "taf_description/";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_TAFDATA_ADMIN,
            payload: data.results[0],
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchTafdataFront = () => {
  return async (
    dispatch: (arg0: { type: string; payload: LocationFormData }) => void
  ) => {
    const url = api + "taf_descriptionbyvisitor/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_TAFDATA_FRONT,
          payload: data.results[0],
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
