import Axios from "axios";
import api from "../../Http/global-vars";
import ProjetDatas from "../../repositories/user/projets/DatasProjets";
import { DETAIL_PROJECT, FETCH_PROJECTS, UPDATE_PROJECT } from "../types";
import { FilterType } from "../Article/articleAction";

export const fetchProjects = ({
  page = 1,
  limit = 10,
  q = "",
  status = "",
  status_publication = "",
  location = "",
  amount = "",
}: FilterType) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url =
      api +
      `project/?page=${page}&limit=${limit}&projet_name=${q}&status=${status}&publish_status=${status_publication}&location=${location}&amount_requested=${amount}`;
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_PROJECTS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROJECTS,
          payload: { results: [], count: 0 },
        });
      });
  };
};

export const updateProject = (id: ProjetDatas) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "project/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results idididid", data)
          dispatch({
            type: UPDATE_PROJECT,
            payload: data,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const listProjectsDataToTest = async () => {
  try {
    return await Axios.get(`${api}project`);
  } catch (e) {
    return [];
  }
};

export const listProjectsDataFrontToTest = async () => {
  try {
    return await Axios.get(`${api}projectbyvisitor`);
  } catch (e) {
    return [];
  }
};

export const getProjectById = (id: number) => {
  return async (dispatch) => {
    const url = api + "projectbyvisitor/" + id + "/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        console.log("data", data);
        dispatch({
          type: DETAIL_PROJECT,
          payload: data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
