import { FETCH_CONVERT_CODE } from "../types";

export const convertReducer = (state = 1, action) => {
  switch (action.type) {
    case FETCH_CONVERT_CODE:
      return action.payload;
    default:
      return state;
  }
};
