import React from "react";
import "./NotFoundPage.css";
import { useHistory } from "react-router-dom";
import img404 from "./images/Page_not_found.png";
import Header from "./components/header/Header";
import { Helmet } from "react-helmet";
// import baseUrlFront from "./Http/frontend-base-url";

function NotFoundPage() {
  const navigate = useHistory();

  return (
    <div className="component-front-home">
      <Helmet>
        <title>404 Page {`${window.location.pathname}`}</title>
        <link rel="canonical" href={`${window.location.href}`}></link>
      </Helmet>
      <Header />
      <div className="error-page-component">
        <section id="error-page-section">
          <div className="container error-page-container">
            <div className=" error-page-section-row">
              <div className="text-center error-page-section-col">
                <div className="error-page-textual-partial-container">
                  <h1 className="error-page-section-title">NOT FOUND</h1>
                  <div className="error-page-textual-container">
                    <div className="error-page-textual mb-3">
                      <p>
                        Oups! sorry, the page you are looking for does not
                        exist.
                      </p>
                    </div>
                    <div className="error-page-btn-container">
                      <button
                        onClick={() => navigate.push("/")}
                        className="return-to-homepage-btn btn"
                        aria-label="Home"
                      >
                        Home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" error-page-section-col">
                <div className="error-page-code-textual-container">
                  <div>
                    <img
                      src={img404}
                      alt="404"
                      className="img-404"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NotFoundPage;
