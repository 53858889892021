import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../Http/global-vars";
import Axios from "axios";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { PropertyDatas } from "../../../../repositories/user/PropertyDatas";
import { propertyTypes } from "../../../../redux/PropertyType/propertyTypeAction";


function Modalafficherpropertytype({ item }) {


  const dispatch = useDispatch();
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().nullable(),

  });

  const { register, formState, handleSubmit, reset, setValue } =
    useForm<PropertyDatas>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;
  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };

  useEffect(() => {
    if (item?.id) {
      setValue("name", item?.name);
      setValue("description", item?.description);
    }

  }, [item?.description, item?.id, item?.name, setValue])

  useEffect(() => {

    return () => {
      reset();
    };
  }, [reset]);

  const onSubmitUpdateproperty = (data: PropertyDatas) => {
    setIsSuccessfullySubmittedForm(true);
    const request = Axios.put(`${api}property_type/${item?.id}/`, data, config);

    request
      .then((res) => {
        if (res.status === 200) {
          // console.log(res)
          Swal.fire({
            icon: 'success',
            title: "Success",
            html: "Request sent successfully!",
            iconColor: '#df5656',
            showCancelButton: false,
            confirmButtonColor: '#df5656',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(propertyTypes());
              $('.modal').modal('hide');
            }
          });
          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: "Errorr",
          html: "Check your entered data",
          iconColor: '#df5656',
          showCancelButton: false,
          confirmButtonColor: '#df5656',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header modal-faq-header-add-member">
            <h5
              className="modal-title titre-question-reponse"
              id="AfficherpropertyModal"
            >
              Add
              Property Type{" "}
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModalPropertyUpdate"
            >
              <span aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmitUpdateproperty)} className="form-input-profile-modal">
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Name
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Name"
                        name="name"
                        ref={register}

                      />
                      {errors.name && (
                        <div
                          className="alert alert-danger sia-alert-danger closer mt-3"
                          role="alert"
                        >
                          {errors.name?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Description
                      </div>
                      <textarea
                        id="AdditionalInformation"
                        className="form-control-textarea px-3 pt-3 text-dark"
                        placeholder="Description"
                        ref={register}
                        name="description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>

              {!isSuccessfullySubmittedForm ? (
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-taf-primary"
                    type="submit"
                  >
                    Send
                  </button>
                </div>

              ) : (
                <div className="d-flex justify-content-end py-3">
                  <span className="btn btn-valider-profil trans-0-2">
                    <i className="fas fa-spin fa-spinner"></i>
                    &nbsp;&nbsp;&nbsp;In progress...
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Modalafficherpropertytype;
