import React from "react";

interface Props {
  datas_user: any
}
const ModalShowAdmin: React.FC<Props> = ({ datas_user }) => {

  // console.log("adatas datas_users",datas_user?.fullname && datas_user?.fullname);

  return (

    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            Detail administrator :  {datas_user?.fullname && datas_user?.fullname}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form className="form-input-profile-modal">
          <div className="modal-body">

            <div className="container-input-address-modal">
              <div className="row">
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Name
                    </div>
                    <span className="form-control-profil">
                      {datas_user?.fullname && datas_user?.fullname}
                    </span>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Address
                    </div>
                    <span className="form-control-profil">
                      {datas_user?.adresse && datas_user?.adresse}
                    </span>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Email
                    </div>
                    <span className="form-control-profil">
                      {datas_user?.email && datas_user?.email}
                    </span>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Phone
                    </div>
                    <span className="form-control-profil">
                      {datas_user?.telephone && datas_user?.telephone}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer faq-modal-footer">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>

  );
}

export default ModalShowAdmin;
