import Skeleton from "react-loading-skeleton";

const ProjetSkeleton = () => {
  return (
    <div className="col-md-12 as-col-table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Projet Name
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Location
            </th>
            {/* <th scope="col" style={{ color: '#A0AEC0' }} className="th-table-admin">Type</th>  */}
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Amount requested
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Status
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Publish status
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            {/* <td><Skeleton /></td> */}
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            {/* <td><Skeleton /></td> */}
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            {/* <td><Skeleton /></td> */}
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            {/* <td><Skeleton /></td> */}
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProjetSkeleton;
