import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./ReservationAdmin.css";
import ReservationsTable from "./Reservations/ReservationsTable";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { listReservationsByProject } from "../../../redux/Reservations/reservationAction";
import moment from "moment";

const ReservationsByProject: React.FC = () => {
  const history = useHistory();
  const { project } = useLocation<any>().state || {};
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [date_souhaite, setDate_souhaite] = useState("");
  const [status, setStatus] = useState("");
  const [property_id, setProperty_id] = useState("");
  const { isLoading, data = { results: [], count: 0 } } = useSelector(
    (s: RootStateOrAny) => s?.reservationByProject
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      listReservationsByProject({
        page,
        limit,
        fullname,
        status,
        date_souhaite: date_souhaite
          ? moment(date_souhaite).format("DD-MM-YYYY")
          : "",
        phone,
        email,
        property_id,
        id: project?.id,
      })
    );
  }, [
    page,
    limit,
    fullname,
    status,
    date_souhaite,
    phone,
    email,
    property_id,
    project,
  ]);
  React.useEffect(() => {
    if (!project) {
      history.goBack();
    }
  }, [project]);
  if (!project) return null;
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <h1 className="mon-profil">
                  List of reservations {project?.property_name}
                  <br />
                  <p className="sold-this-month"></p>
                </h1>
              </div>
              <div className="users-table pt-2">
                <ReservationsTable
                  project={project}
                  page={page}
                  limit={limit}
                  setDate_souhaite={setDate_souhaite}
                  setFullname={setFullname}
                  setPhone={setPhone}
                  setEmail={setEmail}
                  setStatus={setStatus}
                  setProperty_id={setProperty_id}
                  setLimit={setLimit}
                  setPage={setPage}
                  data={data}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationsByProject;
