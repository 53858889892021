import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Plus } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import { fetchSlider } from "../../../redux/Slider/SliderAction";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import Sliders from "../Sliders/Sliders";
import SliderSkeleton from "./skeletons/SliderSkeleton";

const ListSliders = () => {
  const dispatch = useDispatch();
  const [item, setItem] = React.useState<any>(false);
  const sliders = useSelector((state: RootStateOrAny) => state?.sliders);

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  React.useEffect(() => {
    dispatch(fetchSlider());
  }, []);

  const photoFormatter = (cell, row) => {
    const image = row.image;
    if (!image) return <small>Pas d'image</small>;
    return <img src={baseUrl + image} alt="avatar" className="user-img" />;
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span
          className="name"
          dangerouslySetInnerHTML={{ __html: row.catch_phrase }}
        />
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-container">
          <NavLink
            to="#"
            style={{ color: "#2D3748" }}
            className="mr-2"
            data-toggle="modal"
            data-target="#updateAdminModal"
            onClick={() => setItem(row)}
            title="Edit this slide"
          >
            <EditTwoTone style={{ fontSize: "20px" }} />
          </NavLink>
          <NavLink
            to="#"
            style={{ color: "#E53E3E" }}
            className="mr-2"
            onClick={() => onDelete(row.id)}
            title="Delete this Slide"
          >
            <Delete style={{ fontSize: "20px" }} />
          </NavLink>
        </div>
      </>
    );
  };

  const token = window.localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this slide ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteRequest = Axios.delete(`${api}slider/${id}/`, config);

        deleteRequest
          .then((response) => {
            dispatch(fetchSlider());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Slide deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <div className="container-profile-edit container-profile-article">
                  <h1 className="mon-profil">List of slides</h1>
                  <NavLink
                    data-toggle="modal"
                    data-target="#updateAdminModal"
                    to="#"
                    onClick={() => {
                      setItem(null);
                    }}
                    className="btn btn-add-listing"
                  >
                    <Plus set="light" primaryColor="#B0B0B0" />
                    <span className="pl-2">Add slide</span>
                  </NavLink>
                </div>
              </div>
              <div className="users-table pt-2">
                <div className="child-table-container">
                  <div className="table-body-container-activite">
                    <div className="row easypm-table-row">
                      <div className="col-md-12 easypm-table-col">
                        <div className="table-container-activite">
                          {!!sliders?.isLoading && <SliderSkeleton />}

                          {!sliders?.isLoading && (
                            <BootstrapTable
                              data={sliders?.data}
                              hover={true}
                              condensed={false}
                              multiColumnSort={2}
                              options={options}
                              search={false}
                              version="4"
                              bordered={false}
                              striped={true}
                              pagination
                            >
                              <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                hidden
                              >
                                ID
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: "#A0AEC0",
                                  fontFamily: "Raleway",
                                }}
                                tdStyle={{
                                  fontWeight: "normal",
                                  fontSize: 14,
                                  color: "#2D3748",
                                }}
                                width="50"
                                dataField="image"
                                dataFormat={(cell, row) =>
                                  photoFormatter(cell, row)
                                }
                              >
                                Photo
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: "#A0AEC0",
                                  fontFamily: "Raleway",
                                }}
                                tdStyle={{
                                  fontWeight: "normal",
                                  fontSize: 14,
                                  color: "#2D3748",
                                  fontFamily: "Raleway",
                                }}
                                width="100"
                                dataField="id"
                                dataFormat={(cell, row) =>
                                  nameFormatter(cell, row)
                                }
                              >
                                Name
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: "#A0AEC0",
                                  fontFamily: "Raleway",
                                }}
                                dataField="id"
                                width="100"
                                dataFormat={(cell, row) =>
                                  actionFormatter(cell, row)
                                }
                              >
                                Actions
                              </TableHeaderColumn>
                            </BootstrapTable>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade modal-faq modal-faq-contact"
                    id="updateAdminModal"
                    aria-labelledby="updateAdminModalLabel"
                    aria-hidden="true"
                  >
                    <Sliders onDone={() => setItem(false)} slide={item} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSliders;
