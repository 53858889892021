import api from "../../Http/global-vars";
import {
  FETCH_ADD_MEMBER_LIST,
  FETCH_MEMBER_LIST,
  FETCH_MEMBER_LIST_FRONT,
  FETCH_UPDATE_MEMBER_LIST,
} from "../types";

export const memberList = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "member/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_MEMBER_LIST,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const memberListFront = () => {
  return async (dispatch) => {
    const url = api + "memberfront/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("member list front", data)
        dispatch({
          type: FETCH_MEMBER_LIST_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const addMember = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "member/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_ADD_MEMBER_LIST,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const updateMember = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "member/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_UPDATE_MEMBER_LIST,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
