import { BsArrowLeft } from "react-icons/bs";

interface Props {
  datas_member: any;
}

function ModalShowMember({ datas_member }) {
  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            <BsArrowLeft
              className="btn-modal-arrowback"
              data-dismiss="modal"
              aria-label="Close"
            />
            {datas_member?.full_name && datas_member?.full_name}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form className="form-input-parametre">
          <div className="container-input-address container-input-modal-member">
            <div className="form-group-add-project-group-container row">
              <div className="form-add-project-group form-group-add-project-dropzone-container pl-3"></div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <div className="form-label-text text-label-modal">
                    Full name
                  </div>
                  <span className="form-control-profil">
                    {datas_member?.full_name && datas_member?.full_name}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <div className="form-label-text text-label-modal">Title</div>
                  <span className="form-control-profil">
                    {datas_member?.title && datas_member?.title}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <div className="form-label-text text-label-modal">
                    Facebook
                  </div>
                  <span className="form-control-profil">
                    {datas_member?.facebook && datas_member?.facebook}
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <div className="form-label-text text-label-modal">
                    Twitter
                  </div>
                  <span className="form-control-profil">
                    {datas_member?.twitter && datas_member?.twitter}
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <div className="form-label-text text-label-modal">
                    Instagram
                  </div>
                  <span className="form-control-profil">
                    {datas_member?.instagram && datas_member?.instagram}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              {/* <button 
                        className="btn-back-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        data-testid="backBtnId"
                        >
                            Back
                        </button> */}
              <button
                className="btn-taf-primary"
                data-testid="saveBtnId"
                type="button"
                // className="close close-icon"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalShowMember;
