import user1 from '../../../../images/user1.png'
import user2 from '../../../../images/user2.png'
import user3 from '../../../../images/user3.png'
import user4 from '../../../../images/user4.png'



const UsersData = [
    {
        image: user4,
        name: 'Esthera Jackson',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user2,
        name: 'Alexa Liras',
        email: 'daniel@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user3,
        name: 'Laurent Michael',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user4,
        name: 'Freduardo Hill',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user4,
        name: 'Daniel Thomas',
        email: 'daniel@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user2,
        name: 'Mark Wilson',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user1,
        name: 'Esthera Jackson',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user2,
        name: 'Alexa Liras',
        email: 'daniel@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user3,
        name: 'Laurent Michael',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user4,
        name: 'Freduardo Hill',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user4,
        name: 'Daniel Thomas',
        email: 'daniel@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
    {
        image: user2,
        name: 'Mark Wilson',
        email: 'mark@simmmple.com',
        tel: '77 770 00 00',
        dateRegister: '14/06/21'
    },
];

export default UsersData;
