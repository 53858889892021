import React from 'react';
import HeaderAdmin from '../headerAdmin/HeaderAdmin';
import NavbarVertical from '../nav/NavbarVertical';
import BlogTable from './AgencieBlogTable/AgencieTable';


const Agencieadmin: React.FC = () => {
    return (
      <div className="container-fluid body-background-dashboard">
        <HeaderAdmin />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavbarVertical />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
            <div className="projets-container bloc-principal-body-content">
              <div className="profile-containers">
                <h1 className="liste-utilisateurs-title">
                  Agencies
                </h1>
                <div className="users-table">
                  <BlogTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
     }       
export default Agencieadmin;