import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { UserFormData } from "../../../../repositories/user/UserFormData";
import axios from 'axios';
import api from '../../../../Http/global-vars';
import Swal from "sweetalert2";
import $ from "jquery";


const ModalProfile: React.FC = () => {

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] = React.useState(false);

  // const user = useSelector((state: RootStateOrAny) => state.user);

  const validationSchema = yup.object().shape({
    old_password: yup.string().required("Old Password is required"),
    new_password: yup
      .string()
      .required("Password is required.")
      .min(8, "The password must contain at least 8 characters")
      .matches(
        /[a-z]+/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /[A-Z]+/,
        "The password must contain at least one capital letter"
      )
      .matches(
        /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
        "Password must contain at least one special character"
      )
      .matches(
        /\d+/,
        "The password must contain at least one number"
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("new_password"), null],
        "Please enter identical passwords."
      )
      .required("Please confirm your password"),
  });

  const token = window.localStorage.getItem('userToken');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const {
    register,
    // setValue,
    handleSubmit,
    reset,
    formState,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  const { errors } = formState;

  const onSubmitInfoUser = async (data: UserFormData) => {

    console.log("data to update", data);
    const fd = new FormData();

    fd.set("old_password", data.old_password);
    fd.set("new_password", data.new_password);

    setIsSuccessfullySubmittedForm(true);

    const request = axios.put(`${api}changepassword/`, data, config);

    request.then((res) => {

      if (res.status === 200) {

        Swal.fire({
          icon: "success",
          iconColor: '#df5656',
          showCancelButton: false,
          confirmButtonColor: '#df5656',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          title: "Password changed successfully.",
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            $('#myClosebutton').click();
          }
        });


        setIsSuccessfullySubmittedForm(false);
        reset()

      }
    }).catch((err) => {
      if (err.response.data.old_password[0] === 'Wrong password.') {
        Swal.fire({
          icon: "error",
          iconColor: '#df5656',
          showCancelButton: false,
          confirmButtonColor: '#df5656',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          title: "Whoops!! You must enter your old password.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      }
    })
  }

  return (
    <div
      className="modal fade modal-faq modal-faq-contact"
      id="profileModal"
      //   tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"

    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header">
            <h5
              className="modal-title titre-question-reponse"
              id="exampleModalLabel"
            >
              Change your password
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
              id="myClosebutton"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="form-input-profile-modal" id="form-change-pwd-profile" onSubmit={handleSubmit(onSubmitInfoUser)}>
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-12">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Old password
                      </div>
                      <input
                        type="password"
                        className="form-control-profil"
                        placeholder="********"
                        name="old_password"
                        data-testid="oldpasswordId"
                        ref={register}
                      />
                      {errors.old_password &&
                        <div className="alert alert-danger sia-alert-danger closer">

                          {errors.old_password?.message}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        New password
                      </div>
                      <input
                        type="password"
                        className="form-control-profil"
                        placeholder="********"
                        name="new_password"
                        data-testid="new_passwordId"
                        ref={register}
                      />
                      {errors.new_password &&
                        <div className="alert alert-danger sia-alert-danger closer">

                          {errors.new_password?.message}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Confirm password
                      </div>
                      <input
                        type="password"
                        className="form-control-profil"
                        placeholder="********"
                        data-testid="passwordConfirmationId"
                        name="confirmPassword"
                        ref={register}
                      />
                      {errors.confirmPassword &&
                        <div className="alert alert-danger sia-alert-danger closer">

                          {errors.confirmPassword?.message}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer faq-modal-footer">
            <div className="d-flex justify-content-end">
              {
                !isSuccessfullySubmittedForm ? (
                  <button className="btn-taf-primary" form="form-change-pwd-profile">Send</button>
                ) : (
                  <span className="btn-taf-primary"><i className="fa fa-spin fa-spinner"></i>In Progress...</span>
                )
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalProfile;
