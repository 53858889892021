import ProjectTypeDataForm from "../../repositories/user/ProjectTypeDataForm";
import { FETCH_ADD_TESTIMONIALS, FETCH_FRONT_TESTIMONIALS, FETCH_TESTIMONIALS} from "../types";

export const TestimonialsReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: ProjectTypeDataForm }
) => {
  switch (action.type) {
    case FETCH_TESTIMONIALS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const testimonialsFrontReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_FRONT_TESTIMONIALS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const addpropertyTypeReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_ADD_TESTIMONIALS:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

