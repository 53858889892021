import ProjetDatas from "../../repositories/user/projets/DatasProjets";
import { PaginationResults } from "../Article/articleAction";
import {
  DETAIL_PROPERTY,
  FETCH_PROPERTIES_CONVERTED,
  FETCH_PROPERTIES_TYPE_TO_FRONT,
} from "../types";

type Type = {
  isLoading: boolean;
  data: PaginationResults<ProjetDatas>;
  origin: ProjetDatas[]
};
export const listPropertiesFrontReducer = (
  state: Type = {
    isLoading: true,
    data: { results: [], count: 0 },
    origin: [],
  },
  action: { type: any; payload: Type["data"],isLoading: boolean }
): Type => {
  switch (action.type) {
    case FETCH_PROPERTIES_TYPE_TO_FRONT:
      return {
        isLoading: action.isLoading,
        data: action.payload,
        origin: action.payload.results,
      };
    case FETCH_PROPERTIES_CONVERTED:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const detailPropertyFrontReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case DETAIL_PROPERTY:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
