import { FETCH_SHOW_OF, FETCH_SHOW_OF_FRONT } from "../types";

export const ShowOfReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SHOW_OF:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};

export const ShowOfFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SHOW_OF_FRONT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};