import axios from "axios";
import api from "../Http/global-vars";

export default {
  async onAdd(data, config) {
    const response = await axios.post(`${api}taf_description/`, data, config);
    return response.data;
  },
  async onEdit(id, data, config) {
    const response = await axios.put(
      `${api}taf_description/${id}/`,
      data,
      config
    );
    return response.data;
  },

  async onDelete(id, config) {
    const response = await axios.delete(`${api}taf_description/${id}/`, config);
    return response.data;
  },
};
