import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalCountry from "./LesModals/ModalCountry";
import CountryTable from "./LesTable/CountryTable";

const Countries: React.FC = () => {
  return (
    <div className="container-table-location">
      <button
        type="button"
        data-toggle="modal"
        data-target="#ProprietetypeModal"
        className="btn btn-add-amin-setting ml-auto"
      >
        <AiOutlinePlusCircle className="btn-addadmin-icon" />
        <span className="">Add Country</span>
      </button>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="ProprietetypeModal"
        aria-labelledby="addAdminModalLabel"
        aria-hidden="true"
      >
        <ModalCountry />
      </div>
      <div className="profile-containers">
        <div className="containter-table-admin mt-5">
          <CountryTable />
        </div>
      </div>
    </div>
  );
};

export default Countries;
