import React from "react";
import UsersTable from "./UsersTable/UsersTable";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./Users.css";
import ModalAddAdmin from "../ParaAdmin/ModalParametre/ModalAddAdmin";
import { Plus } from "react-iconly";

const Useradmin: React.FC =
  () => {
    return (
      <div className="container-fluid body-background-dashboard">
        <HeaderAdmin />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavbarVertical />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
            <div className="projets-container bloc-principal-body-content">
              <div className="profile-containers">
                <div className="title-page-container">
                  <div>
                    <h1 className="liste-utilisateurs-title">
                      List of administrators
                    </h1>
                  </div>
                  <div>
                    <button type="button" className="btn btn-add-listing" data-toggle="modal" data-target="#addAdminModal">
                      <Plus set="light" primaryColor="#B0B0B0" />
                      <span className="pl-2">Add admin</span>
                    </button>
                  </div>
                </div>
                <div className="users-table">
                  <UsersTable />
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade modal-faq modal-faq-contact"
            id="addAdminModal"
            aria-labelledby="addAdminModalLabel"
            aria-hidden="true"
          >
            <ModalAddAdmin />
          </div>
        </div>
      </div>
    );
  };

export default Useradmin;
