import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../../Http/global-vars";
import groupphoto from "../../../../images/Groupphoto.png";
import { memberList } from "../../../../redux/AddNewMemberSetting/ourTeamMemberAction";
import { SettingAddMemberData } from "../../../../repositories/user/SettingAddMemberData";

function ModalUpdateMember({ data_member }) {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [image, setImage] = useState<File>();

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    full_name: yup.string().required("This filed is required"),
    title: yup.string(),
    facebook: yup.string(),
    instagram: yup.string(),
    twitter: yup.string(),
  });

  const { register, handleSubmit, formState, reset } =
    useForm<SettingAddMemberData>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;
  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );

    return (
      <div className="upload-dropzone-row row">
        <div className="col-md-10 offset-md-1">
          {/* {text} */}
          <label htmlFor="dropzoneImageUpdateMember">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            accept={accept}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              setImage(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageUpdateMember"
          />
        </div>
      </div>
    );
  };

  const userID = window.localStorage.getItem("userID");

  const submitAddMember = async (data: SettingAddMemberData) => {
    setIsSuccessfullySubmittedForm(true);
    console.log("images update member", image);

    const fd = new FormData();
    fd.set("full_name", data.full_name);
    fd.set("title", data.title);
    fd.set("facebook", data.facebook);
    fd.set("twitter", data.twitter);
    fd.set("instagram", data.instagram);
    if (image) {
      fd.set("image", image);
    }
    fd.set("created_by", userID ? userID : "null");

    const request = Axios.put(`${api}member/${data_member.id}/`, fd, config);
    await request
      .then(async (resp) => {
        console.log("resp", resp);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Member updated successfully",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            $(".modal").modal("hide");
            reset();
          }
        });
        dispatch(memberList());
        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        Swal.fire({
          icon: "error",
          title: "Error",
          html: "Error to update member",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            <BsArrowLeft
              className="btn-modal-arrowback"
              data-dismiss="modal"
              aria-label="Close"
            />
            Update member {data_member?.full_name && data_member?.full_name}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form
          className="form-input-parametre"
          onSubmit={handleSubmit(submitAddMember)}
        >
          <div className="container-input-address container-input-modal-member">
            <div className="form-group-add-project-group-container row">
              <div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
                <label
                  htmlFor="projectImage"
                  className="form-add-project-label"
                >
                  image
                </label>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  accept="image/*"
                  maxFiles={1}
                  InputComponent={InputChooseFile}
                  // getFilesFromEvent={
                  //   getFilesFromEvent
                  // }
                  styles={{
                    dropzone: {
                      minHeight: 150,
                      maxHeight: 300,
                      border: "2px dashed #d9d9d9",
                      background: "#F3F3F3",
                      overflow: "hidden",
                      borderRadius: 10,
                    },
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Full name</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="Carolina Faye"
                    name="full_name"
                    ref={register}
                    data-testid="nameId"
                    defaultValue={data_member?.full_name}
                  />
                  {errors.full_name && (
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.full_name?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Title</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="CEO & MANAGER"
                    name="title"
                    ref={register}
                    data-testid="titleId"
                    defaultValue={data_member?.title}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <label className="form-label-text">Facebook</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="facebook.com"
                    name="facebook"
                    ref={register}
                    data-testid="facebookId"
                    defaultValue={data_member?.facebook}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <label className="form-label-text">Twitter</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="twitter.com"
                    name="twitter"
                    ref={register}
                    data-testid="twitterId"
                    defaultValue={data_member?.twitter}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="infos-perso-input">
                  <label className="form-label-text">Instagram</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="instagram.com"
                    name="instagram"
                    ref={register}
                    data-testid="instagramId"
                    defaultValue={data_member?.instagram}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn-back-primary"
                data-dismiss="modal"
                aria-label="Close"
                data-testid="backBtnId"
              >
                Back
              </button>
              {!isSuccessfullySubmittedForm ? (
                <button
                  className="btn-taf-primary"
                  type="submit"
                  data-testid="saveBtnId"
                >
                  Save
                </button>
              ) : (
                // <div className="d-flex justify-content-end py-3">
                <span className="btn btn-valider-profil trans-0-2 btn-taf-primary">
                  <i className="fas fa-spin fa-spinner"></i>
                  &nbsp;&nbsp;&nbsp;In progress...
                </span>
                // </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalUpdateMember;
