import React, { useEffect } from "react";

function AdminLayout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    document.title = "TAF AFRICA GLOBAL";
  }, []);
  return <div>{children}</div>;
}

export default AdminLayout;
