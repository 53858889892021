import { FETCH_RESERVATIONS, FETCH_RESERVATIONS_PROJECT, FETCH_RESERVATIONS_PROPERTY } from "../types";
export const reservationsReducer = (
  state = { isLoading: true, data: { results: [], count: 0 } },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_RESERVATIONS:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const reservationsPropertyReducer = (
  state = { isLoading: true, data: { results: [], count: 0 } },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_PROPERTY:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const reservationsProjectReducer = (
  state = { isLoading: true, data: { results: [], count: 0 } },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_PROJECT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};