import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../../Http/global-vars";
import groupphoto from "../../../../images/Groupphoto.png";
// import { RootStateOrAny, useSelector } from "react-redux";
import { testimonialsAction } from "../../../../redux/Testimonials/TestimonialsAction";
import { PropertyDatas } from "../../../../repositories/user/PropertyDatas";
import { ContentEditor } from "../../ListingAdmin/ListingDetailsDeBaseSecondStep";

function ModalAddTestimonials() {
  const dispatch = useDispatch();
  // const addtestimonials = useSelector((state: RootStateOrAny) => state.addtestimonials);
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [image, setImage] = useState<any>();
  const [ckeditorVal, setCkeditorVal] = useState("");
  const [ckeditorNotEmpty, setCkeditorNotEmpty] = useState(false);

  const validationSchema = yup.object().shape({
    full_name: yup.string().required("This field is required"),
    // content: yup.string().required("This field is required"),
    function: yup.string().nullable(),
  });

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    console.log(
      "chosenFiles.map(f => f.fileObject)",
      chosenFiles.map((f) => f.fileObject)
    );
    return chosenFiles.map((f) => f.fileObject);
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text =
      files.length === 0 ? (
        // 'Ajouter  des photos' :
        <div className="start-label-input-choose-file">
          <div className="label-img-container pt-3">
            <img src={groupphoto} className="label-image" alt="icone-label" />
          </div>
          <span className="first_label-input-choose-file">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
      ) : (
        <div
          className="btn-taf-add"
          style={{
            cursor: "pointer",
            float: "right",
          }}
          onClick={(e) => e.preventDefault()}
        >
          Add more picture
        </div>
        // <div
        // 	className="row"
        // 	style={{ cursor: "pointer", float: "right" }}
        // 	onClick={(e) => e.preventDefault()}
        // >
        // 	ADD
        // </div>
      );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImageAddTestimonial">{text}</label>
          <input
            style={{
              opacity: 0,
              cursor: "pointer",
            }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              setImage(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageAddTestimonial"
          />
        </div>
      </div>
    );
  };

  const { register, handleSubmit, formState, clearErrors, reset } =
    useForm<any>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;
  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const inputHandler = async (name, value) => {
    setCkeditorVal(value);
    if (value) {
      setCkeditorNotEmpty(false);
    }
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(() => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const onSubmitAddTestimonial = (data: PropertyDatas) => {
    setIsSuccessfullySubmittedForm(true);

    const fd = new FormData();
    fd.append("created_by", userID ? userID : "null");
    fd.append("name", data.name);
    fd.append("description", data.description);
    fd.append("full_name", data.full_name);
    fd.append("content", ckeditorVal ? ckeditorVal : "");
    fd.append("function", data.function);
    if (image) {
      fd.append("image", image);
    }

    if (!ckeditorVal) {
      setCkeditorNotEmpty(true);
      setIsSuccessfullySubmittedForm(false);
    } else {
      const request = Axios.post(`${api}testimonial/`, fd, config);
      request
        .then((res) => {
          if (res.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success",
              html: "Testimonial saved successfully!",
              iconColor: "#df5656",
              showCancelButton: false,
              confirmButtonColor: "#df5656",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                $(".modal").modal("hide");
                setCkeditorVal("");
                $("#yourFormId").trigger("reset");
                reset();
              }
            });

            dispatch(testimonialsAction());
            setIsSuccessfullySubmittedForm(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Errorr",
            html: "Testimonial not saved successfully!,Please review the data entered",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
          setIsSuccessfullySubmittedForm(false);
          reset();
          setCkeditorVal("");
          setImage("null");
        });
    }
  };

  return (
    <div className="modal-dialog modal-lg modal-dialog-centered fghjk">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            Add a opinion
          </h5>

          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
            id=""
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmitAddTestimonial)}>
          <div className="container-input-address container-input-modal-member">
            <div className="form-group-add-project-group-container row">
              <div className="col-lg-8 ">
                <div className="form-add-project-group form-group-add-project-dropzone-container pl-3 ">
                  <label
                    htmlFor="projectImage"
                    className="form-add-project-label"
                  >
                    Teaser image
                  </label>
                  <Dropzone
                    getUploadParams={() => ({
                      url: "https://httpbin.org/post",
                    })}
                    InputComponent={InputChooseFile}
                    getFilesFromEvent={getFilesFromEvent}
                    accept="image/*"
                    maxFiles={100}
                    styles={{
                      dropzone: {
                        minHeight: 150,
                        maxHeight: 300,
                        border: "2px dashed #d9d9d9",
                        background: "#F3F3F3",
                        overflow: "hidden",
                        opacity: 1,
                      },
                    }}
                    ref={register}
                  />
                  <small className="text-danger fw-bold d-flex">dimension 200x200px</small>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="infos-perso-input-testimonials">
                        <label className="form-label-text">Full name</label>
                        <input
                          type="text"
                          className="form-control-profil form-control-showoff form-control"
                          placeholder="Clara Bush"
                          ref={register}
                          id="full_nameId"
                          name="full_name"
                          data-testid="fullnameId"
                        />
                        {errors.full_name && (
                          <div
                            className="alert alert-danger sia-alert-danger closer mt-3"
                            role="alert"
                          >
                            {errors.full_name?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="infos-perso-input-testimonials">
                        <label className="form-label-text">Function</label>
                        <input
                          type="text"
                          className="form-control-profil form-control-showoff form-control "
                          placeholder="Project manager"
                          ref={register}
                          id="functionId"
                          name="function"
                          data-testid="functionId"
                        />
                        {errors.function && (
                          <div
                            className="alert alert-danger sia-alert-danger closer mt-3"
                            role="alert"
                          >
                            {errors.function?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="infos-perso-input">
                  <label className="form-label-text">Content</label>
                  <ContentEditor
                    data={ckeditorVal ?? " "}
                    setValue={inputHandler}
                  />
                  {ckeditorNotEmpty && (
                    <div
                      className="alert alert-danger sia-alert-danger closer mt-3"
                      role="alert"
                    >
                      {"This field is required"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
            >
              Back
            </button>
            {!isSuccessfullySubmittedForm ? (
              <div className="d-flex justify-content-end">
                <button
                  className="btn-taf-primary"
                  type="submit"
                  data-testid="sendBtnId"
                >
                  Send
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-end py-3">
                <span className="btn btn-valider-profil trans-0-2">
                  <i className="fas fa-spin fa-spinner"></i>
                  &nbsp;&nbsp;&nbsp;In progress...
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalAddTestimonials;
