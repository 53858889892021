import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api, { PROJECT_STATUS } from "../../../../Http/global-vars";
import { fetchProjects } from "../../../../redux/projects/projectAction";
import ProjetDatas from "../../../../repositories/user/projets/DatasProjets";
import Project from "../../../../services/project";
import { formatAmout } from "../../../../utils/convert_currency";
import ListDocuments from "../../ParaAdmin/ListDocuments";
import { CustomPagination } from "../../../../modules/Pagination/PaginationV2";
import ProjetSkeleton from "./ProjetSkeleton";

const statusOption = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Sold out",
    value: "sold_out",
  },
  {
    label: "Coming soon",
    value: "coming_soon",
  },
  {
    label: "Just launched",
    value: "just_launched",
  },
  {
    label: "Finished",
    value: "finished",
  },
  {
    label: "Final units remaining",
    value: "final_units_remaining",
  },
];

const publishOptions = [
  {
    label: "Publish",
    value: "publier",
  },
  {
    label: "Not publish",
    value: "non_publier",
  },
];
type Props = {
  onChange: any;
  name: string;
  setValue: any;
  type: string;
  options?: any[];
};
const CustomFilterText = (props: Props) => {
  const { onChange, name, setValue, type, options = [] } = props;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    setValue(value);
    // filterHandler(value)
  };
  if (type === "select") {
    return (
      <select
        name={name}
        id={name}
        className="filter select-filter form-control placeholder-selected"
        onChange={dataSelected.bind(this, onChange)}
      >
        <option value="">Select</option>
        {options?.map((opt) => (
          <option value={opt?.value} key={opt?.value}>
            {" "}
            {opt?.label}{" "}
          </option>
        ))}
      </select>
    );
  }
  return (
    <input
      // ref={ref}
      type={type}
      name={name}
      min={0}
      onChange={dataSelected.bind(this, onChange)}
      // value={value}
      placeholder={name}
      className="filter text-filter form-control"
    />
  );
};
const ProjetTable = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [publishStatus, setPublishStatus] = useState("");
  const [item, setItem] = React.useState<any>(null);

  const { data = { results: [], count: 0 }, isLoading } = useSelector(
    (state: RootStateOrAny) => state.projects
  );
  const reviews = useSelector((state: RootStateOrAny) => state.reviews);
  const reservations = useSelector(
    (state: RootStateOrAny) => state.reservations
  );

  const property_types = useSelector(
    (state: RootStateOrAny) => state.property_types
  );

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchProjects({
        page,
        limit,
        status,
        status_publication: publishStatus,
        location,
        q: name,
        amount,
      })
    );
  }, [page, limit, status, publishStatus, name, location, amount]);

  const statutFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          onClick={() => updateStatut(row.id)}
          className={
            row.statut_project === "new"
              ? `btn projet-btn-nouveau-bleu text-uppercase cursor-pointer`
              : row.statut_project === "sold_out"
              ? `btn projet-btn-nouveau-red text-uppercase cursor-pointer`
              : row.statut_project === "finished"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-rouge text-uppercase cursor-pointer`
          }
        >
          {PROJECT_STATUS[cell]}
        </button>
      </span>
    );
  };

  const updateStatut = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: PROJECT_STATUS,
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("statut_project", value);

            const request = Axios.put(`${api}project/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(
                  fetchProjects({
                    page,
                    limit,
                    status,
                    status_publication: publishStatus,
                    location,
                    q: name,
                    amount,
                  })
                );
                setPage(1);
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Project changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };

  const onMiseAvant = (item: ProjetDatas) => {
    Swal.fire({
      title: `Do you want ${
        item?.en_avant ? "to unpromote" : "to promote"
      } this project ?`,
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        const fd = new FormData();
        fd.append("en_avant", "false");
        let addRequest;
        if (item.en_avant) {
          addRequest = Project.onEditProject(item?.id, fd, config);
        } else {
          addRequest = Project.onAvantProject(item?.id, "project", config);
        }

        return addRequest
          .then(async (response) => {
            await dispatch(
              fetchProjects({
                page,
                limit,
                status,
                status_publication: publishStatus,
                location,
                q: name,
                amount,
              })
            );
            setPage(1);
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${
            !item?.en_avant
              ? "Project successfully promoted !"
              : "Project unpromoted !"
          }`,
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const onFeatured = (item: ProjetDatas) => {
    Swal.fire({
      title: `Do you want ${
        item?.is_featured ? "to delete the featured of" : "to feature"
      } this project ?`,
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        const fd = new FormData();
        const is_featured = item.is_featured ? "false" : "true";
        fd.append("is_featured", is_featured);
        const addRequest = Project.onEditProject(item?.id, fd, config);
        return addRequest
          .then(async (response) => {
            await dispatch(
              fetchProjects({
                page,
                limit,
                status,
                status_publication: publishStatus,
                location,
                q: name,
                amount,
              })
            );
            setPage(1);
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${
            !item?.en_avant
              ? "Project successfully featured !"
              : "Featured of this project successfully deleted !"
          }`,
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    // let countReviewProperty =reviews.data && reviews.data.filter((x) => x.project_id === row.id);
    return (
      <div className="table-actions-container justify-content-right">
        <NavLink
          to={"#"}
          style={{ color: "#A9A9A9" }}
          className="mr-2 number-chat-link"
          data-toggle="modal"
          data-target="#ShowListDocuemnt"
          onClick={() => setItem(row)}
          title="Add documents"
        >
          <IconlyPack.Document set="bold" />
        </NavLink>
        {row?.statut_publication === "publier" ? (
          <>
            <NavLink
              to="#"
              title="Highlight this property"
              style={{ color: row?.is_featured ? "#9A160A" : "#A9A9A9" }}
              className="mr-2"
              onClick={() => onFeatured(row)}
            >
              <IconlyPack.Heart set="bold" />
            </NavLink>

            <NavLink
              to="#"
              title="Promote this property"
              style={{ color: row?.en_avant ? "#9A160A" : "#A9A9A9" }}
              className="mr-2"
              onClick={() => onMiseAvant(row)}
            >
              <IconlyPack.Swap set="bold" />
            </NavLink>
          </>
        ) : null}
        <NavLink
          to={{
            pathname: `/admin/project-details/${row.slug}`,
            state: { row, property_types, reviews, reservations },
          }}
          style={{ color: "#A9A9A9" }}
          className="mr-2 number-chat-link"
          title="View details"
        >
          <IconlyPack.Show set="bold" />
        </NavLink>

        <NavLink
          to={{
            pathname: `/admin/modifier-projet/${row.id}`,
            state: row,
          }}
          style={{ color: "#2D3748" }}
          className="mr-2"
          title="Edit this property"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>
        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className=""
          onClick={() => onDelete(row.id)}
          title="Delete this property"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const projectNameFormatter = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/admin/project-details/${row.slug}`,
          state: { row, property_types, reviews, reservations },
        }}
        className="link-detail-project"
      >
        {cell}
      </NavLink>
    );
  };

  const onDelete = (id: ProjetDatas) => {
    Swal.fire({
      title: "Do you want to delete this project ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = Project.onDeleteProject(id, config);
        addRequest
          .then((response) => {
            dispatch(
              fetchProjects({
                page,
                limit,
                status,
                status_publication: publishStatus,
                location,
                q: name,
                amount,
              })
            );
            setPage(1);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Project deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };
  const updateStatutPublication = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: {
        publier: "Publish",
        non_publier: "No Publish",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("statut_publication", value);

            const request = Axios.put(`${api}project/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(
                  fetchProjects({
                    page,
                    limit,
                    status,
                    status_publication: publishStatus,
                    location,
                    q: name,
                    amount,
                  })
                );
                setPage(1);
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Status changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };
  const priceForamt = (cell, row) => {
    return `Starting from ${formatAmout(row?.min_price)}`;
  };

  const statutPublicationFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          onClick={() => updateStatutPublication(row?.id)}
          className={
            cell === "publier"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-red text-uppercase cursor-pointer`
          }
        >
          {cell === "publier"
            ? "Publish"
            : cell === "non_publier"
            ? "No Publish"
            : ""}
        </button>
      </span>
    );
  };

  const locationFormat = (cell, row) => {
    return cell?.name;
  };

  const renderPaginationPanel = (props) => {
    return (
      <div className="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const onChangeData = (key, value: any) => {
    console.log(key);
    if (key === "name") {
      setName(value);
    } else if (key === "amount") {
      setAmount(value);
    } else if (key === "location") {
      setLocation(value);
    } else if (key === "status") {
      setStatus(value);
    } else if (key === "status_publication") {
      setPublishStatus(value);
    }
    setPage(1);
  };

  const getCustomTextFilter = (filterHandler, nom, options: any = []) => {
    return (
      <CustomFilterText
        onChange={filterHandler}
        name={nom}
        setValue={(value) => onChangeData(nom, value)}
        type={
          nom === "name" || nom === "location"
            ? "text"
            : nom === "amount"
            ? "number"
            : "select"
        }
        options={options}
      />
    );
  };

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          {!!isLoading && <ProjetSkeleton />}
          {!isLoading && (
            <div className="col-md-12 easypm-table-col">
              <div className="table-container-activite">
                <BootstrapTable
                  data={data?.results}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  pagination
                  bordered={false}
                  striped={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                    }}
                    width="150"
                    dataField="property_name"
                    isKey={true}
                    // filter={{
                    //   type: "TextFilter",
                    // }}
                    filter={{
                      type: "CustomFilter",
                      getElement: (filterHandler) =>
                        getCustomTextFilter(filterHandler, "name"),
                    }}
                    dataFormat={(cell, row) => projectNameFormatter(cell, row)}
                  >
                    Projet Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="location_id"
                    filterValue={locationFormat}
                    dataFormat={(cell, row) => locationFormat(cell, row)}
                    // filter={{
                    //   type: "TextFilter",
                    // }}
                    filter={{
                      type: "CustomFilter",
                      getElement: (filterHandler) =>
                        getCustomTextFilter(filterHandler, "location"),
                    }}
                  >
                    Location
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="200"
                    dataField="id"
                    // filter={{
                    //   type: "TextFilter",
                    // }}
                    filter={{
                      type: "CustomFilter",
                      getElement: (filterHandler) =>
                        getCustomTextFilter(filterHandler, "amount"),
                    }}
                    dataFormat={(cell, row) => priceForamt(cell, row)}
                  >
                    Amount requested
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="statut_project"
                    // filter={{
                    //   type: "SelectFilter",
                    //   options: status,
                    // }}
                    filter={{
                      type: "CustomFilter",
                      getElement: (filterHandler) =>
                        getCustomTextFilter(
                          filterHandler,
                          "status",
                          statusOption
                        ),
                    }}
                    dataFormat={(cell, row) => statutFormatter(cell, row)}
                  >
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="statut_publication"
                    dataFormat={(cell, row) =>
                      statutPublicationFormatter(cell, row)
                    }
                    // filter={{
                    //   type: "SelectFilter",
                    //   options: status_publication,
                    //   condition: "eq",
                    // }}
                    filter={{
                      type: "CustomFilter",
                      getElement: (filterHandler) =>
                        getCustomTextFilter(
                          filterHandler,
                          "status_publication",
                          publishOptions
                        ),
                    }}
                  >
                    Publish status
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    dataField="id"
                    width="200"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="ShowListDocuemnt"
        aria-labelledby="ShowListDocuemntLabel"
        aria-hidden="true"
      >
        <ListDocuments project={item} />
      </div>
    </div>
  );
};

export default ProjetTable;
