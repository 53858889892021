import api from "../../Http/global-vars";
import { FilterType } from "../Article/articleAction";
import { FETCH_ADS_ADMIN, FETCH_ADS_VISITOR } from "../types";

export const adsList = ({ page = 1, limit = 12 }: FilterType) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = `${api}popup_ads/?page=${page}&limit=${limit}`;
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_ADS_ADMIN,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_ADS_ADMIN,
          payload: { results: [], count: 0 },
        });
      });
  };
};

export const adsListFront = () => {
  return async (dispatch) => {
    const url = `${api}visiteur/popup_ads/`;
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_ADS_VISITOR,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_ADS_VISITOR,
          payload: {},
        });
      });
  };
};
