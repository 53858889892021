import api from "../../Http/global-vars";
import { FilterType } from "../Article/articleAction";
import { DETAIL_PROJECT, FETCH_PROJECTS_FRONT } from "../types";

export const fetchProjectsFront = ({
  page = 1,
  limit = 12,
  property_type= "",
}: FilterType) => {
  return async (dispatch) => {
    const url = api + `projectbyvisitor/?page=${page}&limit=${limit}&property_type=${property_type}`;

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();

        dispatch({
          type: FETCH_PROJECTS_FRONT,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_PROJECTS_FRONT,
          payload: { results: [], count: 0 },
        });
      });
  };
};

export const fetchProjectsFrontGet = (data: any) => {
  return (dispatch) => {
    return dispatch({
      type: FETCH_PROJECTS_FRONT,
      payload: data,
    });
  };
};
export const detailProjectFront = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "projectbyvisitorbyslug/" + id;

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        console.log("data results idididid", data)
        dispatch({
          type: DETAIL_PROJECT,
          payload: data
        });
      })
      .catch((error) => console.log("error", error));
  };
};
