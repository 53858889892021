import React from "react";
import { Show } from "react-iconly";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import { displayPriceType } from "../../../Http/global-vars";
import { useCurrency } from "../../../redux/store";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import { formatAmout } from "../../../utils/convert_currency";

type Props = {
  data: DatasListings;
};

const CardAppartItem: React.FC<Props> = ({ data }) => {
  const currency = useCurrency();

  return (
    <div className="">
      <div className="card card-appart-item position-relative">
        <NavLink to={`/listings/${data?.slug}`} className="no-link">
          <img
            src={
              data.image === "" ||
              data.image === undefined ||
              data.image === null
                ? `https://ui-avatars.com/api/?name=${data?.property_name}`
                : `${baseUrl + data?.image}`
            }
            className="card-img-top"
            height="300"
            alt="card-img"
          />
          <div
            className={`div-container-statut ${
              data?.statut_listing === "sale"
                ? "span-for-sale"
                : "span-for-rent"
            }`}
          >
            <span>For {data?.statut_listing && data?.statut_listing}</span>
          </div>
          <div className="bloc-contain-prix">
            <p
              style={{
                color: "#fff",
                backgroundColor:
                  data?.statut_listing === "sale" ? "#cc1b0b70" : "#06640c70",
                padding: "5px 15px",
                borderRadius: "10px",
                fontWeight: 700,
              }}
            >
              {getPrice(data,currency)}
              {displayPriceType(data?.type_price)}
            </p>
          </div>
          <div className="card-body p-0">
            <div className="p-3">
              <h6 className="titre-card-item">
                {data?.property_name && data?.property_name}{" "}
              </h6>
              <p className="card-text text-card-item">
                {data?.country_id && data?.country_id?.title}
              </p>
            </div>
            <div className="bloc-card-div-border mt-2 px-3">
              <div>
                <p className="para-text-div-border">
                  {data?.bedroom && data?.bedroom} bedroom
                </p>
              </div>
              <div className="border-left border-right no-border-mobile">
                <p className="p-3 para-text-div-border no-p-mobile">
                  {data?.kitchen && data?.kitchen} kitchens
                </p>
              </div>
              <div className="d-flex justify-bloc-mobile align-items-center">
                <div className="pr-xl-4 pr-div-tablet">
                  <p className="para-text-div-border">
                    {data?.bathroom && data?.bathroom} bathroom
                  </p>
                </div>
                <div>
                  <Show set="bold" primaryColor="rgba(102, 102, 102, 0.69)" />
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default CardAppartItem;

export function getPrice(item: DatasListings,currency) {
  if (!item) return "-";
  if (item.price) return formatAmout(item.price, currency);
  return formatAmout(item.price, "GMD");
}
