import axios from "axios";
import api from "../Http/global-vars";

export default {
  async onAdd(data, config) {
    const response = await axios.post(`${api}timeline/`, data, config);
    return response.data;
  },
  async onEdit(id, data, config) {
    const response = await axios.put(`${api}timeline/${id}/`, data, config);
    return response.data;
  },

  async onDelete(id, config) {
    const response = await axios.delete(`${api}timeline/${id}/`, config);
    return response.data;
  },
};
