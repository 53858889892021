import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { CustomPagination } from "../../../../modules/Pagination/PaginationV2";
import { adsList, adsListFront } from "../../../../redux/ads/adsAction";
import AdsTableSkeleton from "./AdsTableSkeleton";
import baseUrl from "../../../../Http/backend-base-url";
import api from "../../../../Http/global-vars";
import { ADS } from "../../../../redux/ads/adsReducer";

function AdsTable() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useSelector(
    (state: RootStateOrAny) => state.adsList
  );

  useEffect(() => {
    dispatch(adsList({ page, limit }));
  }, [page, limit]);

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <NavLink
          to={{
            pathname: `/admin/ads-edit/${row.id}`,
            state: row,
          }}
          style={{ color: "#2D3748" }}
          className="mr-2"
          title="Edit"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>

        <button
          style={{ color: "#E53E3E" }}
          className="mr-2 bg-transparent"
          onClick={() => onDelete(row.id)}
          title="Delete"
        >
          <Delete style={{ fontSize: "20px" }} />
        </button>
      </div>
    );
  };

  const onDelete = (id) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to delete this ads?",
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#bfc0c9",
      confirmButtonText: "YES",
      cancelButtonText: "NON",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Axios.delete(`${api}popup_ads/${id}/`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(adsList({ page, limit }));
            await dispatch(adsListFront());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Ads deleted successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          // allowOutsideClick: false,
          timer: 3000,
        });
      }
    });
  };

  const onChangeStatus = (item: ADS) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to change this status?",
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#bfc0c9",
      confirmButtonText: "YES",
      cancelButtonText: "NON",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Axios.put(
          `${api}popup_ads/${item?.id}/`,
          { is_published: !item?.is_published },
          config
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(adsList({ page, limit }));
            await dispatch(adsListFront());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed && !result?.value?.error) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Status changed successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          // allowOutsideClick: false,
          timer: 3000,
        });
      }
    });
  };

  const photoFormatter = (cell, row) => {
    return (
      <img
        src={
          !row?.image
            ? `https://ui-avatars.com/api/?name=${row?.title}`
            : `${baseUrl + row?.image}`
        }
        alt="avatar"
        height="50"
        width="50"
      />
    );
  };

  const statusFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          onClick={() => onChangeStatus(row)}
          className={
            cell === true
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-red text-uppercase cursor-pointer`
          }
        >
          {cell === true ? "Publish" : "No publish"}
        </button>
      </span>
    );
  };

  const countFormatter = (cell, row) => {
    return cell + " click" + `${cell > 1 ? "s" : ""}`;
  };

  const renderPaginationPanel = (props) => {
    return (
      <div className="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}

        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {/* {article_data.length ? ( */}
            <div>
              {isLoading && <AdsTableSkeleton />}
              {!isLoading && (
                <div className="table-container-activite">
                  <BootstrapTable
                    data={data?.results}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="180"
                      dataField="id"
                      isKey={true}
                      hidden
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="60"
                      dataField="image"
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Image
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="150"
                      dataField="description"
                    >
                      Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="150"
                      dataField="link"
                    >
                      Link
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="80"
                      dataField="views"
                      dataFormat={countFormatter}
                    >
                      Clicks
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="is_published"
                      dataFormat={statusFormatter}
                    >
                      Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="id"
                      width="80"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsTable;
