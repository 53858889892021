import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../../Http/global-vars";
import { countriesList } from "../../../../redux/countries/countriesAction";
// import $ from "jquery";
type ArticleType = { title: string };
function ModalCountry() {
  const dispatch = useDispatch();
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = yup.object().shape({
    title: yup.string().required("This filed is required"),
  });

  const { register, handleSubmit, formState, setValue, clearErrors, reset } =
    useForm<ArticleType>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;
  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(() => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const onSubmitAddproperty = (data: ArticleType) => {
    setIsSuccessfullySubmittedForm(true);
    const request = Axios.post(`${api}country/`, data, config);

    request
      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: "Request sent successfully!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(countriesList());
              reset();
              $(".modal").modal("hide");
            }
          });
          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Errorr",
          html: "EN error occured",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header modal-faq-header-add-member">
            <h5
              className="modal-title titre-question-reponse"
              id="ProprietetypeModal"
            >
              Add Country
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              id="closemodalproperty"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            onSubmit={handleSubmit(onSubmitAddproperty)}
            className="form-input-profile-modal"
          >
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Name
                      </div>
                      <input
                        className="form-control-profil"
                        data-testid="nameId"
                        name="title"
                        placeholder="Name"
                        ref={register}
                        id="nameId"
                      />
                      {errors.title && (
                        <div
                          className="alert alert-danger sia-alert-danger closer mt-3"
                          role="alert"
                        >
                          {errors.title?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>
              {!isSuccessfullySubmittedForm ? (
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-taf-primary"
                    type="submit"
                    data-testid="sendButtonId"
                  >
                    Send
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-end py-3">
                  <span className="btn btn-valider-profil trans-0-2">
                    <i className="fas fa-spin fa-spinner"></i>
                    &nbsp;&nbsp;&nbsp;In progress...
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalCountry;
