import React from "react";
import {
  ArrowDownSquare,
  Category,
  Chat,
  EditSquare,
  Filter,
  Image2,
  Message,
  Paper,
  Send,
  Setting,
  Ticket,
  User,
  Wallet,
} from "react-iconly";
import { NavLink, useHistory } from "react-router-dom";
import "./NavbarContent.css";
const NavbarContent: React.FC = () => {
  const history = useHistory();
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userToken");
    window.localStorage.clear();
    history.push("/login");
  };
  return (
    <div className="hoverable" id="scrollbar">
      <div className="content">
        <ul className="nav nav-items-custom flex-column">
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard"
              activeClassName="actived"
            >
              <Category set="bold" secondaryColor="white" />
              <span className="hiddenable-navlink-label">Dashboard</span>
            </NavLink>
          </li>

          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/properties"
              activeClassName="actived"
            >
              <Wallet set="bold" />
              <span className="hiddenable-navlink-label">
                Rentals & Resales
              </span>
            </NavLink>
          </li>

          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/location-property"
              activeClassName="actived"
            >
              <Filter set="bold" />
              <span className="hiddenable-navlink-label">Manage Types</span>
            </NavLink>
          </li>

          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/projets"
              activeClassName="actived"
            >
              <Paper set="bold" />
              <span className="hiddenable-navlink-label">Projects</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/reservations"
              activeClassName="actived"
            >
              <ArrowDownSquare set="bold" />
              <span className="hiddenable-navlink-label">Reservations</span>
            </NavLink>
          </li>

          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/articles"
              activeClassName="actived"
            >
              <Chat set="bold" />
              <span className="hiddenable-navlink-label">Articles</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/contact"
              activeClassName="actived"
            >
              <Message set="bold" />
              <span className="hiddenable-navlink-label">Contact us</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/setting"
              activeClassName="actived"
            >
              <Setting set="bold" />
              <span className="hiddenable-navlink-label">Settings</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/sliders"
              activeClassName="actived"
            >
              <Image2 set="bold" />
              <span className="hiddenable-navlink-label">Sliders</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/ads"
              activeClassName="actived"
            >
              <Ticket set="bold" />
              <span className="hiddenable-navlink-label">Ads</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/testimonials"
              activeClassName="actived"
            >
              <EditSquare set="bold" />
              <span className="hiddenable-navlink-label">Testimonials</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/profiles"
              activeClassName="actived"
            >
              <User set="bold" />
              <span className="hiddenable-navlink-label">Profile</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/admin/newsletter"
              activeClassName="actived"
            >
              <Send set="bold" />
              <span className="hiddenable-navlink-label">Newsletter Email</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <span className="hiddenable-navlink-label">
              <select
                defaultValue={""}
                className="form-control select-langue-sidebar"
                id="exampleFormControlSelect1"
              >
                <option className="pr-2">English</option>
              </select>
            </span>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <span className="hiddenable-navlink-label">
              <button
                className="btn nav-link-vertival-custom"
                onClick={(e) => logout(e)}
              >
                Log out
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarContent;
