import api from "../../Http/global-vars";
import { SocialMediaDatas } from "../../repositories/user/SocialMediaDatas";
import { FETCH_SOCIAL_MEDIAS, FETCH_SOCIAL_MEDIAS_FRONT } from "../types";

export const fetchSocialMedias = () => {
  return async (
    dispatch: (arg0: { type: string; payload: SocialMediaDatas }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "social_media/";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_SOCIAL_MEDIAS,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchSocialMediasFront = () => {
  return async (
    dispatch: (arg0: { type: string; payload: SocialMediaDatas }) => void
  ) => {
    const url = api + "social_mediafront/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_SOCIAL_MEDIAS_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
