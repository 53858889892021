/*  */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
import * as yup from "yup";
import DatasAmeneties from "../../../repositories/user/ameneties/DatasAmeneties";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import { getNumberSelectOptions } from "../../../services/Endpoints";
import GoogleInput from "../../common/GoogleInput";


export function ContentEditor({
  setValue,
  data = "",
  name = "description",
}: {
  setValue: any;
  name?: string;
  data?: any;
}) {
  const [Id] = useState(`${name}_${Math.floor(Math.random() * 1000)}`);
  const editor = useRef();
  useEffect(() => {
    console.log(editor);
    // @ts-ignore
    if (!editor.current && window.ClassicEditor?.create) {
      // @ts-ignore
      editor.current = { setData: () => null, getData: () => null };
      // @ts-ignore
      window.ClassicEditor.create(document.querySelector(`#${Id}`), {
        initialData: data,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            // "imageUpload",
            "blockQuote",
            // "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ]
        },
        licenseKey: ""
      })
        .then((ed) => {
          const modelDocument = ed.model.document;
          console.log("modalDoc", modelDocument);
          modelDocument.on("change:data", (event) => {
            const data = ed.getData();
            setValue(name, data);
          });
          editor.current = ed;
        })
        .catch((err) => {
          console.error(err.stack);
        });
    }
  }, [editor.current]);

  useEffect(() => {
    if (editor.current) {
      // @ts-ignore
      const ed = editor.current.getData();
      if (ed !== data) {
        // @ts-ignore
        editor.current.setData(data);
      }
    }
  }, [editor.current, data]);

  return <div id={Id} />;
}

const ListingDetailsDeBaseSecondStep = ({
  formDataListing,
  setFormDataListing,
  finishedStep,
  setFinishedStep,
  handleSubmit: handleFinalSubmit,
  navigation,
}) => {
  const ameneties = useSelector((state: RootStateOrAny) => state.ameneties);

  const validationSchema = yup.object().shape({
    description: yup.string().required("This Field is required"),
    building_age: yup.string().required("This Field is required"),
    room: yup.string().required("This Field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    // reset,
    formState,
    clearErrors,
  } = useForm<DatasListings>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;
  const wf = watch();

  React.useEffect(() => {
    if (
      wf.city ||
      wf.description ||
      wf.building_age ||
      wf.room ||
      wf.ameneties ||
      wf.latitude ||
      wf.longitude
    ) {
      setFormDataListing((old: any) => {
        if (old.length === 1) {
          return [...old, wf];
        } else {
          old[1] = wf;
          return [...old];
        }
      });
    }
  }, [
    wf.description,
    wf.building_age,
    wf.room,
    wf.ameneties?.length,
    wf.latitude,
    wf.longitude,
    wf.city,
  ]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors]);

  useEffect(() => {
    register("description");
    register("city");
    register("latitude");
    register("longitude");
    const item = formDataListing[1];
    if (item) {
      setValue("description", item.description);
      setValue("building_age", item.building_age);
      setValue("room", item.room);
      setValue("ameneties", item.ameneties);
      setValue("latitude", item.latitude);
      setValue("longitude", item.longitude);
      setValue("city", item.city);
    }
  }, []);

  const DetailsDeBaseSecondStepSubmit = (data: DatasListings) => {
    setFinishedStep(true);
    handleFinalSubmit();
  };

  const selectNumber: string[] = getNumberSelectOptions();
  const item = formDataListing[1];
  return (
    <>
      <div className="form-group-add-project-group-container">
        <form
          className="form-input-profile pl-3"
          onSubmit={handleSubmit(DetailsDeBaseSecondStepSubmit)}
        >
          <div className="row">
            <div className="form-group form-textarea-group-container col-md-12">
              <label htmlFor="AdditionalInformation" className="custom-label">
                Description
              </label>
              <ContentEditor data={wf.description || ""} setValue={setValue} />
              {errors.description && (
                <div className="alert alert-danger sia-alert-danger closer mt-2">
                  {errors.description?.message}
                </div>
              )}
              <small className="text-danger"></small>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Built in</label>
                <input
                  type="date"
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="building_age"
                  ref={register}
                />

                {errors.building_age && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.building_age?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Rooms</label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="room"
                  ref={register}
                >
                  <option value="" >
                    Select an option
                  </option>
                  {selectNumber &&
                    selectNumber.map((item: string) => {
                      return (
                        <option key={`room_listing-${item}`} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </select>
                {errors.room && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.room?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <GoogleInput
                value={item?.city}
                onChange={(value) => {
                  setValue("city", value.rue);
                  setValue("latitude", value.latitude);
                  setValue("longitude", value.longitude);
                }}
              />
            </div>
          </div>
          <div className="form-add-project-group my-3">
            <label
              htmlFor="investmentFormat"
              className="form-add-project-label"
            >
              Amenities (optional)
            </label>
            <div className="row">
              {ameneties &&
                ameneties.data.map((item: DatasAmeneties) => {
                  return (
                    <div className="form-group col-md-3">
                      <input
                        type="checkbox"
                        id={item?.id + "-" + item?.name}
                        value={item?.id}
                        className="form-custom-para border-5"
                        style={{}}
                        ref={register}
                        name="ameneties"
                      />
                      <label
                        htmlFor={item?.id + "-" + item?.name}
                        style={{ top: "none" }}
                        className="custom-label-para label-para"
                      >
                        {item?.name}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="d-flex justify-content-start mt-5">
            {!finishedStep ? (
              <button className="btn-taf-primary" type="submit">
                Submit
              </button>
            ) : (
              <span className="btn-taf-primary">
                <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;&nbsp;In
                Progress...
              </span>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ListingDetailsDeBaseSecondStep;
