import { FETCH_DASHBOARD, FETCH_GRAPHE_DATA } from "../types";

export const grapheDataReducer = (
  state = {
    isLoading: true,
    data: [],
    dashboard: {
      properties_for_rent_count: 0,
      properties_for_sale_count: 0,
      reservations_count: 0,
      projects_count: 0,
    },
  },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_GRAPHE_DATA:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      return state;
  }
};
