import axios from "axios";
import { project_type } from "./Endpoints";

export default {
  async onAddProjectType(data, config) {
    const response = await axios.post(
      project_type.onPostProjectType(),
      data,
      config
    );
    return response.data;
  },
  async onEditProjectType(id, data, config) {
    const response = await axios.put(
      project_type.onPutProjectType(id),
      data,
      config
    );
    // console.log(response)
    return response.data;
  },

  async onDeleteProjectType(id, config) {
    const response = await axios.delete(
      project_type.deleteProjectType(id),
      config
    );
    // console.log(response)
    return response.data;
  },
};
