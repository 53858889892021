import React from 'react'
import Skeleton from 'react-loading-skeleton'

function NewsletterSkeleton() {
    return (
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style={{ color: '#A0AEC0', width: '90%' }} className="th-table-admin">Emails</th>
                        <th scope="col" style={{ color: '#A0AEC0', width: '10%'  }} className="th-table-admin">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default NewsletterSkeleton