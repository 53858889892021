import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { DocumentService } from "../../../services/Slider";
import "./Sliders.css";

const AddDocuments = ({
  document,
  onDone,
  projectId,
}: {
  document: any;
  projectId: any;
  onDone: () => any;
}) => {
  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [doc, setDoc] = React.useState<File | null | undefined>(null);

  const token = window.localStorage.getItem("userToken");

  const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "multipart/form-data",
		},
  };

  useEffect(() => {
		$("#formSlider").trigger("reset");
		setTimeout(() => {
			if (document?.id) {
				setTitle(document.title);
				setDoc(null);
			} else {
				setTitle("");
				setDoc(null);
			}
		}, 400);
  }, [document]);

  const submitAddSlider = () => {
		const fd = new FormData();
		fd.append("title", title);
		if (doc) {
			fd.append("doc", doc);
		}
		fd.append("project", projectId);
		if (!title) {
			Swal.fire({
				icon: "error",
				title: "Error",
				html: "The title is required",
				iconColor: "#df5656",
				showCancelButton: false,
				confirmButtonColor: "#df5656",
				cancelButtonColor: "#d33",
				confirmButtonText: "OK",
				allowOutsideClick: false,
			});
			return;
		}
		if (!doc && !document?.id) {
			Swal.fire({
				icon: "error",
				title: "Error",
				html: "Please select a file",
				iconColor: "#df5656",
				showCancelButton: false,
				confirmButtonColor: "#df5656",
				cancelButtonColor: "#d33",
				confirmButtonText: "OK",
				allowOutsideClick: false,
			});
			return;
		}
		setLoading(true);
		const request = document?.id
			? DocumentService.onEdit(document.id, fd, config)
			: DocumentService.onAdd(fd, config);
		request
			.then(async (res) => {
				await Swal.fire({
					icon: "success",
					title: "Success",
					html: document?.id
						? "Docuent updated successfully!"
						: "Docuent saved successfully!",
					iconColor: "#df5656",
					showCancelButton: false,
					confirmButtonColor: "#df5656",
					cancelButtonColor: "#d33",
					confirmButtonText: "OK",
					allowOutsideClick: false,
				}).then((result) => {
					setLoading(false);
					$("#updateAdminModal").modal("hide");
					onDone();
				});
			})
			.catch((error) => {
				console.log("error ", error);
				setLoading(false);
				Swal.fire({
					icon: "error",
					iconColor: "#df5656",
					showCancelButton: false,
					confirmButtonColor: "#df5656",
					cancelButtonColor: "#d33",
					confirmButtonText: "OK",
					title: "Slider not saved successfully.Please Check your entered data",
					showConfirmButton: true,
					allowOutsideClick: false,
				});
			});
  };

  return (
		<div className="modal-dialog modal-sm">
			<div className="modal-content faq-modal-content">
				<div className="modal-header modal-faq-header">
					<h5 className="modal-title titre-question-reponse">
						{document?.id ? "Update Document" : "Add Document"}
					</h5>
					<button
						type="button"
						className="close close-icon"
						onClick={() => {
							$("#updateAdminModal").modal("hide");
						}}
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form
					className="py-2 px-2"
					onSubmit={(e) => {
						e.preventDefault();
						submitAddSlider();
					}}
					id="formSlider"
				>
					<div className="form-group">
						<label htmlFor="title">Title</label>
						<input
							type="text"
							className="form-control"
							id="title"
							placeholder="Title"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="doc">{"Choose a pdf file (4mb max)"}</label>
						<input
							type="file"
							className="form-control-file"
							placeholder="File"
							accept="application/pdf"
							onChange={(e) => {
								if (e.target?.files?.length) {
									const file = e.target?.files[0];
									if (file && file.size / 1000000 > 4) {
										Swal.fire({
											icon: "error",
											title: "The file size should not exceed 4mb",
											iconColor: "#df5656",
											showCancelButton: false,
											confirmButtonColor: "#df5656",
											cancelButtonColor: "#d33",
											confirmButtonText: "OK",
											allowOutsideClick: false,
										});
										$("#doc").val("");
									} else {
										setDoc(e.target?.files[0]);
									}
								}
							}}
							id="doc"
						/>
					</div>
					<div className="d-flex justify-content-end mt-2">
						{!loading ? (
							<button
								className="btn-taf-primary"
								type="submit"
								form="formSlider"
							>
								{document?.id ? "Update" : "Save"}
							</button>
						) : (
							<span className="btn-taf-primary">
								<i className="fa fa-spin fa-spinner"></i>
								&nbsp;&nbsp;In Progress
							</span>
						)}
					</div>
				</form>
			</div>
		</div>
  );
};

export default AddDocuments;
