import api from "../../Http/global-vars";
import NewsletterData from "../../repositories/NewsletterData";
import { FilterType, PaginationResults } from "../Article/articleAction";
import { FETCH_NEWSLETTER_ADMIN } from "../types";

export const fetchNewsletterAdmin = ({ page = 1, limit = 10 }: FilterType) => {
  return async (
    dispatch: (arg0: {
      type: string;
      payload: PaginationResults<NewsletterData>;
    }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + `newsletters/?page=${page}&limit=${limit}`;

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_NEWSLETTER_ADMIN,
            payload: data,
          });
        })
        .catch((error) => {
          console.log("error", error?.response);
          dispatch({
            type: FETCH_NEWSLETTER_ADMIN,
            payload: {
              results: [],
              count: 0,
            },
          });
        });
    }
  };
};
