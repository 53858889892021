import { Check } from "@material-ui/icons";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

interface Props {
  amnetie: any;
}

const AmnetieItem: React.FC<Props> = ({ amnetie }) => {
  return (
    <div className="amnetie-item col-12 col-sm-6 col-xl-4 mb-2">
      <div className="amnetie-check-container">
        <Check />
      </div>
      <div className="amnetie-title-container">
        <h6 className="amnetie-title">{amnetie?.name}</h6>
      </div>
    </div>
  );
};

export default AmnetieItem;

export function Amineties({ amneties }) {
  if (!amneties) return null;
  const amenetiesFront = useSelector(
    (state: RootStateOrAny) => state.amenetiesToFront
  );
  return (
    <div className="row">
      {amneties.map((amnetie) => {
        if (typeof amnetie === "number") {
          amnetie =
            amenetiesFront.data.length &&
            amenetiesFront.data.find((x: any) => x.id === amnetie);
        }
        if (!amnetie?.id) return null;
        return <AmnetieItem key={amnetie.id} amnetie={amnetie} />;
      })}
    </div>
  );
}
