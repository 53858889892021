import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
const PER_PAGE = 24;
type Page = {
  page: number;
  next?: boolean;
  previous?: boolean;
  active: boolean;
};
type PaginationProps = {
  page: number;
  onPageChange: (nextPage: number) => void;
  total?: number;
  perPage?: number;
};


function Pagination({
  page,
  onPageChange,
  total,
  perPage = PER_PAGE,
}: PaginationProps) {
  const [pages, setPages] = useState<Page[]>([]);
  useEffect(() => {
    const numberOfPages = Math.ceil((total || 0) / perPage);
    const pages: Page[] = [];
    for (let i = 1; i <= numberOfPages; i++) {
      const newPage: Page = {
        active: page === i,
        page: i,
      };
      pages.push(newPage);
    }
    // console.log("p", pages, PER_PAGE, perPage);
    setPages(pages);
  }, [total, page, perPage]);
  if (pages?.length < 1) return null;

  return (
    /* @ts-ignore */
    <ReactPaginate
      breakLabel="..."
      initialPage={page - 1}
      nextLabel=">"
      onPageChange={(page) => onPageChange(page?.selected + 1)}
      pageRangeDisplayed={5}
      pageCount={total ? total : 0}
      previousLabel="<"
      //   renderOnZeroPageCount={null}
      pageClassName="page-item m-1"
      pageLinkClassName="page-link custom-page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link custom-previous-link"
      nextClassName="page-item"
      nextLinkClassName="page-link custom-next-link"
      breakClassName="page-item m-1"
      breakLinkClassName="page-link custom-break-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
}

export default Pagination;

type CustomPaginationProps = {
  nbPages: number | any;
  page: number;
  onChange: (page: number, perPage: number) => any;
  perPage: number;
  showPerPage?: boolean;
};
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
  showPerPage = true,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-baseline justify-content-between custom-paginate-padding">
      <div className="footer-form d-flex mt-3">
        {!!showPerPage && (
          <select
            className="form-control select-perpage"
            name="limit"
            id="limit"
            value={perPage}
            onChange={(e) => {
              onChange(1, parseInt(e.target.value, 10));
            }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        )}
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={Math.ceil((nbPages || 0) / perPage) || 0}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}