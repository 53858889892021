import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchProjectTypes } from "../../../../redux/ProjectTypes/ProjectTypesAction";
import ProjectTypeDataForm from "../../../../repositories/user/ProjectTypeDataForm";
import ProjectType from "../../../../services/ProjectType";

const ModalPropriete = (props) => {
  const typeForm = props.type_form;
  const ProjectTypeDatasForm = props.datas;

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    description: yup.string(),
  });

  const { register, handleSubmit, reset, setValue, formState } =
    useForm<ProjectTypeDataForm>({
      resolver: yupResolver(validationSchema),
    });
  const { errors } = formState;

  useEffect(() => {
    if (typeForm === "update") {
      setValue("name", ProjectTypeDatasForm?.name);
      setValue("description", ProjectTypeDatasForm?.description);
    }
  }, [
    ProjectTypeDatasForm?.description,
    ProjectTypeDatasForm?.name,
    setValue,
    typeForm,
  ]);

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmitProjectType = async (data: ProjectTypeDataForm) => {
    setSubmitedForm(true);
    setIsSuccessfullySubmittedForm(true);
    const messageSuccesByTypeForm =
      typeForm === "update"
        ? "Project Type updated successfully!"
        : "Project Type added successfully!";

    const request =
      typeForm === "update"
        ? ProjectType.onEditProjectType(ProjectTypeDatasForm?.id, data, config)
        : ProjectType.onAddProjectType(data, config);

    await request
      .then(async (resp) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          html: messageSuccesByTypeForm,
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            $(".modal").modal("hide");
            reset();
          }
        });
        dispatch(fetchProjectTypes());
        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Errorr",
          html: "Check your entered data",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        setSubmitedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div
            className="modal-header modal-faq-header modal-faq-header-add-member"
            data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
          >
            <h5
              className="modal-title titre-question-reponse"
              id="ProprieteModal"
              data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
            >
              {typeForm === "update" ? "Update" : "Add"} &nbsp;Project Types
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="form-input-profile-modal"
            onSubmit={handleSubmit(onSubmitProjectType)}
          >
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Name
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Name"
                        name="name"
                        ref={register({ required: true })}
                        data-testid="nameId"
                      />
                      {errors.name && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.name?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Description
                      </div>
                      <textarea
                        id="AdditionalInformation"
                        className="form-control-textarea px-3 pt-3 text-dark"
                        placeholder="Description"
                        name="description"
                        ref={register({ required: true })}
                        data-testid="descriptionId"
                      />
                      {errors.name && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.description?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>

              <div className="d-flex justify-content-end">
                {!isSuccessfullySubmittedForm ? (
                  <button
                    className="btn-taf-primary"
                    type="submit"
                    data-testid="sendBtnId"
                  >
                    Send
                  </button>
                ) : (
                  <span className="btn-connexion">
                    <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                    Progress
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalPropriete;
