import api from "../Http/global-vars";

export const authenticate = {
  onRegisterSocial(){
    return `${api}auth/socialregister/`;
  },
  onLogin() {
    return `${api}auth/get-token/`;
  },
  onRegesister() {
    return `${api}auth/register/`;
  },
  onMe() {
    return `${api}me/`;
  },
};

export const user = {
  chagePassword() {
    return `${api}changepassword/`;
  },
};

export const property = {
  onPostProperties() {
    return `${api}listing/`;
  },
  onPutProperties(id) {
    return `${api}listing/${id}/`;
  },
  onDeleteProperties(id) {
    return `${api}listing/${id}/`;
  },
  onMiseAvant(id, type) {
    return `${api}miseenavant/${id}/${type}/`;
  },
};

export const project = {
  onPostProject() {
    return `${api}project/`;
  },
  onPutProject(id) {
    return `${api}project/${id}/`;
  },
  onDeleteProject(id) {
    return `${api}project/${id}/`;
  },
  onMiseAvant(id, type) {
    return `${api}miseenavant/${id}/${type}/`;
  },
};
export const adminuser = {
  onPostAdmin() {
    return `${api}adminuser/`;
  },
  onPutAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
  onDeleteAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
};

export const location = {
  onPostLocation() {
    return `${api}location/`;
  },
  onPutLocation(id) {
    return `${api}location/${id}/`;
  },
  deleteLocation(id) {
    return `${api}location/${id}/`;
  },
};

export const about = {
  onPostAbout() {
    return `${api}about/`;
  },
  onPutAbout(id) {
    return `${api}about/${id}/`;
  },
  deleteAbout(id) {
    return `${api}about/${id}/`;
  },
};

export const project_type = {
  onPostProjectType() {
    return `${api}project_type/`;
  },
  onPutProjectType(id) {
    return `${api}project_type/${id}/`;
  },
  deleteProjectType(id) {
    return `${api}project_type/${id}/`;
  },
};

export const social_media = {
  onPostSocialMedia() {
    return `${api}social_media/`;
  },
  onPutSocialMedia(id) {
    return `${api}social_media/${id}/`;
  },
  deleteSocialMedia(id) {
    return `${api}social_media/${id}/`;
  },
};

export const partner = {
  onPostPartner() {
    return `${api}partner/`;
  },
  onPutPartner(id) {
    return `${api}partner/${id}/`;
  },
  deletePartner(id) {
    return `${api}partner/${id}/`;
  },
};

export const slider = {
  onPostSlider() {
    return `${api}slider/`;
  },
  onPutSlider(id) {
    return `${api}slider/${id}/`;
  },
  deleteSlider(id) {
    return `${api}slider/${id}/`;
  },
};

export const docsUrl = {
  list() {
    return `${api}document/`;
  },
  show(id) {
    return `${api}document/${id}/`;
  },
  showProject(id) {
    return `${api}documentbyproject/${id}/`;
  },
};
export const showof = {
  onPostShowOf() {
    return `${api}showof/`;
  },
  onPutShowOf(id) {
    return `${api}showof/${id}/`;
  },
  deleteShowOf(id) {
    return `${api}showof/${id}/`;
  },
};

export function getNumberSelectOptions() {
  return ["0", "1", "2", "3", "4", "5", "5+"];
}
