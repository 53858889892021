import api from "../../Http/global-vars";
import {
  FETCH_ADD_ARTICLE_LIST,
  FETCH_ARTICLE_DETAILS_FRONT,
  FETCH_ARTICLE_LIST,
  FETCH_ARTICLE_LIST_FRONT,
  FETCH_UPDATE_ARTICLE_LIST,
} from "../types";
export type PaginationResults<T> = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  limit?: number;
  nbPage?: number;
  nbPages?: number;
};
export type FilterType = {
  page?: number;
  limit?: number;
  q?: string;
  category?: string;
  title?: string;
  date?: string;
  author?: string;
  property_type?: any;
  status?: string;
  status_publication?: string;
  location?: string;
  amount?: string;
  type?: string;
  currency?: string;
  onGmb?: any;
  where?: any;
  bedroom?: any;
  price_min?: string;
  price_max?: string;
  amenitie?: any;
  currency_from?: string;
  currency_to?: string;
  id?: any;
  fullname?: string;
  phone?:string;
  email?:string;
  date_souhaite?:string;
  property_id?:string;
};

export const articleList = ({
  page = 1,
  limit = 12,
  q = "",
  date = "",
  author = "",
}: FilterType) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = `${api}article/?page=${page}&limit=${limit}&q=${q}&date=${date}&auteur=${author}`;
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_ARTICLE_LIST,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_ARTICLE_LIST,
          payload: { results: [], count: 0 },
        });
      });
  };
};

export const articleListFront = ({
  page = 1,
  limit = 12,
  q = "",
  category = "",
  date
}: FilterType) => {
  return async (dispatch) => {
    const url = `${api}articles/?page=${page}&limit=${limit}&q=${q}&category=${category}&date=${date}`;
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_ARTICLE_LIST_FRONT,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_ARTICLE_LIST_FRONT,
          payload: { results: [], count: 0 },
        });
      });
  };
};

export const articleDetails = (slug: string) => {
  return async (dispatch) => {
    const url = `${api}articlebyslug/${slug}/`;
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_ARTICLE_DETAILS_FRONT,
          payload: data,
        });
      })
      .catch((error) => {
        console.log("error", error?.response);
        dispatch({
          type: FETCH_ARTICLE_DETAILS_FRONT,
          payload: {},
        });
      });
  };
};

export const addArticle = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "article/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_ADD_ARTICLE_LIST,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const updateArticle = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "article/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_UPDATE_ARTICLE_LIST,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
