import { FETCH_LOCATION_FOR_CONNECTED_USER } from "../types";

export const getDatasLocationForConnectedUser = (currency: string) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LOCATION_FOR_CONNECTED_USER,
      payload: currency,
    });
  };
};

export function getCurrentUserInfos() {
  const url = "https://ipapi.co/json/";

  return fetch(url)
    .then(async (res) => {
      const data = await res.json();
      return data;
    })
    .catch((error) => null);
}
