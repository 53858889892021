import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import AjouterProjet from "./AjouterProjet";

const ModifierProjet = () => {
  const { state } = useLocation<ProjetDatas>();
  const history = useHistory();
  useEffect(() => {
    if (!state) {
      history.goBack();
    }
  }, [state]);
  return <AjouterProjet project={state} />;
};

export default ModifierProjet;
