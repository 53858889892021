import React from "react";
import "./ProfileAdmin.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import profileimage from "../../../images/profileadmin.png";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavBarVertical from "../nav/NavbarVertical";
import ModalProfile from "./ModalProfile/ModalProfile";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import { User } from "../../../repositories/user/User";
import { UserFormData } from "../../../repositories/user/UserFormData";
import Axios from "axios";
// import { useHistory } from "react-router-dom";
import api from "../../../Http/global-vars";
import { fetchMe } from "../../../redux/user/userActions";
import Swal from "sweetalert2";
import baseUrl from '../../../Http/backend-base-url';
import { MdOutlineModeEditOutline } from "react-icons/md";

const Profiladmin: React.FC = () => {
  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = React.useState(false);

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    fullname: yup.string().required("Fullname is required"),
    telephone: yup.string().required("Telephone is required"),
    adresse: yup.string().required("Address is required"),
    lieu_naissance: yup.string().required("Place of birth is required"),
    date_naissance: yup.string().required("Date of birth is required"),
  });

  const { register, handleSubmit, formState } =
    useForm<UserFormData>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // photo profile
  const changeAvatar = (e) => {
    setIsLoad(true)
    const file = e.target.files[0];

    const fd = new FormData();
    fd.append("avatar", file);

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg"
    ) {
      return Swal.fire({
        icon: "error",
        title: "Veuiller uploader une image de type jpg ou jpeg ou png",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      const request = Axios.put(`${api}user/${user.data?.id}/`, fd, config);

      request
        .then((res) => {
          if (res.status === 200) {
            dispatch(fetchMe());
            setIsLoad(false)
            setIsSuccessfullySubmittedForm(false);
          } else {
            Swal.fire({
              icon: "error",
              iconColor: "#df5656",
              showCancelButton: false,
              confirmButtonColor: "#df5656",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              title: "Please check the file entered.",
              showConfirmButton: true,
              allowOutsideClick: false,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: "Please check the data entered.",
            showConfirmButton: true,
            allowOutsideClick: false,
          });
          setIsSuccessfullySubmittedForm(false);
        });
    }
  };

  const onSubmitInfoUser = (data: UserFormData) => {
    setIsSuccessfullySubmittedForm(true);
    const request = Axios.put(`${api}user/${user.data?.id}/`, data, config);

    request
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: "Information successfully changed.",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(fetchMe());
            }
          });
          setIsSuccessfullySubmittedForm(false);
          dispatch(fetchMe());
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };



  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />

      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVertical />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="container-fluid">
            <div className="projets-container bloc-principal-body-content">
              {/* <div className="bloc-general">
            <div className="projets-container bloc-principal-body-content"> */}
              <div className="profile-containers">
                <div className="container-profile-edit container-profile">
                  <h1 className="mon-profil">Profile</h1>
                  <button
                    type="button"
                    className="btn btn-profile-admin"
                    data-toggle="modal"
                    data-target="#profileModal"
                  >
                    <MdOutlineModeEditOutline className="btn-profile-icon" />
                    Edit You Password
                  </button>
                  <ModalProfile />
                </div>
                <div className="d-flex align-items-center flex-column ">
                  <div className="mb-auto profile-image-admin">
                    <div className="profile-avatar-container">
                      <img src={user.data.avatar === '/mediafiles/avatars/default.png' ? `https://ui-avatars.com/api/?name=${user.data?.fullname}` : `${baseUrl + user.data.avatar}`} alt="avatar" />
                      {isLoad && (
                        <span className="charge">
                          <i
                            className="fas fa-spin fa-spinner"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </span>
                      )}

                      <span>
                        <i className="fas fa-camera"></i>
                        <p>Changer</p>
                        <input
                          type="file"
                          name="avatar"
                          id="file_up"
                          accept="image/*"
                          onChange={changeAvatar}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="text-jones-admin">
                    <h1 className="text-jones">{user.data.fullname}</h1>
                    <p className="text-admin">{user.data.user_type}</p>
                  </div>
                </div>

                <form
                  className="form-input-profile"
                  onSubmit={handleSubmit(onSubmitInfoUser)}
                >
                  <div className="container-input-address">
                    <h2 className="information-personnelle infos-perso-input">
                      information personnelles
                    </h2>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Fullname</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            data-testid="fullnameId"
                            name="fullname"
                            id="fullname"
                            ref={register}
                            defaultValue={user?.data?.fullname}
                          />
                          {errors.fullname && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.fullname?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Date</label>
                          <input
                            name="date_naissance"
                            className="form-control-profil"
                            type="date"
                            ref={register}
                            defaultValue={user?.data?.date_naissance}
                          />
                          {errors.date_naissance && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.date_naissance?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Place of birth
                          </label>
                          <input
                            type="text"
                            className="form-control-profil"
                            name="lieu_naissance"
                            ref={register}
                            defaultValue={user?.data?.lieu_naissance}
                          />
                          {errors.lieu_naissance && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.lieu_naissance?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Address</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            data-testid="adresseId"
                            name="adresse"
                            id="adresse"
                            ref={register}
                            defaultValue={user?.data?.adresse}
                          />
                          {errors.adresse && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.adresse?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Email</label>
                          <input
                            type="Email"
                            name="email"
                            className="form-control-profil"
                            data-testid="emailId"
                            id="email"
                            ref={register}
                            defaultValue={user?.data?.email}
                          />
                          {errors.email && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.email?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Telephone</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            data-testid="telephoneId"
                            name="telephone"
                            id="telephone"
                            ref={register}
                            defaultValue={user?.data?.telephone}
                          />
                          {errors.telephone && (
                            <div
                              className="alert alert-danger sia-alert-danger closer mt-3"
                              role="alert"
                            >
                              {errors.telephone?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* {!isSuccessfullySubmittedForm ? (
                      <button
                        type="submit"
                        className="btn btn-profile-admin cursor-pointer"
                        data-testid="buttonSubmit"
                      >
                        <MdOutlineModeEditOutline className="btn-profile-icon" />
                        To modify
                      </button>
                    ) : (
                      <span
                        className="btn btn-profile-admin cursor-pointer"
                        data-testid="buttonSubmit"
                      >
                        <MdOutlineModeEditOutline className="btn-profile-icon" />
                        <i className="fas fa-spin fa-spinner"></i>
                        &nbsp;&nbsp;&nbsp;In progress...
                      </span>
                    )} */}
                    <div className="d-flex justify-content-end mt-5">

                      {!isSuccessfullySubmittedForm ? (
                        <button
                          className="btn-taf-primary"
                          type="submit"
                          data-testid="buttonSubmit"
                        >
                          Modify
                        </button>

                      ) : (
                        <span className="btn btn-valider-profil trans-0-2">
                          <i className="fas fa-spin fa-spinner"></i>
                          &nbsp;&nbsp;&nbsp;In progress...
                        </span>
                      )}


                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="admin-container">
                <form className="form-input-profile">
                  <h2 className="information-personnelle infos-perso-input text-paaword-profile m-0">
                    Password
                  </h2>
                  <div className="container-input">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control-profil"
                            name="passwordConfirmation"
                            placeholder="*********"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div> */}
              {/* </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profiladmin;
