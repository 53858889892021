import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
import * as yup from "yup";
import DatasAmeneties from "../../../repositories/user/ameneties/DatasAmeneties";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import GoogleInput from "../../common/GoogleInput";
import { ContentEditor } from "../ListingAdmin/ListingDetailsDeBaseSecondStep";

const DetailsDeBaseSecondStep = ({
  formData,
  setFormData,
  finishedStep,
  setFinishedStep,
  handleSubmit: handleFinalSubmit,
}) => {
  const ameneties = useSelector((state: RootStateOrAny) => state.ameneties);

  const validationSchema = yup.object().shape({
    description: yup.string().required("This Field is required"),
  });

  const { register, setValue, handleSubmit, formState, watch, clearErrors } =
    useForm<ProjetDatas>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });
  const { errors } = formState;

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors]);
  const wf = watch();
  React.useEffect(() => {
    if (
      wf.description ||
      wf.ameneties?.length > 0 ||
      wf.latitude ||
      wf.city ||
      wf.longitude
    ) {
      setFormData((old: any) => {
        if (old.length === 1) {
          return [...old, wf];
        } else {
          old[1] = wf;
          return [...old];
        }
      });
    }
  }, [wf.description, wf.ameneties?.length, wf.latitude, wf.longitude]);

  useEffect(() => {
    const item = formData[1];
    register("description");
    register("latitude");
    register("longitude");
    register("city");
    if (item) {
      setValue("description", item.description);
      setValue("ameneties", item.ameneties);
      setValue("latitude", item.latitude);
      setValue("longitude", item.longitude);
      setValue("city", item.city);
    }
  }, []);

  const DetailsDeBaseSecondStepSubmit = (data: ProjetDatas) => {
    setFinishedStep(true);
    handleFinalSubmit();
  };
  const item = formData[1];
  return (
    <>
      <div className="form-group-add-project-group-container">
        <form
          className="form-input-profile pl-3"
          onSubmit={handleSubmit(DetailsDeBaseSecondStepSubmit)}
        >
          <div className="row">
            <div className="form-group form-textarea-group-container col-md-12">
              <label htmlFor="AdditionalInformation" className="custom-label">
                Description <sup className="text-danger h6">*</sup>
              </label>
              <ContentEditor data={wf.description || ""} setValue={setValue} />
              {errors.description && (
                <div className="alert alert-danger sia-alert-danger closer mt-2">
                  {errors.description?.message}
                </div>
              )}
              <small className="text-danger"></small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <GoogleInput
                value={item?.city}
                onChange={(value) => {
                  setValue("city", value.rue);
                  setValue("latitude", value.latitude);
                  setValue("longitude", value.longitude);
                }}
              />
            </div>
          </div>
          <div className="my-3">
            <label
              htmlFor="investmentFormat"
              className="form-add-project-label"
            >
              Amenities (optional)
            </label>
            <div className="row">
              {ameneties.data &&
                ameneties?.data?.map((item: DatasAmeneties) => {
                  return (
                    <div
                      key={`ameneties-project-${item.id}`}
                      className="form-group col-md-2 "
                    >
                      <input
                        type="checkbox"
                        id={item?.id + "-" + item?.name}
                        value={item?.id}
                        className="form-custom-para border-5 "
                        ref={register}
                        name="ameneties"
                      />
                      <label
                        htmlFor={item?.id + "-" + item?.name}
                        style={{ top: "none" }}
                        className="custom-label-para label-para"
                      >
                        {item?.name}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="d-flex justify-content-start mt-5">
            {!finishedStep ? (
              <button className="btn-taf-primary" type="submit">
                Submit
              </button>
            ) : (
              <span className="btn-taf-primary">
                <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;&nbsp;In
                Progress...
              </span>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default DetailsDeBaseSecondStep;
