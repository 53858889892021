import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Plus } from "react-iconly";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import { DocumentService } from "../../../services/Slider";
import AddDocuments from "../Sliders/AddDocument";

const ListDocuments = ({ project }) => {
  const dispatch = useDispatch();
  const [item, setItem] = React.useState<any>(false);
  const [data, setData] = React.useState([]);
  const getData = React.useCallback(
    (reset?: boolean) => {
      const token = window.localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (reset) {
        setData([]);
      }
      if (project) {
        DocumentService.onGet(project?.id, config)
          .then((res) => {
            console.log({ res });
            setData(res);
          })
          .catch((ex) => setData([]));
      }
    },
    [project]
  );

  React.useEffect(() => {
    getData(true);
  }, [project]);
  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-container">
          <NavLink
            to="#"
            style={{ color: "#2D3748" }}
            className="mr-2"
            data-toggle="modal"
            data-target="#updateAdminModal"
            onClick={() => setItem(row)}
            title="Edit this slide"
          >
            <EditTwoTone style={{ fontSize: "20px" }} />
          </NavLink>
          <NavLink
            to="#"
            style={{ color: "#E53E3E" }}
            className="mr-2"
            onClick={() => onDelete(row.id)}
            title="Delete this Slide"
          >
            <Delete style={{ fontSize: "20px" }} />
          </NavLink>
        </div>
      </>
    );
  };

  const token = window.localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this document ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteRequest = Axios.delete(`${api}document/${id}/`, config);
        deleteRequest
          .then((response) => {
            getData();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Document deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content p-2">
        <div className="modal-header modal-faq-header">
          <div>
            <h5 className="modal-title titre-question-reponse">
              List of documents {project?.property_name}
            </h5>
            <NavLink
              data-toggle="modal"
              data-target="#updateAdminModal"
              to="#"
              onClick={() => {
                setItem(null);
              }}
              className="btn btn-add-listing my-2"
            >
              <Plus set="light" primaryColor="#B0B0B0" />
              <span className="pl-2">Add Document</span>
            </NavLink>
          </div>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="child-table-container">
          <div className="table-body-container-activite">
            <div className="row easypm-table-row">
              <div className="col-md-12 easypm-table-col">
                <div className="table-container-activite">
                  <BootstrapTable
                    data={data}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn dataField="id" isKey={true} hidden>
                      ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="title"
                    >
                      Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="id"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade modal-faq modal-faq-contact"
            id="updateAdminModal"
            aria-labelledby="updateAdminModalLabel"
            aria-hidden="true"
          >
            <AddDocuments
              projectId={project?.id}
              document={item}
              onDone={() => {
                getData();
                setItem(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListDocuments;
