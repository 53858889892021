import api from "../../Http/global-vars";
import { FETCH_FRONT_SLIDER, FETCH_SLIDER } from "../types";

export const fetchSlider = () => {
  return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "slider/";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();

          dispatch({
            type: FETCH_SLIDER,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchSlidersFront = () => {
  return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
    const url = api + "sliderfront";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();

        dispatch({
          type: FETCH_FRONT_SLIDER,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
