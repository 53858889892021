import { EditTwoTone } from '@material-ui/icons';
import React from 'react'
import { Delete } from 'react-iconly';
import { NavLink } from 'react-router-dom';
import {
	BootstrapTable,
	TableHeaderColumn,
} from "react-bootstrap-table";
import baseUrl from "../../../Http/backend-base-url";
import { AiOutlineEye } from 'react-icons/ai';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Axios from "axios";
import api from '../../../Http/global-vars';
import { testimonialsAction } from '../../../redux/Testimonials/TestimonialsAction';
import ModalUpdateTestimonials from './Modal/ModalUpdateTestimonials';
import ModalTestimonials from './Modal/ModalTestimonials';
import TestimonialSkeleton from './TestimonialSkeleton';

const TestimonialsTable: React.FC = () => {

	const testimonial = useSelector((state: RootStateOrAny) => state.testimonial);
	const [testimonialData, setTestimonialData] = React.useState();
	const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(testimonialsAction());
  }, []);

	const options = {
		// searchField: createCustomSearchField,
		clearSearch: false,
		noDataText: "Aucune donnée disponible",
	};

	const photoFormatter = (cell, row) => {
		return <img src={row?.image === null ? `https://ui-avatars.com/api/?name=${row?.full_name}` : `${baseUrl + row?.image}`} alt="avatar" height='50' width='50' />

	};

	const nameFormatter = (cell, row) => {
		return (
			<div className="name-email-group">
				<span className="name">{row.full_name}</span>
			</div>
		);
	};

	const getTestimonials = (data: any) => {
		setTestimonialData(data)
	}

	const actionFormatter = (cell, row) => {
		const idmodal = 'testimonial' + row.id

		return (
			<>
				<div className="table-actions-container flex-c">
					<NavLink to="#" onClick={() => { getTestimonials(row) }} title="Show this testimonial">
						<AiOutlineEye data-toggle="modal" data-target="#testimonialsModal"
							className="btn-addadmin-icon" style={{ color: "#9B51E0", fontSize: '22px' }} />
					</NavLink>
					<button type="button"
						className="linkable icone-bg-testimonials" title="Edit this testimonial">
						<EditTwoTone data-toggle="modal" data-target={"#" + idmodal}
							style={{ fontSize: '20px', color: "#2D3748" }} className="mr-2" /></button>
					<NavLink
						to="#"
						style={{ color: "#E53E3E" }}
						className="mr-2"
						onClick={() => onDelete(row.id)}
						title="Delete this testimonial"
					>
						<Delete style={{ fontSize: '20px' }} />
					</NavLink>
				</div>
				<div
					className="modal fade modal-faq modal-faq-contact"
					id={idmodal}
					aria-labelledby="updateAdminModalLabel"
					aria-hidden="true"
				>
					<ModalUpdateTestimonials item={row} />
				</div>
			</>
		);
	};


	const onDelete = (id) => {
		const token = window.localStorage.getItem("userToken");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		Swal.fire({
			title: "Do you want to delete this project?",
			// showDenyButton: true,
			icon: 'warning',
			confirmButtonText: `YES`,
			cancelButtonText: `NO`,
			iconColor: "#df5656",
			showCancelButton: true,
			confirmButtonColor: "#df5656",
			cancelButtonColor: "#6c757d",
			allowOutsideClick: false,
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return Axios.delete(
					`${api}testimonial/${id}/`, config)
					.then(async (response) => {
						// console.log("res", response);
						await dispatch(testimonialsAction());
						return response;
					})
					.catch((error) => {
						// console.log(error);
						Swal.showValidationMessage(`Request failed: ${error}`);
					});
			},
		}).then((result) => {
			if (result.isConfirmed) {
				// console.log("res", result);
				Swal.fire({
					position: "center",
					icon: "success",
					title: "Project type deleted successfully",
					showConfirmButton: true,
					iconColor: "#df5656",
					confirmButtonColor: "#df5656",
					allowOutsideClick: false,
				});
			}
		});
	};
	// const token = window.localStorage.getItem('userToken');

	const contentFormat = (cell) => {
		return (<p className="text-content text-dark" dangerouslySetInnerHTML={{ __html: cell }}></p>)
	}

	return (
    <div className="child-table-container mt-5">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            <>
              <div className="table-container-activite">
                {!!testimonial?.isLoading && <TestimonialSkeleton />}
                {!testimonial?.isLoading && (
                  <BootstrapTable
                  data={testimonial?.data}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                  striped={true}
                  pagination
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                    }}
                    width="50"
                    dataField="pic"
                    isKey={true}
                    dataFormat={(cell, row) => photoFormatter(cell, row)}
                  >
                    Pictures
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="fullname"
                    dataFormat={(cell, row) => nameFormatter(cell, row)}
                  >
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="function"
                  >
                    Function
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#000000",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="content"
                    dataFormat={(cell) => contentFormat(cell)}
                  >
                    Content
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                      textAlign: "center",
                    }}
                    dataField="action"
                    width="100"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
                )}
                
              </div>
            </>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="testimonialsModal"
        aria-labelledby="addAdminModalLabel"
        aria-hidden="true"
      >
        <ModalTestimonials testimonial_data={testimonialData} />
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="updateAdminModal"
        aria-labelledby="updateAdminModalLabel"
        aria-hidden="true"
      >
        {/* <ModalUpdateAdmin user={userData} /> */}
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="showAdminModal"
        aria-labelledby="showAdminModalLabel"
        aria-hidden="true"
      >
        {/* <ModalShowAdmin datas_user={userData} /> */}
      </div>
    </div>
  );
}

export default TestimonialsTable