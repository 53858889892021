import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchLocations } from "../../../../redux/Location/LocationAction";
import { LocationFormData } from "../../../../repositories/user/LocationFormData";
import Location from "../../../../services/Location";

function ModalLocation(props) {
  const typeForm = props.type;
  const datasLocation = props.datas;

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const dispatch = useDispatch();
  // const user = useSelector((state: RootStateOrAny) => state.user);

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    description: yup.string(),
  });

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { register, setValue, handleSubmit, reset, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  useEffect(() => {
    if (typeForm === "update" && datasLocation?.id) {
      setValue("name", datasLocation?.name);
      setValue("description", datasLocation?.description);
      // setValue("longitude", datasLocation?.longitude);
      // setValue("latitude", datasLocation?.latitude);
    }
  }, [setValue, typeForm, datasLocation]);

  const onSubmitLocationDatas = async (data: LocationFormData) => {
    setSubmitedForm(true);
    setIsSuccessfullySubmittedForm(true);

    const fd = new FormData();
    fd.append("name", data.name);
    fd.append("description", data.description);
    // fd.append("latitude", data.latitude);
    // fd.append("longitude", data.longitude);
    const messageSuccesByTypeForm =
      typeForm === "update"
        ? "Location updated successfully."
        : "Location added successfully.";

    const request =
      typeForm === "update"
        ? Location.onEditLocation(datasLocation?.id, fd, config)
        : Location.onAddLocation(fd, config);

    request
      .then((res) => {
        // console.log("response post data location",data);
        if (res.status === 201 || 200) {
          Swal.fire({
            icon: "success",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: messageSuccesByTypeForm,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              $(".modal").modal("hide");
            }
          });
          dispatch(fetchLocations());
          setIsSuccessfullySubmittedForm(false);
          reset();
        }
      })
      .catch((err) => {
        console.log("error", err.response, "datas", data);
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Check your entered datas.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        setSubmitedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header modal-faq-header-add-member">
            <h5
              className="modal-title titre-question-reponse"
              id="locationModal"
              data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
            >
              {typeForm === "update" ? "Update" : " Add"}
              &nbsp;&nbsp;Location{" "}
            </h5>
            <button
              type="button"
              data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="form-input-profile-modal"
            onSubmit={handleSubmit(onSubmitLocationDatas)}
          >
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-12">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Name
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Name"
                        name="name"
                        data-testid="nameId"
                        ref={register}
                      />
                      {errors.name && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.name?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Latitude</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            id="latitude"
                            name="latitude"
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Longitude</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            id="longitude"
                            name="longitude"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Description
                      </div>
                      <textarea
                        className="form-control-textarea px-3 pt-3 text-dark"
                        placeholder="Description"
                        name="description"
                        data-testid="descriptionId"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>

              <div className="d-flex justify-content-end">
                {!isSuccessfullySubmittedForm ? (
                  <button
                    className="btn-taf-primary"
                    type="submit"
                    data-testid="sendBtnId"
                  >
                    Send
                  </button>
                ) : (
                  <span className="btn-taf-primary">
                    <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                    Progress...
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalLocation;
