import React, { useEffect, useState } from "react";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import UseAddOrEditAdsForm from "./form/UseAddOrEditAdsForm";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import arrowback from "../../../images/arrowback.png";
import Axios from "axios";
import api from "../../../Http/global-vars";
import { ADS } from "../../../redux/ads/adsReducer";

function AddOrEditAds() {
  const { id } = useParams<any>();
  const token = window.localStorage.getItem("userToken");
  const history = useHistory();
  const location = useLocation<any>();
  const { state } = location;
  const [ads, setAds] = useState<ADS | undefined>(state);
  const { register, errors, onSubmit, isLoading, image, handleChangeImage } =
    UseAddOrEditAdsForm(ads);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getAdsById = (id) => {
    Axios.get(`${api}popup_ads/${id}/`, config)
      .then((res) => {
        setAds(res?.data);
      })
      .catch((err) => {
        history.push("/admin/ads");
      });
  };

  useEffect(() => {
    if (id) {
      getAdsById(id);
    }
  }, [id]);
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <NavLink to="/admin/ads">
                <h1 className="mon-profil">
                  <img src={arrowback} alt="parametre" />
                  Add ads
                </h1>
              </NavLink>
              <div className="mt-3">
                <form className="form-input-parametre" onSubmit={onSubmit}>
                  <div className="container-input-address">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Title</label>
                          <input
                            type="text"
                            className="form-control-profil"
                            placeholder="title"
                            name="description"
                            ref={register}
                            data-testid="titleId"
                          />
                          {errors.description && (
                            <div className="alert alert-danger sia-alert-danger closer mt-2">
                              {errors.description?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Link</label>
                          <input
                            type="url"
                            className="form-control-profil"
                            placeholder="Link"
                            name="link"
                            ref={register}
                          />
                          {errors.link && (
                            <div className="alert alert-danger sia-alert-danger closer mt-2">
                              {errors.link?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="infos-perso-input">
                          <label className="form-label-text">Image</label>
                          <input
                            type="file"
                            className="form-control-profil"
                            placeholder="Image"
                            name="image"
                            onChange={(e) => handleChangeImage(e)}
                          />
                          <small className="text-danger fw-bold d-flex">
                            Max dimension 750x400px
                          </small>
                          {errors.image && (
                            <div className="alert alert-danger sia-alert-danger closer mt-2">
                              {errors.image?.message}
                            </div>
                          )}
                          {!!image && (
                            <img
                              src={image}
                              alt="ads-img"
                              height="500"
                              width="750"
                              className="my-2"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-start mt-5">
                      {!isLoading ? (
                        <button
                          className="btn-taf-primary"
                          type="submit"
                          data-testid="submitBtnId"
                        >
                          Submit
                        </button>
                      ) : (
                        <span className="btn btn-valider-profil trans-0-2">
                          <i className="fas fa-spin fa-spinner"></i>
                          &nbsp;&nbsp;&nbsp;In progress...
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditAds;
