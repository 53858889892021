import React from "react";
import { useStep } from "react-hooks-helper";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import ArticleType from "./ArticleType";
import ChampsBase from "./ChampsBase";
import Countries from "./Countries";
import LocationPropriete from "./Location";
import PropertyType from "./PropertyType";

// import ModalParametre from './ModalParametre/ModalAddAdmin';

const steps = [
  {
    id: "location-propriete",
    Component: LocationPropriete,
  },
  {
    id: "champs-base",
    Component: ChampsBase,
  },
  {
    id: "property-type",
    Component: PropertyType,
  },
  {
    id: "article-type",
    Component: ArticleType,
  },
  {
    id: "countries",
    Component: Countries,
  },
];

const LocationPropertyAccueil: React.FC = () => {
  const {
    navigation: { go },
    index,
  } = useStep({
    initialStep: 0,
    steps,
  });

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit-setting">
                {/* ================================== TABS ===================================== */}
                <div className="settings-admin-stepper">
                  <div className="button-steps-location">
                    <button
                      onClick={() => go("location-propriete")}
                      className={`stepper-setting ${
                        index === 0 && "stepper-active-setting"
                      }`}
                    >
                      Location
                    </button>
                    <button
                      onClick={() => go("champs-base")}
                      className={`stepper-setting ml-3 ${
                        index === 1 && "stepper-active-setting"
                      }`}
                    >
                      Project Types
                    </button>
                    <button
                      onClick={() => go("property-type")}
                      className={`stepper-setting ml-3 ${
                        index === 2 && "stepper-active-setting"
                      }`}
                    >
                      Property Types
                    </button>
                    <button
                      onClick={() => go("article-type")}
                      className={`stepper-setting ml-3 ${
                        index === 3 && "stepper-active-setting"
                      }`}
                    >
                      Article Types
                    </button>
                    <button
                      onClick={() => go("countries")}
                      className={`stepper-setting ml-3 ${
                        index === 4 && "stepper-active-setting"
                      }`}
                    >
                      Countries
                    </button>
                  </div>
                </div>
                {index === 0 && <LocationPropriete />}
                {index === 1 && <ChampsBase />}
                {index === 2 && <PropertyType />}
                {index === 3 && <ArticleType />}
                {index === 4 && <Countries />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationPropertyAccueil;
