import React from "react";

interface Props {
	detailReservation: any;
}

const DetailsReservationModal: React.FC<Props> = ({ detailReservation }) => {
	return (
		<div className="modal-dialog modal-lg modal-dialog-centered">
			<div className="modal-content faq-modal-content">
				<div className="modal-header modal-faq-header">
					<h5
						className="modal-title titre-question-reponse"
						id="DetailsReservationModal"
					>
						Reservation Details
					</h5>
					<button
						type="button"
						className="close close-icon"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-input-profile-modal">
					<div className="modal-body">
						<div className="container-input-address-modal">
							<div className="row">
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											First name
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.first_name &&
												detailReservation?.first_name}
										</span>
									</div>
								</div>
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Last name
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.last_name &&
												detailReservation?.last_name}
										</span>
									</div>
								</div>
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Email
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.email &&
												detailReservation?.email}
										</span>
									</div>
								</div>
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Telephone
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.phone &&
												detailReservation?.phone}
										</span>
									</div>
								</div>
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Desired date
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.date_souhaite &&
												detailReservation?.date_souhaite}
										</span>
									</div>
								</div>
								<div className="col-md-6">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Property name/ Project name
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.property_id
												?.id
												? detailReservation
														?.property_id
														?.property_name
												: detailReservation
														?.project_id?.id
												? detailReservation
														?.project_id
														?.property_name
												: ""}
										</span>
									</div>
								</div>
								<div className="col-12">
									<div className="infos-perso-input-modal">
										<div className="form-label-text text-label-modal">
											Message
										</div>
										<span className="form-control-profil pt-2">
											{detailReservation?.message &&
												detailReservation?.message}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer faq-modal-footer">
						<button
							type="button"
							className="btn btn-retour-faq-modal"
							data-dismiss="modal"
						>
							Close
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DetailsReservationModal;
