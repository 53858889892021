/*  */
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { ArrowUp, ChevronRight } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../Http/global-vars";
import Facebook from "../../images/socials/facebook-footer.png";
import Instagram from "../../images/socials/instagram-footer.png";
import Twitter from "../../images/socials/twitter-footer.png";
import { PropertyDatas } from "../../repositories/user/PropertyDatas";
import "./FrontFooter.css";
import { capitalizeText } from "../../utils/utils";

const scrollTop = () => {
  window.scrollTo({
    top: document.documentElement.scrollTop,
    behavior: "smooth",
  });
};
const FrontFooter: React.FC = () => {
  const dispatch = useDispatch();
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email("Enter a valid email").required(),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors, reset } =
    useForm<any>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const config = {
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  };

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(() => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const onSubmit = (data: PropertyDatas) => {
    setIsSuccessfullySubmittedForm(true);

    const request = Axios.post(`${api}newsletter/`, data, config);
    request
      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: "Your newsletter subscription has been successfully sent",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              reset();
            }
          });

          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Errorr",
          html: "newsletter with this email already exists.",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  const socialmediasfront = useSelector(
    (state: RootStateOrAny) => state.socialmediasfront
  );
  const property_types_front = useSelector(
    (state: RootStateOrAny) => state.property_types_front
  );

  return (
    <footer className="component-front-footer">
      <div className="container-taf-footer">
        <div className="body-footer">
          <div className="row">
            <div
              className="col-xl-3 col-lg-3 mb-5 col-md-4 footer-grid"
              style={{ height: "max-content" }}
            >
              <p className="title-footer mb-4 ">Contact us</p>
              <a
                href="mailto:info@tafafricaglobal.com"
                target="_blank"
                rel="noopener noreferrer"
                className="link-footer trans-0-2 d-block mb-3"
              >
                The Gambia:
                <br />
                info@tafafricaglobal.com
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href={"tel:+220776-2333"}
              >
                +220776-2333
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href={"tel:+220 3762333"}
              >
                +220376-2333
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href="mailto:info@tafafricaglobal.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Nigeria:
                <br />
                info@tafafricaglobal.com
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href={"tel:(+234) 803 718 0488"}
              >
                (+234) 803 718 0488
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href="mailto:info@tafsalone.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Sierra Leone:
                <br />
                info@tafsalone.com
              </a>
              <a
                className="link-footer trans-0-2 d-block mb-3"
                href={"tel:(+232) 900 777 00"}
              >
                (+232) 900 777 00
              </a>
            </div>
            <div className="col-xl-2 col-lg-2 mb-5 col-md-4 footer-grid ">
              <p className="title-footer mb-4 ">Navigations</p>

              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to={"/about-us"}
              >
                About Us
              </NavLink>
              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to={"/projects"}
              >
                Projects
              </NavLink>
              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to={"/listing"}
              >
                Rentals & resales
              </NavLink>
              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to={"/contact"}
              >
                Contact us
              </NavLink>
              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to={"/blog"}
              >
                Blog
              </NavLink>
            </div>
            <div className="col-xl-2 col-lg-2 mb-5 col-md-4 footer-grid">
              <p className="title-footer mb-4 ">Highlights</p>
              {property_types_front.data.length > 0 &&
                property_types_front.data.map((item) => (
                  <NavLink
                    key={item.id}
                    className="link-footer trans-0-2 d-block mb-3"
                    to={{
                      pathname: `/listing`,
                      state: {
                        propertyType: item?.id,
                      },
                    }}
                  >
                    {capitalizeText(item?.name)}
                  </NavLink>
                ))}
            </div>
            {/* <div className="col-xl-2 col-lg-2 mb-5 col-md-4 footer-grid">
               <div className="d-flex flex-column ">
                <p className="title-footer mb-4 ">My account</p>
                <NavLink
                  className="link-footer trans-0-2  mb-3 d-block"
                  to="/app/faq"
                >
                  My Profile
                </NavLink>
                <NavLink
                  className="link-footer trans-0-2  mb-3 d-block"
                  to="/app/faq"
                >
                  My Account
                </NavLink>
                <NavLink
                  className="link-footer trans-0-2  mb-3 d-block"
                  to="/app/faq"
                >
                  Favorites
                </NavLink>
              </div> 
            </div> */}
            <div className="col-xl-4 pr-lg-5 col-lg-4 mb-5 footer-grid">
              <p className="title-footer mb-4 text-sm">
                Subscribe to our newsletter
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full mb-4">
                  <div className="pt-4 footer-form d-flex">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="input-email-footer"
                      ref={register}
                    />

                    <button
                      type="submit"
                      className="btn-submit-footer"
                      aria-label="submit"
                    >
                      {!isSuccessfullySubmittedForm ? (
                        <ChevronRight set="light" primaryColor="#fff" />
                      ) : (
                        <span className="btn-submit-footer">
                          <i className="fas fa-spin fa-spinner"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
              <div className="footer-form-langue mt-5">
                <div className="form-group mb-0">
                  <select
                    className="form-control form-langue-footer"
                    id="exampleFormControlSelect1"
                  >
                    <option>English</option>
                    {/* <option>French</option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-5 end-footer py-3 d-flex align-items-center px-footer-mobile">
        <div className="col-xl-6 col-lg-6 col-md-6 pl-5 no-pl-mobile pb-3">
          <p className="copyright">
            ©2021 All Rights Reserved. TAF Africa Global, Inc.
          </p>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 pb-3">
          <div className="d-flex justify-content-mobile">
            <a
              className="mx-3 trans-0-2"
              target="_blank"
              href={socialmediasfront?.data[0]?.facebook ?? "#"}
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className="icon-social-footer"
              />
            </a>
            <a
              className="mx-3 trans-0-2"
              target="_blank"
              href={socialmediasfront?.data[0]?.twitter ?? "#"}
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <img src={Twitter} alt="Twitter" className="icon-social-footer" />
            </a>
            <a
              className="mx-3 trans-0-2"
              target="_blank"
              href={socialmediasfront?.data[0]?.instagram ?? "#"}
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <img
                src={Instagram}
                alt="Instagram"
                className="icon-social-footer"
              />
            </a>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 d-flex justify-content-end pb-3">
          <div>
            <a
              href="#"
              className="toTop"
              onClick={() => scrollTop()}
              aria-label="top"
            >
              {" "}
              <ArrowUp set="light" primaryColor="#fff" />{" "}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
