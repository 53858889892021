import ProjectTypeDataForm from "../../repositories/user/ProjectTypeDataForm";
import { FETCH_PROJECT_TYPES } from "../types";

export const projectTypesReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: ProjectTypeDataForm }
) => {
  switch (action.type) {
    case FETCH_PROJECT_TYPES:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
