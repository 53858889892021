import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../../Http/global-vars";
import { contactList } from "../../../../redux/contact/contactAction";
import { responseContactList } from "../../../../redux/ResponseContact/ResponseContactAction";
import ContactFormData from "../../../../repositories/user/ContactFormData";
import "./ModalContact.css";

function ModalContact({ datas_contact }) {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    message: yup.string().required("This field is required"),
  });

  const { register, handleSubmit, reset, formState } = useForm<ContactFormData>(
    {
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    }
  );

  const { errors } = formState;

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmitAnswer = async (data: ContactFormData) => {
    // console.log(data)
    data["admin"] = userID;
    data["contact"] = datas_contact?.id && datas_contact?.id;

    setIsSuccessfullySubmittedForm(true);
    const request = Axios.post(`${api}responsecontact/`, data, config);
    await request
      .then(async (res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: "Message send successfully!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
          setIsSuccessfullySubmittedForm(false);
          reset();
          $(".modal").modal("hide");
          try {
            await Axios.put(
              `${api}contact/${datas_contact?.id}/`,
              {
                statut: true,
              },
              config
            );
          } catch (ex) {}
          dispatch(contactList({}));
          dispatch(responseContactList());
          // setSubmitedForm(false)
          // setSubmitedForm(false)
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div
      className="modal fade modal-faq modal-faq-contact"
      id="contactModal"
      //   tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header">
            <h5
              className="modal-title titre-question-reponse"
              id="exampleModalLabel"
            >
              Réponses à ({datas_contact?.name && datas_contact?.name},{" "}
              <span style={{ fontSize: 15 }}>
                {datas_contact?.email && datas_contact?.email}
              </span>
              )
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="contact-modal-form"
            onSubmit={handleSubmit(onSubmitAnswer)}
          >
            <div className="modal-body">
              <div className="div-para-contact-modal mb-5">
                <p className="contact-modal-para">
                  {datas_contact?.message && datas_contact?.message}
                </p>
              </div>
              <div className="form-group form-textarea-group-container col-md-12">
                <label htmlFor="AdditionalInformation" className="custom-label">
                  Description
                </label>
                <textarea
                  id="AdditionalInformation"
                  className="form-control-textarea px-3 pt-3"
                  placeholder="I"
                  name="message"
                  ref={register}
                />
                <small className="text-danger "></small>
              </div>
              {errors.message && (
                <div className="alert alert-danger sia-alert-danger closer">
                  {errors.message?.message}
                </div>
              )}
            </div>
            <div className="modal-footer faq-modal-footer">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>
              {!isSuccessfullySubmittedForm ? (
                <button className="btn-taf-primary" type="submit">
                  Send
                </button>
              ) : (
                <button
                  className="btn-taf-primary"
                  type="submit"
                  data-testid="submitBtnId"
                >
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                  Progress
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalContact;
