import React from "react";
import baseUrl from "../../../../Http/backend-base-url";

interface Props {
  testimonial_data: any;
}

function ModalTestimonials({ testimonial_data }) {
  return (
    <div className="modal-dialog modal-lg modal-dialog-centered fghjk">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            Testimonials
          </h5>

          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="container-input-address container-input-modal-member">
          <div className="card card-body ">
            <div className="modal-testimonials-content">
              <div className="div-modal-img-testimonials">
                <img
                  src={
                    testimonial_data?.image === null
                      ? `https://ui-avatars.com/api/?name=${testimonial_data?.full_name}`
                      : `${baseUrl + testimonial_data?.image}`
                  }
                  alt="avatar"
                  className="modal-img-testimonials"
                />
              </div>

              <div>
                <div className="bloc-flex-img-text">
                  <div className="div-inherit-position div-inherit-position-icon">
                    <img
                      src={
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAXCAYAAAD+4+QTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJqSURBVHgBtVbRcdpAEN09SYQxZKx0QCoIriBQQeggMJCZ/BlXYKgg9l8mxrFcQaAC4wpMKjAdmExghkEnrfcOZJ80EhIffh/idNq9t29vbw+EDDjfKnX++YwAdQjFZHP9fww5sHvVBhK0UNAxAVz6V6uZnk8l6FV/sHH/ZQLpAz+zSb6Xa460bzigBvADCEEA/eVROkmpV30AFb0BIvqzj6DkW3e8eM2cDhGm0VhAQkGSQMEKgntIQ991twRYg3hU8yhVMRLegzYr7SeWGQpbflx763kah7OU5zECXpxV948C58S0w2hQ6lYeTQeB1FlfrTzIgkqTtB9NAhEEzbSARKQiIXm4l0D5+NbAfOdqGmQp3qaL8DQWkZQe7AOrQMSvBsHUv17dZpkL7RDf7GlWRBGcwGrEJoi8ffbinbRa5kSIeAs5QMIvxutinwptz+dCnYHWa1BwyeXwSX/kBUjQ2P8VX4RL/Ym/ubvXGbPOOOV1TttCVxgHKkfL6QsJOzxgytmIgR03TtCEn5zGNp8NWz5BDpjwwh8tz9RYbXwtz0FVnj50TOA4fr496Cz0nW7lRo85XQTFMQy5MDiyu6IObN8UcBhOD7RXis6F3qzicAWhe4C9IqkrJXN4W7jc9uH+EA8S4Zx290RBLFg95N54rwzbFs4pmBzgMxbq0KjeU8hh1w020r4oupeqg+jqsqTsFHCabUbLgR55iwWEdAb5GCoRmkQ1RCY6UelIs1RKj6TdNOf83yuPQupk+SiCKChMfrG671s2UIN71jGG+C/gPTP7UBLldrkWCKshkHuX+pcSwoyv64nZyZ8BH9sSzxjUUMAAAAAASUVORK5CYII="
                      }
                      alt="Carousel Client"
                      className="img-icon-carousel-item"
                      style={{ marginLeft: -20 }}
                    />
                  </div>
                  <div className="div-inherit-position div-inherit-position-text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          testimonial_data?.content &&
                          testimonial_data?.content,
                      }}
                      className="text-temoignage-client modal-paragraph-testimonials"
                    />
                  </div>
                </div>
                <p className="nom-client-carousel name">
                  {testimonial_data?.full_name && testimonial_data?.full_name}
                </p>
                <p className="libelle-client-carousel">
                  {testimonial_data?.function && testimonial_data?.function}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTestimonials;
