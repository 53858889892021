import React from "react";
import Skeleton from "react-loading-skeleton";

const ArticleSkeleton = () => {
    return (
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style={{ color: '#A0AEC0' }} className="th-table-admin">Title</th>
                        {/* <th scope="col" style={{ color:'#A0AEC0' }} className="th-table-admin">Content</th> */}
                        <th scope="col" style={{ color: '#A0AEC0' }} className="th-table-admin">Written by</th>
                        <th scope="col" style={{ color: '#A0AEC0' }} className="th-table-admin">Date</th>
                        <th scope="col" style={{ color: '#A0AEC0' }} className="th-table-admin">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        {/* <td><Skeleton/></td> */}
                        {/* <td><Skeleton/></td>
                        <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        {/* <td><Skeleton/></td> */}
                        {/* <td><Skeleton/></td>
                        <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        {/* <td><Skeleton/></td> */}
                        {/* <td><Skeleton/></td>
                        <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        {/* <td><Skeleton/></td> */}
                        {/* <td><Skeleton/></td>
                        <td><Skeleton/></td> */}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ArticleSkeleton;