import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	BootstrapTable,
	TableHeaderColumn,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { Delete, EditTwoTone } from "@material-ui/icons/";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import Swal from 'sweetalert2';
import Axios from 'axios';
import api from '../../../Http/global-vars';
import { memberList } from '../../../redux/AddNewMemberSetting/ourTeamMemberAction';
import OurTeamSkeleton from './skeletons/OurTeamSkeleton';
import ModalUpdateMember from './ModalParametre/ModalUpdateMember';
import ModalShowMember from './ModalParametre/ModalShowMember';

const MeetourteamTable = (props) => {
	// var [userData, setUserData] = React.useState();
	const [memberData, setMemberData] = React.useState();

	// our_team_member
	const our_team_member = useSelector((state: RootStateOrAny) => state.our_team_member);
	// console.log(our_team_member)
	const dispatch = useDispatch();

	const [isPageLoading, setIsPageLoading] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setIsPageLoading(false);
		}, 3000);
	}, [our_team_member.data]);



	const options = {
		// searchField: createCustomSearchField,
		clearSearch: false,
		noDataText: "Aucune donnée disponible",
	};

	// const getMember = (data: any) => {
	// 	setMemberData(data)
	// }


	const photoFormatter = (cell, row) => {
		return <img src={(row.image === "" || row.image === undefined || row.image === null) ? `https://ui-avatars.com/api/?name=${row?.full_name}` : `${baseUrl + row?.image}`} alt="avatar" className="user-img" />;
	};

	const nameFormatter = (cell, row) => {
		return (
			<div className="name-email-group">
				<span className="name">{row.full_name}</span>
			</div>
		);
	};

	// const getDateRegister = (cell, row) => {
	// 	return moment(row?.date_joined).format('YYYY-MM-DD');
	// }

	const getMember = (data: any) => {
		setMemberData(data)
	}

	const actionFormatter = (cell, row) => {
		return (
			<>
				<div className="table-actions-container">
					<NavLink
						to="#"
						style={{ color: "#9B51E0" }}
						className="mr-2"
						data-toggle="modal" data-target="#showMemberModal"
						onClick={() => { getMember(row) }}
						title="Show this member"
					>
						<IconlyPack.Show />
					</NavLink>

					<NavLink
						to="#"
						style={{ color: "#2D3748", }}
						className="mr-2"
						data-toggle="modal" data-target="#updatememberModal"
						onClick={() => { getMember(row) }}
						title="Edit this member"
					>
						<EditTwoTone style={{ fontSize: '20px' }} />
					</NavLink>
					<NavLink
						to="#"
						style={{ color: "#E53E3E" }}
						className="mr-2"
						onClick={() => onDelete(row.id)}
						title="Delete this member"
					>
						<Delete style={{ fontSize: '20px' }} />
					</NavLink>
				</div>

			</>
		);
	};


	const onDelete = (id) => {
		const token = window.localStorage.getItem("userToken");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		Swal.fire({
			icon: 'success',
			title: "Success",
			html: "Do you want to delete this member?",
			iconColor: '#df5656',
			showCancelButton: true,
			confirmButtonColor: '#df5656',
			cancelButtonColor: '#bfc0c9',
			confirmButtonText: 'YES',
			cancelButtonText: 'NON',
			allowOutsideClick: false,
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return Axios.delete(
					`${api}member/${id}/`, config)
					.then(async (response) => {
						console.log("res", response);
						await dispatch(memberList());
						return response;
					})
					.catch((error) => {
						// console.log(error);
						Swal.showValidationMessage(`Request failed: ${error}`);
					});
			},
		}).then((result) => {
			if (result.isConfirmed) {
				// console.log("res", result);
				Swal.fire({
					icon: 'success',
					title: "Success",
					html: "Member deleted successfully!",
					iconColor: '#df5656',
					showCancelButton: false,
					confirmButtonColor: '#df5656',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK',
					// allowOutsideClick: false,
					timer: 3000,
				});
			}
		});
	};


	return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            <>
              {isPageLoading && <OurTeamSkeleton />}
              {!isPageLoading && (
                <div className="table-container-activite">
                  <BootstrapTable
                    data={our_team_member?.data}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="50"
                      dataField="pic"
                      isKey={true}
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Pic
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="full_name"
                      dataFormat={(cell, row) => nameFormatter(cell, row)}
                    >
                      Full name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="title"
                    >
                      Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="facebook"
                    >
                      Facebook
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="twitter"
                      // dataFormat={(cell, row) => getDateRegister(cell, row)}
                    >
                      Twitter
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="instagram"
                      // dataFormat={(cell, row) => getDateRegister(cell, row)}
                    >
                      Instagram
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="action"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-faq modal-faq-contact"
        id="updatememberModal"
        aria-labelledby="addAdminModalLabel"
        aria-hidden="true"
      >
        <ModalUpdateMember data_member={memberData} />
      </div>

      <div
        className="modal fade modal-faq modal-faq-contact"
        id="showMemberModal"
        aria-labelledby="showMemberModalLabel"
        aria-hidden="true"
      >
        <ModalShowMember datas_member={memberData} />
      </div>
    </div>
  );
};

export default MeetourteamTable;