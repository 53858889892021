import Axios from "axios";
import api from "../../Http/global-vars";
import { FETCH_DASHBOARD, FETCH_GRAPHE_DATA } from "../types";

export const grapheData = (date: any) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "graphpropertiesbymonth/" + date + "/";
    await Axios.get(url, config)
      .then(async (res) => {
        // const data = await res.json();
        console.log("data graphe", res);
        dispatch({
          type: FETCH_GRAPHE_DATA,
          payload: res.data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const dashboardData = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "dashboard/";
    await Axios.get(url, config)
      .then(async (res) => {
        // const data = await res.json();
        console.log("data dashboard", res);
        dispatch({
          type: FETCH_DASHBOARD,
          payload: res.data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
