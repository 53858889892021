import axios from "axios";
import { social_media } from "./Endpoints";

export default {
  async onAddSocialMedia(data, config) {
    const response = await axios.post(
      social_media.onPostSocialMedia(),
      data,
      config
    );
    return response.data;
  },
  async onEditSocialMedia(id, data, config) {
    const response = await axios.put(
      social_media.onPutSocialMedia(id),
      data,
      config
    );
    return response.data;
  },
  async onDeleteSocialMedia(id, config) {
    const response = await axios.delete(
      social_media.deleteSocialMedia(id),
      config
    );
    return response.data;
  },
};
