import { FETCH_SOCIAL_MEDIAS, FETCH_SOCIAL_MEDIAS_FRONT } from "../types";

export const socialMediaReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SOCIAL_MEDIAS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    default:
      return state;
  }
};

export const socialMediaFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_SOCIAL_MEDIAS_FRONT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};