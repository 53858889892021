import React from "react";
import * as IconlyPack from "react-iconly";
import { Plus } from "react-iconly";
import { Link } from "react-router-dom";
import ExcelModel from "../../../modeles/import_projects_model.xlsx";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import ImportProjectModal from "./ImportProjectModal";
import "./ProjetAdmin.css";
import ProjetTable from "./ProjetTable/ProjetTable";

const Projetadmin: React.FC = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit container-profile-list-project">
                <h1 className="mon-profil">List of projects</h1>
                <Link
                  to="/admin/ajouter-projet"
                  className="btn btn-add-listing"
                >
                  <Plus set="light" primaryColor="#B0B0B0" />
                  <span className="pl-2">Add a project</span>
                </Link>
              </div>
              <div className="export-container mt-3 mb-3">
                <div className="export-btn-container justify-content-center">
                  <a
                    className="btn btn-theme-plus modele-import-btn mr-3"
                    href={ExcelModel}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    style={{ letterSpacing: "normal" }}
                  >
                    <IconlyPack.PaperUpload
                      set="light"
                      primaryColor="#FFFFFF"
                    />
                    &nbsp;
                    <span className="file-label">
                      Download import file model
                    </span>
                  </a>

                  <button
                    className="btn btn-primary export-btn"
                    data-toggle="modal"
                    data-target="#ImportProjetModal"
                  >
                    <IconlyPack.Upload set="light" primaryColor="#9A160A" />
                    &nbsp;
                    <span className="export-btn-label">Import excel</span>
                  </button>
                </div>
              </div>
              <div className="users-table pt-2">
                <ProjetTable />
              </div>
              <div
                className="modal fade modal-faq ImportProjetModal"
                id="ImportProjetModal"
                data-backdrop="static"
                aria-labelledby="ImportProjetModalModalLabel"
                aria-hidden="true"
              >
                <ImportProjectModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projetadmin;
