import { PaginationResults } from "../Article/articleAction";
import { FETCH_ADS_ADMIN, FETCH_ADS_VISITOR } from "../types";

export type ADS = {
  id: number;
  slug: string;
  description: string;
  image: string;
  link: string;
  subscribe: string;
  listing: null;
  project: null;
  is_published: boolean;
  count: number;
};

export type AdsFormData = Partial<
  Omit<ADS, "id" | "slug" | "image" | "listing" | "project"> & {
    image: File;
    hasEdit: boolean;
  }
>;
export const adsReducer = (
  state = { isLoading: true, data: { results: [], count: 0 } },
  action: { type: any; payload: PaginationResults<ADS> }
) => {
  switch (action.type) {
    case FETCH_ADS_ADMIN:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const adsFrontReducer = (
  state: { isLoading: boolean; data: ADS | any } = {
    isLoading: true,
    data: {},
  },
  action: { type: any; payload: ADS }
) => {
  switch (action.type) {
    case FETCH_ADS_VISITOR:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
