import Axios from "axios";
import api from "../../Http/global-vars";
import DatasListings from "../../repositories/user/listings/DatasListings";
import { DETAIL_PROPERTY, FETCH_LIST_PROPERTY, UPDATE_PROPERTY } from "../types";
import { FilterType } from "../Article/articleAction";

export const listProperties = ({
  page = 1,
  limit = 10,
  q = "",
  status = "",
  status_publication = "",
  location = "",
  amount = "",
  type = "",
}: FilterType) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url =
      api +
      `listing/?page=${page}&limit=${limit}&property_name=${q}&status=${status}&status_publication=${status_publication}&location=${location}&amount_requested=${amount}&type=${type}`;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          if (res?.status === 401) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("userID");
            localStorage.clear();
            //@ts-ignore
            window.location = "/";
          } else {
            const data = await res.json();
            // console.log("data results", data.results)
            dispatch({
              type: FETCH_LIST_PROPERTY,
              payload: data,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          dispatch({
            type: FETCH_LIST_PROPERTY,
            payload: { results: [], count: 0 },
          });
        });
    }
  };
};

export const updateProperty = (id: DatasListings) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "listing/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results idididid", data)
          dispatch({
            type: UPDATE_PROPERTY,
            payload: data,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const listPropertiesDataToTest = async () => {
  try {
    return await Axios.get(`${api}listing`);
  } catch (e) {
    return [];
  }
};

export const listPropertiesDataFrontToTest = async () => {
  try {
    return await Axios.get(`${api}listingbyvisitor`);
  } catch (e) {
    return [];
  }
};

export const detailProject = (id: number) => {
  return async (
    dispatch: (arg0: { type: string; payload: any; isLoading: boolean }) => void
  ) => {
    const url = api + "listingbyvisitorbyslug/" + id;
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data listingbyvisitorbyslug", data);
        dispatch({
          type: DETAIL_PROPERTY,
          payload: data,
          isLoading: false,
        });
      })
      .catch((error) => console.log("error", error));
  };
};
