import { yupResolver } from "@hookform/resolvers/yup";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
// import { RangeSlider, Slider } from "rsuite";
import Slider from "rsuite/Slider"
import RangeSlider from "rsuite/RangeSlider"
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import { MAX_SIZE, MIN_MAX, TYPE_PRICE } from "../../../Http/global-vars";
import groupphoto from "../../../images/Groupphoto.png";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import DatasPropertyTypes from "../../../repositories/user/propertyTypes/DatasPropertyTypes";
import { getNumberSelectOptions } from "../../../services/Endpoints";
import { formatAmout } from "../../../utils/convert_currency";

const ListingDetailDeBase = ({
  formDataListing,
  setFormDataListing,
  navigation,
  pics,
  setPics,
  onDeletePics,
}) => {
  const [initPictures] = useState(formDataListing[0]?.pictures || []);
  const [image, setImage] = useState<string | File>();
  const [imageShow, setImageShow] = useState<string>();
  const [typeFormat, setTypeFormat] = useState<any>();
  const [emptyImage, setEmptyImage] = useState<any>();
  const [hideInput, setHideInput] = useState<boolean>();

  const property_types = useSelector(
    (state: RootStateOrAny) => state.property_types
  );
  const countries = useSelector((state: RootStateOrAny) => state.countries);
  const locations = useSelector((state: RootStateOrAny) => state.locations);

  const handleInputHidden = (e: any) => {
    const result = property_types.data.find(
      (x: any) => x.id === parseInt(e.target.value)
    );
    if (result?.name === "Office" || result?.name === "office") {
      setHideInput(true);
    } else {
      setHideInput(false);
    }
  };

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    return chosenFiles.map((f) => f.fileObject);
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = (
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );
    let buttonStartStyle: any = {
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
    };

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          <label htmlFor="dropzoneImage">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              onFiles(chosenFiles);
            }}
            id="dropzoneImage"
          />
        </div>
      </div>
    );
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const validationSchema = yup.object().shape({
    property_name: yup.string().required("This Field is required"),
    location: yup.string().required("This Field is required"),
    statut_listing: yup
      .string()
      .transform((value) => (value === "Select an option" ? undefined : value))
      .required("This Field is required"),
    price: yup.string().required("This Field is required"),
    country_id: yup.string().required("This Field is required"),
    // type_price: yup.string().required("This Field is required"),
    size: yup.string().required("This Field is required"),
    // postal_code: yup.string().required("This Field is required"),
    property_type: yup
      .string()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("This Field is required"),
    // property_type: yup.string().required('This Field is required'),
    bathroom: yup.string(),
    bedroom: yup.string(),
    kitchen: yup.string(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    // reset,
    watch,
    formState,
  } = useForm<DatasListings>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  React.useEffect(() => {
    register("price");
    register("max_price");
    register("size");
  }, []);
  const { errors } = formState;
  const wf = watch();
  React.useEffect(() => {
    if (
      image ||
      wf.virtual_video ||
      wf.kitchen ||
      wf.bathroom ||
      wf.bedroom ||
      wf.country_id ||
      wf.type_price ||
      wf.property_type ||
      wf.price ||
      wf.max_price ||
      wf.statut_listing ||
      wf.location ||
      wf.property_name ||
      wf.video_presentation ||
      wf.size
    ) {
      const obj = { ...wf };
      if (image) {
        obj.image = image;
      } else if (formDataListing[0]?.image) {
        obj.image = formDataListing[0]?.image;
      }
      setFormDataListing((old: any) => {
        if (old.length === 0) {
          return [obj];
        } else {
          old[0] = obj;
          return [...old];
        }
      });
    }
  }, [
    image,
    wf.virtual_video,
    wf.video_presentation,
    wf.kitchen,
    wf.bathroom,
    wf.bedroom,
    wf.country_id,
    wf.type_price,
    wf.property_type,
    wf.price,
    wf.max_price,
    wf.statut_listing,
    wf.location,
    wf.property_name,
    wf.size,
  ]);

  useEffect(() => {
    const item = formDataListing[0];
    if (item) {
      setValue("property_name", item.property_name);
      setValue("location", item.location);
      setValue("statut_listing", item.statut_listing);
      setValue("price", item.price);
      setValue("max_price", item.max_price);
      setValue("property_type", item.property_type);
      setValue("country_id", item.country_id);
      setValue("type_price", item.type_price);
      setValue("size", item.size);
      setValue("bathroom", item.bathroom);
      setValue("bedroom", item.bedroom);
      setValue("kitchen", item.kitchen);
      setValue("virtual_video", item.virtual_video);
      setValue("video_presentation", item.video_presentation);
      if (item.image) {
        if (typeof item.image !== "string") {
          setImageShow(URL.createObjectURL(item?.image));
        } else {
          setImageShow(baseUrl + item?.image);
        }
      }
    }
  }, []);

  const handleChange = (e: any) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setEmptyImage(false);
      setImageShow(URL.createObjectURL(file));
      setImage(file);
      setTypeFormat(SUPPORTED_FORMATS.includes(file.type));
    }
  };

  const onSubmitListings = () => {
    navigation.next();
  };

  const selectNumber: string[] = getNumberSelectOptions();

  return (
    <>
      <div className="form-group-add-project-group-container">
        <form
          className="form-input-profile pl-3"
          onSubmit={handleSubmit(onSubmitListings)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Property title <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control-profil"
                  placeholder="Property title"
                  name="property_name"
                  ref={register}
                />
                {errors.property_name && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.property_name?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Select status <sup className="text-danger h6">*</sup>
                </label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="statut_listing"
                  ref={register}
                >
                  <option value="">Select an option</option>
                  <option value="sale">For Sale</option>
                  <option value="rent">For Rent</option>
                </select>
                {errors.statut_listing && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.statut_listing?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Property type <sup className="text-danger h6">*</sup>
                </label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="property_type"
                  ref={register}
                  onChange={handleInputHidden}
                >
                  <option value="">Select an option</option>
                  {property_types?.data?.length > 0 &&
                    property_types.data?.map((item: DatasPropertyTypes) => {
                      return (
                        <option key={`${item.id}-property`} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
                {errors.property_type && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.property_type?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Price <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control-profil"
                  placeholder="GMD"
                  name="price"
                  step="any"
                  ref={register}
                />
                {errors.price && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.price?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="infos-perso-input">
                <label className="form-label-text">Price Type</label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect2"
                  name="type_price"
                  ref={register}
                >
                  <option value={""}>Select an option</option>
                  {TYPE_PRICE?.map((c) => (
                    <option value={c.value} key={c.value}>
                      {c.label}
                    </option>
                  ))}
                </select>
                {errors.type_price && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.type_price?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Size <sup className="text-danger h6">*</sup>
                </label>
                <RangeInput
                  onChange={(val) => {
                    setValue("size", val);
                  }}
                  max={MAX_SIZE}
                  value={wf.size}
                />
                {/* <input
                  type="number"
                  className="form-control-profil"
                  placeholder="Size"
                  name="size"
                  step="any"
                  ref={register}
                /> */}
                {errors.size && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.size?.message}
                  </div>
                )}
              </div>
            </div>
            {hideInput !== true && (
              <>
                <div className="col-md-6">
                  <div className="infos-perso-input">
                    <label className="form-label-text">Bathrooms</label>
                    <select
                      className="form-control-profil"
                      id="exampleFormControlSelect1bathroom"
                      name="bathroom"
                      ref={register}
                    >
                      <option value="">Select an option</option>
                      {selectNumber &&
                        selectNumber.map((item: string) => {
                          return (
                            <option
                              key={`bathroom_project-${item}`}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input">
                    <label className="form-label-text">Bedrooms</label>
                    <select
                      className="form-control-profil"
                      id="exampleFormControlSelect1bedroom"
                      name="bedroom"
                      ref={register}
                    >
                      <option value="">Select an option</option>
                      {selectNumber &&
                        selectNumber.map((item: string) => {
                          return (
                            <option
                              key={`bedroom_project-${item}`}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input">
                    <label className="form-label-text">Kitchen</label>
                    <select
                      className="form-control-profil"
                      id="exampleFormControlSelect1bedroom"
                      name="kitchen"
                      ref={register}
                    >
                      <option value="">Select an option</option>
                      {selectNumber &&
                        selectNumber.map((item: string) => {
                          return (
                            <option key={`kitchen_project${item}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Location <sup className="text-danger h6">*</sup>
                </label>

                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="location"
                  ref={register}
                >
                  <option value="">Select an option</option>
                  {locations.data &&
                    locations.data.map((location) => {
                      return (
                        <option
                          key={`location-${location.id}`}
                          value={location.id}
                        >
                          {location.name}
                        </option>
                      );
                    })}
                </select>
                {errors.location && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.location?.message}
                  </div>
                )}
              </div>
            </div>
            {/*  */}
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Country <sup className="text-danger h6">*</sup>
                </label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect2"
                  name="country_id"
                  ref={register}
                >
                  <option value="">Select an option</option>
                  {countries?.data?.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.title}
                    </option>
                  ))}
                </select>
                {errors.country_id && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.country_id?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Property image</label>
                <input
                  type="file"
                  className="form-control-profil"
                  placeholder="Postal Code"
                  name="image"
                  accept="image/*"
                  onChange={(e) => handleChange(e)}
                />
                <small className="text-danger fw-bold d-flex">Max dimension 800x500px</small>
                {typeFormat === false && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {"Supported files are: jpeg , png , gif , jpg"}
                  </div>
                )}
                {emptyImage === true && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {"This Field is required"}
                  </div>
                )}
                {imageShow && (
                  <div>
                    <img
                      src={imageShow}
                      className="card-img-top img-listing-card-item mt-2"
                      alt="Listing"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Project video link</label>
                <input
                  type="text"
                  className="form-control-profil"
                  name="video_presentation"
                  placeholder="https://www.youtube.com/watch?v=121243465654bcvhhtyry4"
                  ref={register}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Project virtual video link
                </label>
                <input
                  type="text"
                  className="form-control-profil"
                  name="virtual_video"
                  placeholder="https://www.youtube.com/watch?v=121243465654bcvhhtyry4"
                  ref={register}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 form-add-project-group form-group-add-project-dropzone-container">
            <label htmlFor="projectImage" className="form-add-project-label">
              Pictures
            </label>
            <Dropzone
              InputComponent={InputChooseFile}
              PreviewComponent={() => null}
              getFilesFromEvent={getFilesFromEvent}
              autoUpload={false}
              onChangeStatus={(file, status) => {
                if (status === "done") {
                  setPics((old) => [...old, { image: file.file, label: "" }]);
                }
              }}
              accept="image/*"
              maxFiles={100}
              styles={{
                dropzone: {
                  minHeight: 150,
                  maxHeight: 300,
                  border: "2px dashed #d9d9d9",
                  background: "#F3F3F3",
                  overflow: "hidden",
                  opacity: 1,
                },
              }}
            />
            <small className="text-danger fw-bold d-flex">Max dimension 800x500px</small>
            <div className="row">
              {pics.map((pic, index) => {
                const url = pic.id
                  ? baseUrl + pic.image
                  : URL.createObjectURL(pic.image);
                const key = pic.id || `picture${index}`;
                return (
                  <div key={key} className="col-md-4">
                    <div>
                      <img
                        src={url}
                        width={"100%"}
                        height="200"
                        className="mt-1"
                        alt="Listing"
                      />
                    </div>
                    <input
                      type="text"
                      value={pic.label}
                      onChange={(e) => {
                        const newPics = [...pics];
                        newPics[index].label = e.target.value;
                        setPics(newPics);
                      }}
                      className="form-control-profil mt-1"
                      name="label"
                      placeholder="Add image label"
                    />
                    <button
                      className="btn btn-danger btn-sm btn-block mt-1"
                      type="button"
                      onClick={() => {
                        onDeletePics(pic);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-start mt-5">
            <button className="btn-taf-primary" type="submit">
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ListingDetailDeBase;

export function RangeInput({ value, onChange, max = MIN_MAX.max }) {
  const isSize = max !== MIN_MAX.max;
  const val =
    typeof value === "number" ? value : value ? parseInt(value) || 0 : 0;
  return (
    <div className="">
      <Slider
        progress
        min={0}
        step={5}
        max={max}
        defaultValue={val}
        value={val}
        onChange={(value) => {
          onChange(value);
        }}
      />
      <label className="form-label-text">
        {isSize ? `${val}m²` : formatAmout(val)}
      </label>
    </div>
  );
}

export function RangeSliderInput({ value, onChange }) {
  const val1 = value[0];
  const val2 = value[1];
  const min = typeof val1 === "number" ? val1 : val1 ? parseInt(val1) || 0 : 0;
  const max = typeof val2 === "number" ? val2 : val2 ? parseInt(val2) || 0 : 0;
  return (
    <div className="">
      <RangeSlider
        progress
        step={10 * 1000}
        min={MIN_MAX.min}
        max={MIN_MAX.max}
        value={[min, max]}
        onChange={(value) => {
          onChange(value);
        }}
      />
      <div className="row">
        <div className="col">
          <label className="form-label-text">{formatAmout(min)}</label>
        </div>
        <div className="col text-right">
          <label className="form-label-text">{formatAmout(max)}</label>
        </div>
      </div>
    </div>
  );
}
