import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
export const MyGreatPlace = () => (
  <div
    style={{
      position: "absolute",
      width: 40,
      height: 40,
      left: -40 / 2,
      top: -40 / 2,
      border: "5px solid #f44336",
      borderRadius: 40,
      backgroundColor: "white",
      textAlign: "center",
      color: "#3f51b5",
      fontSize: 16,
      fontWeight: "bold",
      padding: 4,
    }}
  ></div>
);

export function GoogleMap(props) {
  const { defaultProps, style, className } = props;
  const { zoom, center } = defaultProps;
  let { lat, lng } = center;
  if (!lat || lat === 0 || !lng || lng === 0) {
    lat = 13.448395;
    lng = -16.57197;
  }

  const [changed, setChanged] = useState(false);
  useEffect(() => {
    setChanged(true);
    setTimeout(() => {
      setChanged(false);
    }, 400);
  }, [lat, lng, zoom]);
  if (changed) return null;
  console.log({ lat, lng });
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        ...style,
      }}
      className={className}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
        }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoom}
      >
        <MyGreatPlace lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
}
