/*  */
import React, { useEffect, useMemo, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { Download } from "react-iconly";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import baseUrl from "../../../Http/backend-base-url";
import { PROJECT_STATUS } from "../../../Http/global-vars";
import Share from "../../../images/icons/share.png";
import { ViewItem } from "../../../redux/ListPropertyToFront/listPropertyToFrontAction";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import { GoogleMap } from "../../admin/ListingAdmin/GoogleMap";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { Amineties } from "../listing/AmnetieItem";
import FeaturedProperty from "../listing/FeaturedProperty";
import { FormSendMessageSaleForce } from "../listing/FormSendMessage";
import PlayerVideo from "../shared/PlayerVideo";
import { Helmet } from "react-helmet";
import "./Projets.css";
import { IoCopy } from "react-icons/io5";
import Swal from "sweetalert2";
import {
  detailProjectFront,
  fetchProjectsFront,
} from "../../../redux/projectsFront/projectsFrontAction";
import baseUrlFront from "../../../Http/frontend-base-url";
import { capitalizeText } from "../../../utils/utils";

const DetailsProjet: React.FC = () => {
  const params = useParams<any>();
  let { slug } = params;
  const dispatch = useDispatch();
  const location = useLocation();
  const [linkCopied, setLinkCopied] = useState(false);
  const [openTafPopover, setOpenTafPopover] = useState(false);

  const openOrCloseTafPopover = () => {
    if (openTafPopover) {
      return setOpenTafPopover(false);
    }
    return setOpenTafPopover(true);
  };

  useScrollToTop();

  const [detailProjectDatas, setDetailProjectDatas] = useState<any>(
    location?.state || {}
  );
  const { data: project, isLoading } = useSelector(
    (s: RootStateOrAny) => s?.detail_project_front
  );
  const { data = { results: [] } } = useSelector(
    (state: RootStateOrAny) => state.projects_front
  );

  useEffect(() => {
    if (slug) {
      dispatch(detailProjectFront(slug));
    }
  }, [slug]);

  useEffect(() => {
    if (project?.slug === slug) {
      setDetailProjectDatas(project);
    }
  }, [project, slug]);

  React.useEffect(() => {
    if (detailProjectDatas?.id) {
      // dispatch(fetchProjectsFront({ property_type: detailProjectDatas?.property_type?.id || detailProjectDatas?.property_type, limit: 6 }));
      ViewItem.project(detailProjectDatas?.id);
    }
  }, [detailProjectDatas?.id]);

  const featuresPropertiesList = useMemo(
    () =>
      data.results?.length > 0 &&
      data.results
        ?.filter((x) => x?.slug !== slug)
        .map((featureProperty: ProjetDatas, index) => (
          <FeaturedProperty
            property={false}
            featureProperty={featureProperty}
            key={index}
          />
        )),
    [detailProjectDatas?.id, data, slug]
  );

  const defaultProps = {
    center: {
      lat: parseFloat(detailProjectDatas?.latitude || 0),
      lng: parseFloat(detailProjectDatas?.longitude || 0),
    },
    zoom: 11,
  };
  let name = detailProjectDatas?.property_name;
  if (detailProjectDatas?.property_type?.name) {
    name = `${name} (${detailProjectDatas?.property_type?.name})`;
  }
  // Fonction pour copier le lien
  const copyLink = () => {
    const linkInput = document.createElement("textarea");
    linkInput.value = window.location.href;
    document.body.appendChild(linkInput);
    linkInput.select();
    document.execCommand("copy");
    document.body.removeChild(linkInput);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 3000);
    // toast.success("The link has been copied!")
    Swal.fire({
      title: "The link has been copied!",
      icon: "success",
      showConfirmButton: false,
      timer: 3000,
    });
  };
  return (
    <div className="component-front-home">
      <Helmet>
        <title>{detailProjectDatas?.property_name}</title>
        <link rel="canonical" href={`${baseUrlFront}project-details/${slug}`}></link>
      </Helmet>
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="text-center border-bottom p-b-50 p-t-50">
          <h1 className="titre-page-content">Projects</h1>
          <p className="sous-titre-page-content">Explore your dream house</p>
        </div>
        <div>
          <div className="p-t-50">
            <div className="text-center p-b-50">
              <h3 className="title-card-item-listing mt-5">
                {capitalizeText(detailProjectDatas?.property_name &&
                  detailProjectDatas?.property_name)}
              </h3>
            </div>
            <div className="container-taf">
              <Gallery
                pictures={detailProjectDatas?.pictures || []}
                image={detailProjectDatas?.image}
                name={detailProjectDatas?.property_name}
              />
            </div>
          </div>
        </div>
        <div className="container-taf taf-relative-position-container">
          <div className="d-flex px-4 justify-content-between align-items-center">
            <h3 className="title-card-item-listing text-center">
              {capitalizeText(detailProjectDatas?.property_name &&
                detailProjectDatas?.property_name)}
            </h3>
            <span className="statut-project-details">
              {capitalizeText(PROJECT_STATUS[detailProjectDatas?.statut_project] || "New")}
            </span>
          </div>
          <div className="display-icon-detail-projet p-t-10">
            <div>
              <p className="page-content-text text-left pl-4">
                {capitalizeText(detailProjectDatas?.location_id?.name)},{" "}
                {capitalizeText(detailProjectDatas?.country_id?.title)}
              </p>
            </div>
            <div className="d-flex">
              <div className="share-btns-container">
                <button
                  onClick={() => openOrCloseTafPopover()}
                  className="btn share-in-rs-btn"
                  type="button"
                  data-bs-container="body"
                  data-bs-toggle="popover"
                  data-bs-placement="top"
                  data-bs-content="Top popover"
                >
                  <img src={Share} alt="Icon" />
                </button>
                {openTafPopover && (
                  <div className="taf-popover-container">
                    <div className="taf-popover-body">
                      <h6 className="blog-social-card-title">Social share:</h6>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={"TAF"}
                        about={
                          detailProjectDatas?.property_name &&
                          detailProjectDatas?.property_name
                        }
                        hashtag={"#TAF"}
                        className="blog-social-link"
                      >
                        <FacebookIcon size={20} round />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={window.location.href}
                        className="blog-social-link"
                        about={
                          detailProjectDatas?.property_name &&
                          detailProjectDatas?.property_name
                        }
                      >
                        <TwitterIcon size={20} round />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={window.location.href}
                        about={
                          detailProjectDatas?.property_name &&
                          detailProjectDatas?.property_name
                        }
                        className="blog-social-link"
                      >
                        <WhatsappIcon size={20} round />
                      </WhatsappShareButton>
                      <EmailShareButton
                        url={window.location.href}
                        subject={`Check out this project`}
                        body="I thought you might be interested in this project: "
                        className="blog-social-link"
                      >
                        <EmailIcon size={20} round />
                      </EmailShareButton>
                      <input
                        type="text"
                        value={window.location.href}
                        readOnly
                        className="d-none"
                      />
                      <button
                        onClick={copyLink}
                        aria-label="copy link"
                        className="react-share__ShareButton blog-social-link"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          padding: "0px",
                          font: "inherit",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                      >
                        <IoCopy
                          style={{ color: "#fff" }}
                          width={20}
                          height={20}
                          size={20}
                        />
                        {/* Copier le lien */}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-lg-7">
                <div className="bloc-shadow mb-3">
                  <p className="text-bold-detail-projet">
                    Make an appointment to visit today by filling the contact
                    form ( right)
                  </p>
                </div>
                <div className="bloc-shadow mb-3">
                  <p>
                    <span className="btn btn-titre-collapse p-0">
                      Description
                    </span>
                    <a
                      data-toggle="collapse"
                      href="#collapseDescriptions"
                      role="button"
                      aria-expanded="true"
                      aria-controls="collapseDescriptions"
                    >
                      <i className="fas fa-chevron-down float-right text-black"></i>
                    </a>
                  </p>
                  <div className="collapse show" id="collapseDescriptions">
                    <p
                      className="text-collapse-project-details project-detail-description py-3"
                      dangerouslySetInnerHTML={{
                        __html: detailProjectDatas?.description,
                      }}
                    />
                  </div>
                </div>
                <div className="bloc-shadow mb-3">
                  <p>
                    <span className="btn btn-titre-collapse p-0">
                      Amenities
                    </span>
                    <a
                      data-toggle="collapse"
                      href="#collapseAmeneties"
                      role="button"
                      aria-expanded="true"
                      aria-controls="collapseAmeneties"
                    >
                      <i className="fas fa-chevron-down float-right text-black"></i>
                    </a>
                  </p>

                  <div className="collapse show" id="collapseAmeneties">
                    <p className="text-collapse-project-details py-3">
                      <Amineties amneties={detailProjectDatas?.ameneties} />
                    </p>
                  </div>
                </div>
                {!!detailProjectDatas?.video_presentation && (
                  <div className="bloc-shadow mb-3">
                    <h2 className="mid-listing-title mb-3">Property video</h2>
                    <PlayerVideo
                      url={detailProjectDatas?.video_presentation}
                      showControls={false}
                    />
                  </div>
                )}
                <div className="bloc-shadow mb-3">
                  <h2 className="mid-listing-title mb-3">Map</h2>

                  <GoogleMap
                    style={{
                      height: "55vh",
                      width: "100%",
                    }}
                    className="px-1"
                    defaultProps={defaultProps}
                  />
                </div>
                {!!detailProjectDatas?.virtual_video && (
                  <div className="bloc-shadow mb-3">
                    <h2 className="mid-listing-title mb-3">Virtual visit</h2>
                    <PlayerVideo
                      url={detailProjectDatas?.virtual_video}
                      showControls={false}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <FormSendMessageSaleForce
                  type={"project"}
                  name={`${detailProjectDatas?.property_name}`}
                />
                <div className="featured-properties-container mt-5">
                  <h2 className="mid-listing-title mb-4">Featured projects</h2>
                  <div className="featured-properties">
                    {featuresPropertiesList}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              {detailProjectDatas?.documents?.map((i) => (
                <div
                  key={i.id}
                  className="col-md-4 text-center my-4  px-2 py-2"
                >
                  <div
                    className="bloc-shadow"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <h4 className="my-4">{i.title}</h4>
                    <a
                      href={baseUrl + i.doc}
                      download
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="btn btn-download"
                    >
                      <Download set="light" />
                      <span className="pl-2">Download</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default DetailsProjet;

export function useScrollToTop() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);
}
const gi = (img) => (img?.image ? baseUrl + img.image : null);

export function Gallery({ pictures, image, name }) {
  let allImage = [...pictures];
  if (image) {
    allImage = [{ id: 0, image: image, label: "" }, ...pictures];
  }
  return (
    <div className="my-2">
      <ModalImages pictures={allImage} />
    </div>
  );
}

function ModalImages({ pictures }) {
  if (pictures?.length <= 0) return null;
  return (
    <ImageGallery
      startIndex={0}
      showFullscreenButton={false}
      showPlayButton={false}
      useBrowserFullscreen={false}
      items={pictures.map((pic) => ({
        original: gi(pic),
        thumbnail: gi(pic),
        description: pic.label,
      }))}
    />
  );
}
