import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ADS, AdsFormData } from "../../../../redux/ads/adsReducer";
import baseUrl from "../../../../Http/backend-base-url";
import Axios from "axios";
import api from "../../../../Http/global-vars";
import { adsList, adsListFront } from "../../../../redux/ads/adsAction";
import { useDispatch } from "react-redux";

function UseAddOrEditAdsForm(ads?: ADS) {
  const [image, setImage] = useState<any>("");
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("userToken");
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    description: yup.string().required().label("Title"),
    image: yup.mixed().when("hasEdit", {
      is: false,
      then: () => yup.mixed().required().label("Image"),
    }),
    link: yup.string().url().required(),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<AdsFormData | any>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    register("image");
    register("hasEdit");
  }, []);

  const handleChangeImage = (e) => {
    let file = e?.target?.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setValue("image", file);
      console.log(file);
    } else {
      setImage("");
      setValue("image", "");
    }
  };

  useEffect(() => {
    if (ads?.id) {
      console.log(ads);
      setValue("hasEdit", true);
      const fields: any[] = ["description", "link", "subscribe"];
      for (let field of fields) {
        register(field);
        setValue(field, ads[field]);
      }

      if (ads?.image) {
        setImage(baseUrl + ads?.image);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [ads]);

  useEffect(() => {
    console.log("error", errors);
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => clearErrors(key)),
      3000
    );
  }, [clearErrors, errors]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    console.log("data", data);
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    let request: any = null;

    if (ads?.id) {
      request = Axios.put(`${api}popup_ads/${ads?.id}/`, fd, config);
    } else {
      request = Axios.post(`${api}popup_ads/`, fd, config);
    }
    if (request) {
      await request
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success",
              html: "Ads saved successfully!",
              iconColor: "#df5656",
              showCancelButton: false,
              confirmButtonColor: "#df5656",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                // $('.modal').modal('hide');
                
                history.push("/admin/ads");
                reset();
              }
            });
            dispatch(adsList({}));
            dispatch(adsListFront());
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("err", error);
          Swal.fire({
            icon: "error",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: error?.data?.message
              ? error?.data?.message
              : "Please check the data entered.",
            showConfirmButton: true,
            allowOutsideClick: false,
          });
          setIsLoading(false);
        });
    }
  };
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleChangeImage,
    image,
  };
}

export default UseAddOrEditAdsForm;
