import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchtimelines } from "../../../../redux/timelineAdmin/TimeLineAction";
import TimeLine from "../../../../services/TimeLine";
import { ContentEditor } from "../../ListingAdmin/ListingDetailsDeBaseSecondStep";

function ModalTimeLinePersonnalisation(props) {
  const typeAbout = props.type;
  const aboutDatas = props.datas;

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const dispatch = useDispatch();
  // const about = useSelector((state: RootStateOrAny) => state.about);

  const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    order: yup.number().required("This field is required"),
  });

  const token = window.localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { register, setValue, handleSubmit, reset, formState, watch } = useForm(
    {
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    }
  );
  const { errors } = formState;

  useEffect(() => {
    register("description");
  }, []);

  useEffect(() => {
    if (aboutDatas?.id) {
      setValue("title", aboutDatas?.title);
      setValue("description", aboutDatas?.description);
      setValue("order", aboutDatas?.order);
    }
  }, [
    setValue,
    aboutDatas?.title,
    aboutDatas?.description,
    aboutDatas?.order,
    aboutDatas?.id,
    typeAbout,
  ]);

  const { description } = watch(["description"]);

  const onSubmitAbout = async (data: any) => {
    setSubmitedForm(true);
    setIsSuccessfullySubmittedForm(true);

    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("description", data.description);
    fd.append("order", data.order);

    const messageSuccesBytypeAbout = aboutDatas?.id
      ? "Timeline updated successfully."
      : "Timeline added successfully.";

    const request = aboutDatas?.id
      ? TimeLine.onEdit(aboutDatas?.id, fd, config)
      : TimeLine.onAdd(fd, config);

    request
      .then((res) => {
        if (res.status === 201 || 200) {
          Swal.fire({
            icon: "success",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: messageSuccesBytypeAbout,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              $(".modal").modal("hide");
            }
          });
          dispatch(fetchtimelines());
          setIsSuccessfullySubmittedForm(false);
          if (!aboutDatas?.id) {
            setValue("title", "");
            setValue("description", "");
            setValue("order", "");
          }
        }
      })
      .catch((err) => {
        console.log("error", err.response, "datas", data);
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Check your entered datas.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        setSubmitedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header modal-faq-header-add-member">
            <h5
              className="modal-title titre-question-reponse"
              id="AboutModal"
              data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
            >
              {aboutDatas?.id ? "Update" : " Add"} Timeline
            </h5>
            <button
              type="button"
              data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="form-input-profile-modal"
            onSubmit={handleSubmit(onSubmitAbout)}
          >
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Title
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Title"
                        name="title"
                        data-testid="titleId"
                        ref={register}
                      />
                      {errors.title && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.title?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Order
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Order"
                        name="order"
                        data-testid="orderId"
                        ref={register}
                      />
                      {errors.order && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.order?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Description
                      </div>
                      <ContentEditor
                        setValue={setValue}
                        data={description}
                        name="description"
                      />
                      {errors.description && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.description?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>

              <div className="d-flex justify-content-end">
                {!isSuccessfullySubmittedForm ? (
                  <button
                    className="btn-taf-primary"
                    type="submit"
                    data-testid="sendBtnId"
                  >
                    Send
                  </button>
                ) : (
                  <span className="btn-taf-primary">
                    <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                    Progress...
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ModalTimeLinePersonnalisation;
