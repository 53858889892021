import { FETCH_AMENETIES, FETCH_AMENETIES_FRONT } from "../types";

export const amenetieReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_AMENETIES:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const amenetieFrontsReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_AMENETIES_FRONT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
