import React, { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import ModalAddPartner from "./ModalParametre/ModalAddPartner";
import { BsPlusSquare } from "react-icons/bs";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { PartnerDatas } from "../../../repositories/user/PartnerDatas";
import baseUrl from "../../../Http/backend-base-url";
import Swal from "sweetalert2";
import PartnerService from "../../../services/Partner";
import { fetchPartners } from "../../../redux/Partner/PartnerAction";
import Skeleton from "../../../utils/Skeleton";

const PartnerSkeleton = () => {
  return (
    <>
      {[...Array(8)]?.map((el, i) => (
        <div className="col-md-3 my-2" key={`partner${i}`}>
          <div className="img-card-partner mb-2 mb-md-0">
            <Skeleton
              variant="rect"
              className="img-partner w-100"
              height={180}
            />
            <div className="img-card-partner-icons">
              <Skeleton
                className="icon-edit-img-card-partner cursor-pointer"
                variant="rect"
                width={20}
                height={20}
              />
              <Skeleton
                className="icon-trash-img-card-partner cursor-pointer"
                variant="rect"
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const Partners: React.FC = () => {
  const partners = useSelector((state: RootStateOrAny) => state.partners);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  const getDatas = (item: any) => {
    setData(item);
  };
  const token = window.localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this partner ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = PartnerService.onDeletePartner(id, config);
        addRequest
          .then((response) => {
            dispatch(fetchPartners());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Partner deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              timer: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };
  return (
    <>
      <div className="profile-containers">
        <div className="d-flex justify-content-end mt-1">
          <button
            className="btn-taf-partner"
            data-toggle="modal"
            data-target="#addpartnerModal"
          >
            <BsPlusSquare className="mr-2 btn-taf-partner-icon" />
            Add partner
          </button>
        </div>
        <div className="mt-3">
          <div className="row mt-5 mb-5">
            {!!partners?.isLoading && <PartnerSkeleton />}
            {!partners?.isLoading &&
              (partners.data.length > 0 ? (
                partners.data.map((partner: PartnerDatas) => {
                  return (
                    <div className="col-md-3 my-2" key={`partner${partner.id}`}>
                      <div className="img-card-partner mb-2 mb-md-0">
                        <img
                          src={baseUrl + partner?.logo}
                          alt="partner1"
                          className="img-partner w-100"
                          height="180"
                        />
                        <div className="img-card-partner-icons">
                          <MdModeEdit
                            className="icon-edit-img-card-partner cursor-pointer"
                            title="Edit this partner"
                            data-toggle="modal"
                            data-target={`#addpartnerModal${partner?.id}`}
                            onClick={() => getDatas(partner)}
                          />
                          <IoMdTrash
                            className="icon-trash-img-card-partner cursor-pointer"
                            title="Delete this partner"
                            onClick={() => onDelete(partner?.id)}
                          />
                        </div>
                      </div>
                      <div
                        className="modal fade modal-faq modal-faq-contact"
                        id={`addpartnerModal${partner?.id}`}
                        aria-labelledby="addAdminModalLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                      >
                        <ModalAddPartner
                          datas={partner}
                          modalId={`addpartnerModal${partner?.id}`}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1>No Data</h1>
              ))}
          </div>
          <div
            className="modal fade modal-faq modal-faq-contact"
            id="addpartnerModal"
            aria-labelledby="addAdminModalLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <ModalAddPartner modalId="addpartnerModal" />
          </div>
          {/* <div
                        className="modal updatePartnerModal fade modal-faq modal-faq-contact"
                        id="updatePartnerModal"
                        aria-labelledby="addAdminModalLabel"
                        aria-hidden="true"
                    >
                        <ModalUpdatePartner datas={data} />
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default Partners;
