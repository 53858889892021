import React from "react";
import { useStep } from "react-hooks-helper";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import Administrator from "./Administrator";
import Ameneties from "./Ameneties";
import "./ParaAdmin.css";
import Partners from "./Partners";
import Personnalisation from "./Personnalisation";
import ShowOff from "./ShowOff";
import SocialMedia from "./SocialMedia";

// import ModalParametre from './ModalParametre/ModalAddAdmin';

const steps = [
  { id: "administrator", Component: Administrator },
  { id: "personnalisation", Component: Personnalisation },
  { id: "social-media", Component: SocialMedia },
  { id: "partners", Component: Partners },
  { id: "show-off", Component: ShowOff },
  { id: "ameneties", Component: Ameneties },
];

const Parametreadmin: React.FC = () => {
  const {
    navigation: { go },
    index,
  } = useStep({ initialStep: 0, steps });

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit-setting">
                {/* ================================== TABS ===================================== */}
                <div className="settings-admin-stepper">
                  <div className="admin-stepper-item">
                    <button
                      onClick={() => go("administrator")}
                      className={`stepper-setting ${
                        index === 0 && "stepper-active-setting"
                      }`}
                    >
                      Administrator
                    </button>
                    <button
                      onClick={() => go("personnalisation")}
                      className={`stepper-setting ${
                        index === 1 && "stepper-active-setting"
                      }`}
                    >
                      Personnalization
                    </button>
                    <button
                      onClick={() => go("social-media")}
                      className={`stepper-setting ${
                        index === 2 && "stepper-active-setting"
                      }`}
                    >
                      Social media
                    </button>
                    <button
                      onClick={() => go("partners")}
                      className={`stepper-setting ${
                        index === 3 && "stepper-active-setting"
                      }`}
                    >
                      Partners
                    </button>
                    <button
                      onClick={() => go("show-off")}
                      className={`stepper-setting ${
                        index === 4 && "stepper-active-setting"
                      }`}
                    >
                      Show off
                    </button>
                    <button
                      onClick={() => go("ameneties")}
                      className={`stepper-setting ${
                        index === 5 && "stepper-active-setting"
                      }`}
                    >
                      Amenities
                    </button>
                  </div>

                  {/* ================================= button add admin ================================ */}
                </div>

                {index === 0 && <Administrator />}
                {index === 1 && <Personnalisation />}
                {index === 2 && <SocialMedia />}
                {index === 3 && <Partners />}
                {index === 4 && <ShowOff />}
                {index === 5 && <Ameneties />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Parametreadmin;
