import { FETCH_PROMOTE_ELEMENT } from "../types";

export const promoteReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case FETCH_PROMOTE_ELEMENT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};