import React from "react";
import Skeleton from "react-loading-skeleton";

export function DocumentSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Name
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AdministratorSkeleton;

function AdministratorSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Photo
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Name
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Email
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Addresse
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Registration date
            </th>
            <th
              scope="col"
              style={{ color: "#A0AEC0" }}
              className="th-table-admin"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <Skeleton />
            </th>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
