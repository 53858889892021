import React from "react";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import * as IconlyPack from "react-iconly";
import NewsletteTable, { DataToExcel } from "./Table/NewsletteTable";
import api from "../../../Http/global-vars";

const Newsletter: React.FC = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit container-profile-list-project">
                <h1 className="mon-profil">Newsletter Emails</h1>
              </div>
              <div className="export-container mt-3 mb-3">
                <div className="export-btn-container justify-content-end">
                  {/* <DataToExcel /> */}
                  <a href={`${api}export_newsletters`} className="btn btn-primary export-btn-newsletter btn-theme-export no-link" target="_blank">
                    <IconlyPack.Upload set="light" primaryColor="#9A160A" />
                    &nbsp;
                    <span className="export-btn-label">Export excel</span>
                  </a>
                </div>
              </div>
              <div className="users-table pt-2">
                <NewsletteTable />
              </div>
              <div
                className="modal fade modal-faq ImportProjetModal"
                id="ImportProjetModal"
                data-backdrop="static"
                aria-labelledby="ImportProjetModalModalLabel"
                aria-hidden="true"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
