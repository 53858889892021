import { FETCH_FRONT_SLIDER, FETCH_SLIDER } from "../types";

export const sliderReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case FETCH_SLIDER:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const sliderFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_FRONT_SLIDER:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};