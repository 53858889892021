import React from "react";
import ProprieteTable from "./LesTable/ProprietesTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalPropriete from './LesModals/ModalProperty'

const LocationTables: React.FC =
  () => {

    return (
      <div className="container-table-location">
        <button
          type="button"
          data-toggle="modal"
          data-target="#ProprieteModal"
          className="btn btn-add-amin-setting ml-auto"
        >
          <AiOutlinePlusCircle className="btn-addadmin-icon" />
          <span className="">
            Add Project Types
          </span>
        </button>
        <div
          className="modal fade modal-faq modal-faq-contact"
          id="ProprieteModal"
          aria-labelledby="addAdminModalLabel"
          aria-hidden="true"
        >
          <ModalPropriete />
        </div>
        <div className="profile-containers">
          <div className="containter-table-admin ">
            <ProprieteTable />
          </div>
        </div>
      </div>
    );
  };

export default LocationTables;
