import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Listeadmin from "./ListeAdmin";
import ModalAddAdmin from "./ModalParametre/ModalAddAdmin";

const Administrator: React.FC = () => {
  return (
    <div className="profile-containers">
      <div className="container-profile-edit-reglage d-flex justify-content-between mt-2 mb-2">
        <h1 className="meet-team-title"></h1>
        <button
          type="button"
          data-toggle="modal"
          data-target="#addAdminModal"
          className={`btn btn-add-amin-setting`}
        >
          <AiOutlinePlusCircle className="btn-addadmin-icon" />
          <span className="">Add admin</span>
        </button>
      </div>

      <div className="containter-table-admin mt-5">
        <Listeadmin />
      </div>

      <div
        className="modal fade modal-faq modal-faq-contact"
        id="addAdminModal"
        aria-labelledby="addAdminModalLabel"
        aria-hidden="true"
      >
        <ModalAddAdmin />
      </div>
    </div>
  );
};

export default Administrator;
