
import { FETCH_ADD_PROPERTY_TYPE, FETCH_PROPERTY_TYPE, FETCH_UPDATE_PROPERTY_TYPE } from '../types';

export const propertyTypeReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_PROPERTY_TYPE:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const addpropertyTypeReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_ADD_PROPERTY_TYPE:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const updatepropertyTypeReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_UPDATE_PROPERTY_TYPE:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
