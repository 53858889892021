import { FETCH_PROJECTS, UPDATE_PROJECT } from "../types";

export const projectsReducer = (
  state = { isLoading: true, data: {results: [], count:0} },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};


export const updateProjectReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_PROJECT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

