import api from "../../Http/global-vars";
import { FETCH_PROPERTY_TYPE_FRONT } from "../types";

export const propertyTypesFront = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "property_typebyvisitor/";
    // if (token) {
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data results", data.results)
        dispatch({
          type: FETCH_PROPERTY_TYPE_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
    // }
  };
};
