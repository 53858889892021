import { SettingAddMemberData } from '../../repositories/user/SettingAddMemberData';
import { FETCH_ADD_MEMBER_LIST, FETCH_MEMBER_LIST, FETCH_MEMBER_LIST_FRONT, FETCH_UPDATE_MEMBER_LIST } from '../types';

export const ourTeamMemberReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: SettingAddMemberData }
) => {
  switch (action.type) {
    case FETCH_MEMBER_LIST:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
export const ourTeamMemberFrontReducer = (
  state = { isLoading: true, data: [] },
  action: { type: any; payload: SettingAddMemberData }
) => {
  switch (action.type) {
    case FETCH_MEMBER_LIST_FRONT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};



export const addmemberReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_ADD_MEMBER_LIST:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const updatememberReducer = (
  state = { isLoading: true, data: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_UPDATE_MEMBER_LIST:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};