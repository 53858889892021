import { EditTwoTone } from "@material-ui/icons";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Delete } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchtimelines } from "../../../redux/timelineAdmin/TimeLineAction";
import LoginFormData from "../../../repositories/user/LoginFormData";
import TimeLine from "../../../services/TimeLine";
import ModalTimeLinePersonnalisation from "./ModalParametre/ModalTimeLinePersonnalisation";
import AboutSkeleton from "./skeletons/AboutSkeleton";
// import LoginFormData from "../../../../repositories/user/LoginFormData";
//   import { fetchLocations } from "../../../../redux/Location/LocationAction";
//   import Location from "../../../../services/Location";
//   import ModalLocation from "../LesModals/ModalLocation";

const Timelinepersonnalisation = () => {
  const [isPageLoading, setIsPageLoading] = React.useState(true);

  const dispatch = useDispatch();

  const timeline = useSelector((state: RootStateOrAny) => state.timeline);
  const token = window.localStorage.getItem("userToken");

  React.useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);

  const actionFormatter = (cell, row) => {
    const id = `UpdateTimelineModal${row.id}`;
    return (
      <div className="parametre-actions-container">
        <NavLink
          to="#"
          style={{ color: "#2D3748" }}
          className="mr-2"
          data-toggle="modal"
          data-target={`#${id}`}
          title="Edit this about"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>

        <NavLink
          to="#"
          style={{
            color: "#E53E3E",
          }}
          title="Delete this about"
          onClick={() => onDeleteAbout(row?.id)}
        >
          <Delete
            set="bold"
            primaryColor="#E53E3E"
            style={{
              fontSize: "16px",
              width: "20px",
              marginLeft: " 10px",
            }}
          />
        </NavLink>
        <div
          className="modal fade modal-faq modal-faq-contact"
          id={id}
          aria-labelledby="updateLocationModalLabel"
          aria-hidden="true"
        >
          <ModalTimeLinePersonnalisation
            modalId={id}
            type={"update"}
            datas={row}
          />
        </div>
      </div>
    );
  };

  const onDeleteAbout = (id: LoginFormData) => {
    Swal.fire({
      title: "Do you want to delete this timeline ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = TimeLine.onDelete(id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        addRequest
          .then((response) => {
            dispatch(fetchtimelines());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Timeline deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  return (
    <div className="infos-perso-input">
      <>
        {isPageLoading && <AboutSkeleton />}
        {!isPageLoading && (
          <BootstrapTable
            data={timeline?.data && timeline?.data}
            // data={about}
            striped={true}
            hover={true}
            condensed={false}
            multiColumnSort={2}
            search={false}
            version="4"
            bordered={false}
            pagination
          >
            <TableHeaderColumn
              width="50"
              dataField="id"
              isKey={true}
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.8)",
                padding: 20,
              }}
              hidden
            >
              N°
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="title"
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
            >
              Title
            </TableHeaderColumn>

            <TableHeaderColumn
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
              dataFormat={(col) => (
                <span dangerouslySetInnerHTML={{ __html: col }} />
              )}
              dataField="description"
            >
              Description
            </TableHeaderColumn>
            <TableHeaderColumn
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 18,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
              dataField="id"
              dataFormat={(cell, row) => actionFormatter(cell, row)}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </>
    </div>
  );
};

export default Timelinepersonnalisation;
