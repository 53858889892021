import { FETCH_PROPERTY_REVIEW, FETCH_REVIEWS } from "../types";

export const reviewsFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_REVIEWS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

// export const reviewsProjectsFrontReducer = (
//   state = { isLoading: true, data: [] },
//   action
// ) => {
//   switch (action.type) {
//     case FETCH_REVIEWS_PROJECTS_FRONT:
//       return {
//         isLoading: false,
//         data: action.payload,
//         filteredData: action.payload,
//       };

//     default:
//       return state;
//   }
// };

export const reviewsPropertyReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_PROPERTY_REVIEW:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};