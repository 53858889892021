import { FETCH_TAFDATA_ADMIN, FETCH_TAFDATA_FRONT } from "../types";

export const tafDataReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case FETCH_TAFDATA_ADMIN:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const tafDatafrontReducer = (
  state = { isLoading: true, data: {} },
  action
) => {
  switch (action.type) {
    case FETCH_TAFDATA_FRONT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
