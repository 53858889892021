import React from "react";
import * as IconlyPack from "react-iconly";
import { Plus } from "react-iconly";
import { Link } from "react-router-dom";
import ExcelModel from "../../../modeles/import_properties_model.xlsx";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import ImportProperties from "./ImportProperties";
import "./ListingAdmin.css";
import ListingTable from "./ListingTable/ListingTable";

const Listingadmin: React.FC = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit container-profile-listng-admin">
                <h1 className="mon-profil">
                  List of properties
                  <br />
                </h1>
                <Link to="/admin/add-property" className="btn btn-add-listing">
                  <Plus set="light" primaryColor="#B0B0B0" />
                  <span className="pl-2">Add a property</span>
                </Link>
              </div>
              <div className="export-container mt-3 mb-3">
                <div className="export-btn-container justify-content-center">
                  <a
                    className="btn btn-theme-plus modele-import-btn mr-3"
                    href={ExcelModel}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    style={{ letterSpacing: "normal" }}
                  >
                    <IconlyPack.PaperUpload
                      set="light"
                      primaryColor="#FFFFFF"
                    />
                    &nbsp;
                    <span className="file-label">
                      Download import file model
                    </span>
                  </a>

                  <button
                    className="btn btn-primary export-btn"
                    data-toggle="modal"
                    data-target="#ImportPropertyModal"
                  >
                    <IconlyPack.Upload set="light" primaryColor="#9A160A" />
                    &nbsp;
                    <span className="export-btn-label">Import excel</span>
                  </button>
                </div>
              </div>
              <div className="users-table pt-2">
                <ListingTable />
              </div>
              <div
                className="modal fade modal-faq ImportPropertyModal"
                id="ImportPropertyModal"
                data-backdrop="static"
                aria-labelledby="ImportPropertyModalModalLabel"
                aria-hidden="true"
              >
                <ImportProperties />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listingadmin;
