import api from "../../Http/global-vars";
import ProjectTypeDataForm from "../../repositories/user/ProjectTypeDataForm";
import { FETCH_PROJECT_TYPES } from "../types";

export const fetchProjectTypes = () => {
  return async (
    dispatch: (arg0: { type: string; payload: ProjectTypeDataForm }) => void
  ) => {
    const url = api + "project_type/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_PROJECT_TYPES,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
