import axios from "axios";
import { showof } from "./Endpoints";

export default {
  async onAddShowOf(data, config) {
    const response = await axios.post(showof.onPostShowOf(), data, config);
    return response.data;
  },
  async onEditShowOf(id, data, config) {
    const response = await axios.put(showof.onPutShowOf(id), data, config);
    return response.data;
  },
  async onDeleteShowOf(id, config) {
    const response = await axios.delete(showof.deleteShowOf(id), config);
    return response.data;
  },
};
