import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatasAmeneties from "../../../../repositories/user/ameneties/DatasAmeneties";
import { useDispatch } from "react-redux";
import axios from "axios";
import api from "../../../../Http/global-vars";
import { ameneties } from "../../../../redux/ameneties/amenetieAction";
import Swal from "sweetalert2";

function UseRequestAmenetiesForm() {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  // const history = useHistory();
  const dispatch = useDispatch();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("The name is required"),
      }),
    []
  );

  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = useForm<DatasAmeneties>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (formValues: DatasAmeneties) => {
    console.log(formValues);
    setIsSuccessfullySubmittedForm(true);
    let request;
    if (formValues?.id) {
      request = axios.put(
        `${api}amenetie/${formValues?.id}/`,
        formValues,
        config
      );
    } else {
      request = axios.post(`${api}amenetie/`, formValues, config);
    }

    request
      .then(async (response) => {
        await dispatch(ameneties());
        await Swal.fire({
          icon: "success",
          title: "Amenity added !",
          showConfirmButton: false,
          timer: 2000,
        });
        await $(".modal-backdrop").remove();
        await $(`.close`).click();
        if (!formValues?.id) {
          reset();
        }
        await setIsSuccessfullySubmittedForm(false);
        console.log("response", response);
      })
      .catch((err) => {
        setIsSuccessfullySubmittedForm(false);
        console.log("error", err?.response);
        if (err?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: err?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: err?.response?.status,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    clearErrors,
    isSuccessfullySubmittedForm,
    reset,
    unregister,
  };
}

export default UseRequestAmenetiesForm;
