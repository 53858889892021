import React from 'react'
import NavbarContent from './NavbarContent'
import PackageJson from "../../../../package.json";
const NavbarVertical: React.FC = () => {
    const currentVersion = PackageJson.version;
    return (
        <div className="navbar-vertical-customisation-v2 no-view-mobile">
            <NavbarContent />
            <div className="version-container">
                <div className="text-center text-uppercase font-weight-bold text-danger">
                    {`v${currentVersion}`}
                </div>
            </div>
        </div>
    )
}

export default NavbarVertical;

