import React, { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
// import { NavItem } from 'reactstrap';

function CategoryArticles() {
  // var [isPageLoading, setIsPageLoading] = React.useState(true);

  const [categoryArticleData, setCategoryArticleData] = React.useState();
  const cat_article_data = useSelector(
    (state: RootStateOrAny) => state.category_article
  );
  // const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      // setIsPageLoading(false);
    }, 3000);
  }, [cat_article_data.data]);

  console.log(cat_article_data.data);
  const category = (
    <ul>
      {cat_article_data.data.map((item) => (
        <li key={item.id}> {item.name} </li>
      ))}
    </ul>
  );

  console.log(category);

  return <div>{category}</div>;
}

export default CategoryArticles;
