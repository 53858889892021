import moment from "moment";
import { useRef } from "react";
import { ArrowLeft } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import ChatImg from "../../../images/icons/chat-listing.png";
import { Gallery } from "../../user/projets/DetailsProjet";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
const ShowArticledetail = () => {
  const commentRef = useRef(null);
  const location = useLocation<any>();
  const article = location.state?.row;

  const review_datas = useSelector((state: RootStateOrAny) => {
    const reviews = state.reviews?.data ?? [];
    return reviews.filter((x: any) => x.article === article?.id);
  });

  if (!article) return <Redirect to="/admin/articles" />;

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <div className="d-flex align-items-center">
                  <Link to="/admin/articles" className="btn btn-add-listing">
                    <ArrowLeft set="light" primaryColor="#B0B0B0" />
                  </Link>
                  <h1 className="mon-profil pl-2"> {article.title}</h1>
                </div>
              </div>
              <div className="bloc-principal-body-content p-0">
                <div className="dashboard-card-container d-flex flex-column flex-md-row">
                  <div
                    onClick={() => {
                      if (commentRef.current) {
                        //@ts-ignore
                        commentRef.current.scrollIntoView();
                      }
                    }}
                    className="dashboard-card card-details-listing cursor-pointer"
                  >
                    <div className="">
                      <img src={ChatImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total comment</h1>
                      <h3 className="dasboard-card-montant">
                        {review_datas.length}{" "}
                        <small className="dasboard-card-title">
                          {review_datas.length > 1 ? "Comments" : "Comment"}{" "}
                        </small>
                      </h3>
                    </div>
                  </div>
                  <div className="dashboard-card" />
                  <div className="dashboard-card" />
                </div>

                <div className="project-container ">
                  <Gallery
                    pictures={[]}
                    image={article?.image}
                    name={article?.title}
                  />

                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Description</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-ite">
                        <p
                          className="cla-text"
                          dangerouslySetInnerHTML={{
                            __html: article?.content,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Author</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-ite">
                        <p
                          className="cla-text"
                          dangerouslySetInnerHTML={{
                            __html: article.writen_by,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {!!article.created_by && (
                    <div className="card-listing p-3 mt-3">
                      <h2 className="mid-listing-title">Create by</h2>
                      <div className="det-feat-container">
                        <div className="det-feat-ite">
                          <p
                            className="cla-text"
                            dangerouslySetInnerHTML={{
                              __html: article.created_by.fullname,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div ref={commentRef} className="card-listing mt-3 p-3">
                    <h2 className="mid-listing-title mb-3">
                      Comments ({review_datas.length})
                    </h2>
                    <div className="container-taf">
                      <div className="row">
                        {review_datas &&
                          review_datas.map((review_data: any) => {
                            return (
                              <div className="col-12">
                                <div className="bloc-div-item-service">
                                  <div>
                                    <img
                                      src={
                                        review_data?.image
                                          ? `${baseUrl + review_data?.image}`
                                          : `https://ui-avatars.com/api/?name=${review_data?.name}`
                                      }
                                      alt="service"
                                      className="img-service"
                                    />
                                  </div>
                                  <div className="pl-3">
                                    <div className="d-flex">
                                      <h3 className="titre-section-service">
                                        {review_data?.name} <br />
                                        <span style={{fontSize: 12,opacity: .6}}>{review_data?.email}</span> <br />
                                        <span className="text-date-listing w-100">
                                          {moment(
                                            review_data.created_at
                                          ).format("YYYY-MM-DD")}
                                        </span>
                                      </h3>
                                    </div>
                                    <p className="text-section-services">
                                      {review_data?.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowArticledetail;
