import React from "react";
import ReactPlayer from "react-player/lazy";
import PlayIcon from "../../../images/icons/playVideoIcon.png";

interface Props {
  url: string;
  showControls: boolean;
}
const PlayerVideo: React.FC<Props> = ({ url, showControls }) => {
  const playVideoIcon = (
    <button
      style={{
        background: "transparent",
        border: "none",
      }}
    >
      <img src={PlayIcon} alt="play-icon" />
    </button>
  );
  return (
    <div className="player-video-container px-1">
      {/* @ts-ignore */}
      <ReactPlayer
        url={url}
        className="react-player"
        playing
        width="100%"
        height="50vh"
        controls={showControls}
        light={true}
        pip={false}
        loop={true}
        playIcon={playVideoIcon}
      />
    </div>
  );
};

export default PlayerVideo;
