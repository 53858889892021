// import ModalShowMessage from "./ModalContact/ModaleShowMessage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ModalContact from "../ContactAdmin/ModalContact/ModalContact";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./ContactAdmin.css";
import { contactList } from "../../../redux/contact/contactAction";
import Skeleton from "../../../utils/Skeleton";
import { CustomPagination } from "../../../modules/Pagination/PaginationV2";

const ContactSkeleton = () => {
  return (
    <>
      {[...Array(10)]?.map((el, i) => (
        <div style={{ color: "#9B51E0" }} className="mr-2 cursor-pointer">
          <div className="row div-card">
            <div className="col-6">
              <Skeleton
                variant="rect"
                className="faq-card-title faq-card-title-couper mb-2"
                width={150}
                height={15}
              />
              <Skeleton
                variant="rect"
                className="contact-card-para"
                width={200}
                height={10}
              />
            </div>
            <div className="col-6">
              <div className="div-nouveau-name">
                <div>
                  <Skeleton variant="rect" width={100} height={15} />
                </div>
                <div className="div-icon">
                  <div>
                    <Skeleton
                      variant="rect"
                      className="contact-name-para mb-2"
                      width={150}
                      height={15}
                    />
                    <Skeleton
                      variant="rect"
                      className="contact-name-para"
                      width={200}
                      height={15}
                    />
                  </div>

                  <div>
                    <Skeleton
                      variant="rect"
                      width={25}
                      height={25}
                      className="border-radius-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

function Contactadmin(props) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { data = { results: [], count: 0 }, isLoading } = useSelector(
    (state: RootStateOrAny) => state.contact
  );
  const [dataContact, setDataContact] = React.useState();

  useEffect(() => {
    dispatch(contactList({ page }));
  }, [dispatch, page]);

  const getMessage = (data) => {
    setDataContact(data);
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />

      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <h3 className="admin-contacts-title">Contact messages</h3>
              <div className="card-contacts-main-content">
                {!!isLoading && <ContactSkeleton />}
                {!isLoading &&
                  (data?.count > 0 ? (
                    data?.results?.map((item: any) => {
                      return (
                        <div>
                          <NavLink
                            to="#"
                            style={{ color: "#9B51E0" }}
                            className="mr-2 cursor-pointer"
                            data-toggle="modal"
                            data-target="#contactModal"
                            onClick={() => getMessage(item)}
                          >
                            <div className="row div-card">
                              <div className="col-6">
                                <h4 className="faq-card-title faq-card-title-couper">
                                  {item?.subject}
                                </h4>
                                <p className="contact-card-para">
                                  Le{" "}
                                  {moment(item?.created_at).format(
                                    "DD MMM YYYY hh:mm"
                                  )}{" "}
                                </p>
                              </div>
                              <div className="col-6">
                                <div className="div-nouveau-name">
                                  <div>
                                    <button
                                      type="button"
                                      className={
                                        item?.statut === false
                                          ? `btn contact-btn-nouveau`
                                          : `btn contact-btn-treaty`
                                      }
                                      data-toggle="modal"
                                      // data-target="#contactModal"
                                      // onClick={() => getMessage(item)}
                                    >
                                      {item?.statut === false
                                        ? "NEW"
                                        : "TREATY"}
                                    </button>
                                  </div>
                                  <div className="div-icon">
                                    <div>
                                      <p className="contact-name-para">
                                        {item?.name}
                                      </p>
                                      <p className="contact-name-para">
                                        {item?.email}
                                      </p>
                                    </div>

                                    <div>
                                      <IconlyPack.Show />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      );
                    })
                  ) : (
                    <div className="card-contacts-main-content">
                      <div className="row div-card">
                        <div className="col-12">
                          <h4 className="admin-contacts-title text-center">
                            No contacts available
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                <CustomPagination
                  page={page}
                  perPage={10}
                  nbPages={data?.count}
                  onChange={(page, perPage) => {
                    window.scrollTo(100, 100);
                    setPage(page);
                  }}
                  showPerPage={false}
                />
                <ModalContact datas_contact={dataContact} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactadmin;
