import api from "../../Http/global-vars";
import { QueryUrl } from "../../utils/utils";
import { FilterType } from "../Article/articleAction";
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_PROJECT,
  FETCH_RESERVATIONS_PROPERTY,
} from "../types";

export const listReservations = ({
  page = 1,
  limit = 10,
  fullname = "",
  phone = "",
  date_souhaite = "",
  status = "",
  property_id = "",
  email = "",
}: FilterType) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = QueryUrl(api + `interet/`, {
      page,
      limit,
      fullname,
      phone,
      date_souhaite,
      status,
      property_id,
      email,
    });
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results reservation", data.results);
          dispatch({
            type: FETCH_RESERVATIONS,
            payload: data,
          });
        })
        .catch((error) => {
          console.log("error", error?.response);
          dispatch({
            type: FETCH_RESERVATIONS,
            payload: {
              results: [],
              count: 0,
            },
          });
        });
    }
  };
};

export const listReservationsByProperty = ({
  page = 1,
  limit = 10,
  id,
  fullname = "",
  phone = "",
  date_souhaite = "",
  status = "",
  property_id = "",
  email = "",
}: FilterType) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = QueryUrl(api + `interetbyproperty/${id}/`, {
      fullname,
      phone,
      date_souhaite,
      status,
      property_id,
      email,
      page,
      limit,
    });
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results reservation", data.results);
          dispatch({
            type: FETCH_RESERVATIONS_PROPERTY,
            payload: data,
          });
        })
        .catch((error) => {
          console.log("error", error?.response);
          dispatch({
            type: FETCH_RESERVATIONS_PROPERTY,
            payload: {
              results: [],
              count: 0,
            },
          });
        });
    }
  };
};

export const listReservationsByProject = ({
  page = 1,
  limit = 10,
  id,
  fullname = "",
  phone = "",
  date_souhaite = "",
  status = "",
  property_id = "",
  email = "",
}: FilterType) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = QueryUrl(api + `interetbyproject/${id}/`, {
      fullname,
      phone,
      date_souhaite,
      status,
      property_id,
      email,
      page,
      limit,
    });
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results reservation", data.results);
          dispatch({
            type: FETCH_RESERVATIONS_PROJECT,
            payload: data,
          });
        })
        .catch((error) => {
          console.log("error", error?.response);
          dispatch({
            type: FETCH_RESERVATIONS_PROJECT,
            payload: {
              results: [],
              count: 0,
            },
          });
        });
    }
  };
};
