import api from "../../Http/global-vars";
import DatasListings from "../../repositories/user/listings/DatasListings";
import { FETCH_PROJECT_TYPE, UPDATE_PROJECT_TYPE } from "../types";

export const projectTypeAction = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "project_type/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_PROJECT_TYPE,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const updateProjectTypeAction = (id: DatasListings) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "project_type/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results idididid", data)
          dispatch({
            type: UPDATE_PROJECT_TYPE,
            payload: data,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

//   export const listProjectsDataToTest =async() => {

//     try {
//       return await Axios.get(`${api}project_type/`);
//     } catch (e) {
//       return [];
//     }
//   };

//   export const listProjectsDataFrontToTest =async() => {

//     try {
//       return await Axios.get(`${api}projectbyvisitor`);
//     } catch (e) {
//       return [];
//     }
//   };
