import React from 'react'
import Header from '../../header/Header'
import { Location } from 'react-iconly'
// import { NavLink } from 'react-router-dom'
import Projet1 from '../../../images/projet1.png'
// import Projet2 from '../../../images/projet2.png'
// import Projet3 from '../../../images/projet3.png'
// import Projet4 from '../../../images/projet4.png'
// import Projet5 from '../../../images/projet5.png'
// import Projet6 from '../../../images/projet6.png'
// import Projet7 from '../../../images/projet7.png'
// import Projet8 from '../../../images/projet8.png'
import FrontFooter from '../../frontFooter/FrontFooter'
import ImgIcon from '../../../images/icons/category.png'
// import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
// import ProjetDatas from '../../../repositories/user/projets/DatasProjets'
// import baseUrl from '../../../Http/backend-base-url'
// import { detailProjectFront } from '../../../redux/projectsFront/projectsFrontAction'
// // $(function() {

const Showresultat: React.FC = () => {


    return (
        <div className="component-front-home">
            <Header />
            <div className="p-t-150 m-b-100  container-page-about-home">
                <div className="text-center border-bottom p-b-50 p-t-50">
                    <h1 className="titre-page-content">Show Results</h1>
                    <div className="col-md-8 offset-md-2 p-t-20">
                        <p className="page-content-text">
                            Here are the successfully found results
                        </p>
                    </div>
                </div>
                <div>
                    <div className="container-taf p-t-80">
                        <div className="row">
                            <div className="no-link">
                                <div className="card card-blog position-relative">
                                    <img src={Projet1} height="300" className="card-img-top" alt="..." />

                                    <div className="card-body pt-0">
                                        <div className="div-statut-projet text-uppercase">
                                            <span>ddddd</span>
                                        </div>
                                        <p className="card-text card-text-lastest-news pb-3">
                                            ssss
                                        </p>
                                        <div className="py-2">
                                            <p className="text-calendar-card d-flex align-items-center">
                                                <Location set="light" primaryColor="#828282" />
                                                <span className="pl-2">Continent</span>
                                            </p>
                                        </div>
                                        <div className="py-2">
                                            <p className="text-calendar-card d-flex align-items-center">
                                                <img src={ImgIcon} alt="Icon category" className="icon-img-category" />
                                                <span className="pl-2">Office and rentail</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="no-link ml-5">
                                <div className="card card-blog position-relative">
                                    <img src={Projet1} height="300" className="card-img-top" alt="..." />

                                    <div className="card-body pt-0">
                                        <div className="div-statut-projet text-uppercase">
                                            <span>ddddd</span>
                                        </div>
                                        <p className="card-text card-text-lastest-news pb-3">
                                            ssss
                                        </p>
                                        <div className="py-2">
                                            <p className="text-calendar-card d-flex align-items-center">
                                                <Location set="light" primaryColor="#828282" />
                                                <span className="pl-2">Continent</span>
                                            </p>
                                        </div>
                                        <div className="py-2">
                                            <p className="text-calendar-card d-flex align-items-center">
                                                <img src={ImgIcon} alt="Icon category" className="icon-img-category" />
                                                <span className="pl-2">Office and rentail</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

            <FrontFooter />
        </div>
    )
}

export default Showresultat;
