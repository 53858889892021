import React from "react";
import { Location } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import { PROJECT_STATUS, displayPriceType } from "../../../Http/global-vars";
// import FeaturedImg from "../../../images/featuredimg.png";
import Bathroom3 from "../../../images/icons/bathtub2.png";
import Bed3 from "../../../images/icons/bed2.png";
import Kitchen3 from "../../../images/icons/kitchen2.png";
import { getPrice } from "../front-home/CardAppartItem";
import { useCurrency } from "../../../redux/store";
import { capitalizeText } from "../../../utils/utils";

type Props = {
  property?: boolean;
  featureProperty: any;
};

const FeaturedProperty: React.FC<Props> = ({ featureProperty, property }) => {
  const currency = useCurrency();
  const property_types_front = useSelector(
    (state: RootStateOrAny) => state.property_types_front
  );

  if (property) {
    const res =
      property_types_front.data.length &&
      property_types_front?.data.find(
        (x: any) => x.id === featureProperty?.property_type
      );
    return (
      <Link
        to={{
          pathname: `/listings/${featureProperty?.slug}`,
          state: featureProperty,
        }}
      >
        <div className="card featured-property-item flex-sm-row  mb-3">
          <img
            // src={FeaturedImg}
            src={
              featureProperty.image === "" ||
              featureProperty.image === undefined ||
              featureProperty.image === null
                ? `https://ui-avatars.com/api/?name=${featureProperty?.property_name}`
                : `${baseUrl + featureProperty?.image}`
            }
            alt="featured-property-img"
            className="card-img-top img-featured-property-card"
          />
          <div className="card-body featured-details px-3 py-2">
            <h3 className="featured-title">
              {capitalizeText(featureProperty.property_name && featureProperty.property_name)}
              {capitalizeText(res?.name ? ", " + res?.name : "")}
            </h3>
            <div className="featured-statut-container">
              <h6 className="featured-statut">
                For {capitalizeText(featureProperty.statut_listing)}
              </h6>
            </div>

            <h3 className="featured-price">
              {getPrice(featureProperty,currency)}
              {displayPriceType(featureProperty?.type_price)}
            </h3>
            <div className="featured-elements-container pb-2 mb-2 itm-center">
              <div className="featured-detail-item">
                <img src={Bed3} alt="bed-icon" className="detail-item-img" />
                <span className="featured-text fs-14">
                  {`${featureProperty.bedroom && featureProperty.bedroom} beds`}
                </span>
              </div>
              <div className="featured-detail-item">
                <img
                  src={Kitchen3}
                  alt="kitchen-icon"
                  className="detail-item-img"
                />
                <span className="featured-text fs-14">
                  {`${featureProperty.kitchen}`} kitchens
                </span>
              </div>
              <div className="featured-detail-item">
                <img
                  src={Bathroom3}
                  alt="bathroom-icon"
                  className="detail-item-img"
                />
                <span className="featured-text fs-14">
                  {`${
                    featureProperty.bathroom && featureProperty.bathroom
                  } bathroom`}
                </span>
              </div>
            </div>
            <div className="quice-container d-flex mt-2 mt-md-0">
              <Location
                primaryColor="#A1ABC9"
                style={{
                  height: "15",
                  width: "14",
                }}
              />
              <h6 className="quice-market-text fs-16">
                {capitalizeText(featureProperty.location_id?.name)},{" "}
                {capitalizeText(featureProperty.country_id?.title)}
              </h6>
            </div>
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link
      to={{
        pathname: `/project-details/${featureProperty?.slug}`,
        state: featureProperty,
      }}
    >
      <div className="card featured-property-item  mb-3">
        <img
          // src={FeaturedImg}
          src={
            featureProperty.image === "" ||
            featureProperty.image === undefined ||
            featureProperty.image === null
              ? `https://ui-avatars.com/api/?name=${featureProperty?.property_name}`
              : `${baseUrl + featureProperty?.image}`
          }
          alt="featured-property-img"
          className="card-img-top img-featured-property-card"
        />
        <div className="card-body featured-details px-3 py-2">
          <h3 className="featured-title">{capitalizeText(featureProperty.property_name)}</h3>
          <div className="featured-statut-container">
            <h6 className="featured-statut">
              {capitalizeText(PROJECT_STATUS[featureProperty?.statut_project] || "New")}
            </h6>
          </div>
          {/* <h4 className="featured-price">
            
          </h4>
          <h6 className="featured-price">to</h6>
          <h4 className="featured-price">
            {formatAmout(featureProperty.max_price,currency)}
          </h4> */}
          <div className="quice-container d-flex mt-2 mt-md-0">
            <Location
              primaryColor="#A1ABC9"
              style={{
                height: "15",
                width: "14",
              }}
            />
            <h6 className="quice-market-text fs-16">
              {capitalizeText(featureProperty.location_id?.name)},{" "}
              {capitalizeText(featureProperty.country_id?.title)}
            </h6>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedProperty;
