import { FETCH_NEWSLETTER_ADMIN } from "../types";




export const newsletterAdminReducer = (
    state = { isLoading: true, data: {results:[],count: 0} },
    action
  ) => {
    switch (action.type) {
      case FETCH_NEWSLETTER_ADMIN:
        return {
          isLoading: false,
          data: action.payload,
          filteredData: action.payload,
        };
  
      default:
        return state;
    }
};