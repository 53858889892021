import agencie from '../../../../images/agenci.png'



const AgencieData = [
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
    {
        image: agencie,
        name: 'SMART HOUSE',
        address: 'Senegal',
        ceo: 'Adama Kare',
        tax: '5325',
        number: '150',
        total: '120'
    },
];

export default AgencieData;
