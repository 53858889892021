import axios from "axios";
import { about } from "./Endpoints";

export default {
  async onAddAbout(data, config) {
    const response = await axios.post(about.onPostAbout(), data, config);
    return response.data;
  },
  async onEditAbout(id, data, config) {
    const response = await axios.put(about.onPutAbout(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteAbout(id, config) {
    const response = await axios.delete(about.deleteAbout(id), config);
    // console.log(response)
    return response.data;
  },
};
