import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ReactExport from "react-export-excel";
import * as IconlyPack from "react-iconly";
import { Delete } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../Http/global-vars";
import { fetchNewsletterAdmin } from "../../../../redux/NewsletterAdmin/NewsletterAdminAction";
import "../Newsletter.css";
import NewsletterSkeleton from "../NewsletterSkeleton";
import { CustomPagination } from "../../../../modules/Pagination/PaginationV2";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function NewsletteTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();

  const {data = {results:[],count:0}, isLoading} = useSelector(
    (state: RootStateOrAny) => state.newsletterAdmin
  );

  React.useEffect(() => {
    dispatch(fetchNewsletterAdmin({page,limit}));
  }, [page,limit]);

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className="mr-2"
          onClick={() => onDelete(row.id)}
          title="Delete this email"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const onDelete = (id) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to delete this email?",
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#bfc0c9",
      confirmButtonText: "YES",
      cancelButtonText: "NON",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Axios.delete(`${api}newsletter/${id}/`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchNewsletterAdmin({page,limit}));
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Email deleted successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          // allowOutsideClick: false,
          timer: 3000,
        });
      }
    });
  };

  const renderPaginationPanel = (props) => {
    return (
      <div className="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {!!isLoading && <NewsletterSkeleton />}
            {!isLoading && (
              <div className="table-container-activite">
                <BootstrapTable
                  data={data?.results}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                    options={options}
                  search={false}
                  version="4"
                  pagination
                  bordered={false}
                  striped={true}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                    }}
                    width="50%"
                    dataField="email"
                    isKey={true}
                  >
                    Emails
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                    }}
                    width="40%"
                    dataField="created_at"
                    dataFormat={(cell, row) =>
                      moment(cell).format("YYYY-MM-DD")
                    }
                  >
                    Subscription Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    dataField="id"
                    width="10%"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsletteTable;

// ======================================= EXCEL SHEET ===================================

export const DataToExcel = () => {
  const newsletters = useSelector(
    (state: RootStateOrAny) => state.newsletterAdmin
  );

  return (
    <ExcelFile
      element={
        <button className="btn btn-primary export-btn-newsletter btn-theme-export">
          <IconlyPack.Upload set="light" primaryColor="#9A160A" />
          &nbsp;
          <span className="export-btn-label">Export excel</span>
        </button>
      }
      filename="Newsletter Emails"
    >
      <ExcelSheet
        data={newsletters?.data?.results?.map((c) => ({
          email: c.email,
          created_at: moment(c.created_at).format("YYYY-MM-DD"),
        }))}
        name="newsletters"
      >
        <ExcelColumn label="Emails" value="email" />
        <ExcelColumn label="Subscription Date" value="created_at" />
      </ExcelSheet>
    </ExcelFile>
  );
};
