// import * as IconlyPack from "react-iconly";
import { Delete } from "@material-ui/icons/";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Show } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../Http/global-vars";
import { listReservations, listReservationsByProject, listReservationsByProperty } from "../../../../redux/Reservations/reservationAction";
import DetailsReservationModal from "./DetailsReservationModal";
import ListingSkeleton from "./ReservationSkeleton";
import "./ReservationTable.css";
import { CustomPagination } from "../../../../modules/Pagination/PaginationV2";
import moment from "moment";

const StatusOptions = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Treaty",
    value: "treaty",
  },
];
type Props = {
  onChange: any;
  name: string;
  setValue: any;
  type: string;
  options?: any[];
};
const CustomFilterText = (props: Props) => {
  const { onChange, name, setValue, type, options = [] } = props;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    setValue(value);
    // filterHandler(value)
  };
  if (type === "select") {
    return (
      <select
        name={name}
        id={name}
        className="filter select-filter form-control placeholder-selected"
        onChange={dataSelected.bind(this, onChange)}
      >
        <option value="">Select</option>
        {options?.map((opt) => (
          <option value={opt?.value} key={opt?.value}>
            {" "}
            {opt?.label}{" "}
          </option>
        ))}
      </select>
    );
  }
  return (
    <input
      // ref={ref}
      type={type}
      name={name}
      min={0}
      onChange={dataSelected.bind(this, onChange)}
      // value={value}
      placeholder={name}
      className="filter text-filter form-control"
    />
  );
};
const ReservationsTable = ({
  property,
  project,
  page,
  limit,
  setFullname,
  setPhone,
  setEmail,
  setDate_souhaite,
  setStatus,
  setProperty_id,
  setPage,
  setLimit,
  data,
  isLoading,
}: {
  property?: any;
  project?: any;
  page: number;
  limit: number;
  setFullname: any;
  setPhone: any;
  setEmail: any;
  setDate_souhaite: any;
  setStatus: any;
  setProperty_id: any;
  setPage: any;
  setLimit: any;
  data: any;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();

  const [reservationData, setReservationData] = useState();

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getReservationData = (data) => {
    setReservationData(data);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <button
          data-toggle="modal"
          data-target="#DetailsReservationModal"
          style={{ color: "#2D3748" }}
          className="mr-3 btn"
          type="button"
          onClick={() => {
            if (!row.read) {
              markAsRead(row.id);
            }
            getReservationData(row);
          }}
          title="Show this reservation"
        >
          <Show set="bold" primaryColor="#CECECE" />
        </button>
        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className=""
          onClick={() => onDelete(row.id)}
          title="Delete this reservation"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this reservation ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = Axios.delete(`${api}interet/${id}`, config);
        addRequest
          .then((response) => {
            dispatch(listReservations({ page, limit }));
            if (property?.id) {
              dispatch(listReservationsByProperty({ page, limit,id: property?.id }));
            }

            if (project?.id) {
              dispatch(listReservationsByProject({ page, limit,id: project?.id }));
            }
            
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Reservation deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const getPropertyName = (cell, row) => {
    if (cell?.id) {
      return (
        <div className="nameFormatter">
          <span className="name">{cell?.property_name}</span>
          <br />
          <span className="entreprise">Property</span>
        </div>
      );
    } else if (row?.project_id?.id) {
      return (
        <div className="nameFormatter">
          <span className="name">{row?.project_id?.property_name}</span>
          <br />
          <span className="entreprise">Project</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const filterName = (cell, row) => {
    return cell?.property_name || row?.project_id?.property_name;
  };

  const statutFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          title="Change this status reservation"
          onClick={() => {
            if (!row.read) {
              markAsRead(row.id);
            }
            updateStatut(row?.id);
          }}
          className={
            cell === "new"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-rouge text-uppercase cursor-pointer`
          }
        >
          {cell === "new" ? "New" : cell === "treaty" ? "Treaty" : ""}
        </button>
      </span>
    );
  };

  const markAsRead = async (id: number) => {
    const fd = new FormData();
    fd.append("read", "true");
    const request = Axios.put(`${api}interet/` + id + "/", fd, config);
    request
      .then((res) => {
        dispatch(listReservations({ page, limit }));
        if (property?.id) {
          dispatch(listReservationsByProperty({ page, limit,id: property?.id }));
        }

        if (project?.id) {
          dispatch(listReservationsByProject({ page, limit,id: project?.id }));
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  const updateStatut = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: {
        treaty: "Treaty",
        new: "New",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("status", value);

            const request = Axios.put(`${api}interet/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(listReservations({ page, limit }));
                if (property?.id) {
                  dispatch(listReservationsByProperty({ page, limit,id: property?.id }));
                }
    
                if (project?.id) {
                  dispatch(listReservationsByProject({ page, limit,id: project?.id }));
                }
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Status changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };

  const renderPaginationPanel = (props) => {
    return (
      <div className="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const onChangeData = (key, value: any) => {
    console.log(key);
    if (key === "name") {
      setFullname(value);
    } else if (key === "email") {
      setEmail(value);
    } else if (key === "phone") {
      setPhone(value);
    } else if (key === "status") {
      setStatus(value);
    } else if (key === "property_or_project") {
      setProperty_id(value);
    } else if (key === "date") {
      setDate_souhaite(value);
    }
    setPage(1);
  };

  const getCustomTextFilter = (filterHandler, nom, options: any = []) => {
    return (
      <CustomFilterText
        onChange={filterHandler}
        name={nom}
        setValue={(value) => onChangeData(nom, value)}
        type={nom === "date" ? "date" : nom !== "status" ? "text" : "select"}
        options={options}
      />
    );
  };

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {
              <>
                {isLoading && <ListingSkeleton />}
                {!isLoading && (
                  <div className="table-container-activite">
                    <BootstrapTable
                      data={data?.results}
                      hover={true}
                      condensed={false}
                      multiColumnSort={2}
                      options={options}
                      search={false}
                      version="4"
                      bordered={false}
                      striped={true}
                      pagination
                    >
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                        }}
                        width="150"
                        dataField="id"
                        isKey={true}
                        hidden
                      ></TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                        }}
                        width="150"
                        dataField="first_name"
                        dataFormat={(cel, row) => {
                          return (
                            <div style={{ position: "relative" }}>
                              {`${row.first_name} ${row.last_name}`}
                              {!row.read && (
                                <span className="notification-counter2">
                                  {1}
                                </span>
                              )}
                            </div>
                          );
                        }}
                        // filterValue={(cel, row) =>
                        //   `${row.first_name} ${row.last_name}`
                        // }
                        // filter={{ type: "TextFilter" }}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "name"),
                        }}
                      >
                        Full Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="200"
                        dataField="email"
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "email"),
                        }}
                      >
                        Email
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="130"
                        dataField="phone"
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "phone"),
                        }}
                      >
                        Phone
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="150"
                        dataField="date_souhaite"
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "date"),
                        }}
                      >
                        Desired date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="130"
                        dataField="property_id"
                        dataFormat={(cell, row) => getPropertyName(cell, row)}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(
                              filterHandler,
                              "property_or_project"
                            ),
                        }}
                      >
                        Property/Project Name
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="status"
                        dataFormat={(cell, row) => statutFormatter(cell, row)}
                        // filter={{
                        //   type: "SelectFilter",
                        //   options: status_publication,
                        //   condition: "eq",
                        // }}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(
                              filterHandler,
                              "status",
                              StatusOptions
                            ),
                        }}
                      >
                        Statut Reservations
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          verticalAlign: "middle",
                        }}
                        dataField="id"
                        width="100"
                        dataFormat={(cell, row) => actionFormatter(cell, row)}
                      >
                        Actions
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </>
            }
          </div>
        </div>
        <div
          className="modal fade"
          id="DetailsReservationModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <DetailsReservationModal detailReservation={reservationData} />
        </div>
      </div>
    </div>
  );
};

export default ReservationsTable;
