import api from "../../Http/global-vars";
import { QueryUrl } from "../../utils/utils";
// import { convertCurrency } from "../../utils/convert_currency";
import { FilterType } from "../Article/articleAction";
// import { useCurrency } from "../store";
import {
  DETAIL_PROPERTY,
  FETCH_PROPERTIES_CONVERTED,
  FETCH_PROPERTIES_TYPE_TO_FRONT,
} from "../types";
// const currencyDefault = useCurrency();

export const listPropertiesToFront = ({
  page = 1,
  limit = 10,
  q = "",
  where = "",
  property_type = "",
  bedroom = "",
  amount = "",
  price_min = "",
  price_max = "",
  amenitie = "",
  currency_from = "GMD",
  currency_to = "XOF",
}: FilterType) => {
  return async (
    dispatch: (arg0: { type: string; payload: any; isLoading: boolean }) => void
  ) => {
    dispatch({
      type: FETCH_PROPERTIES_CONVERTED,
      payload: {
        results: [],
        count: 0,
      },
      isLoading: true,
    });
    const url = QueryUrl(api + `listingbyvisitor/`, {
      page,
      limit,
      q,
      where,
      property_type,
      bedroom,
      amount,
      price_min,
      price_max,
      amenitie,
      currency_from,
      currency_to,
    });
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        
        dispatch({
          type: FETCH_PROPERTIES_TYPE_TO_FRONT,
          payload: data,
          isLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROPERTIES_TYPE_TO_FRONT,
          payload: { results: [], count: 0 },
          isLoading: false,
        });
      });
  };
};

export const listPropertiesToFrontGET = (data: any[], count: number) => {
  return async (dispatch: any) => {
    // console.log("tes", count, data);
    dispatch({
      type: FETCH_PROPERTIES_CONVERTED,
      payload: {
        results: data,
        count,
      },
    });
  };
};

export const ViewItem = {
  async property(id: number) {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + `increasevulisting/${id}/`;
    fetch(url, config)
      .then(async (res) => {
        console.log({ ViewedProperty: id });
      })
      .catch((error) => console.log({ ViewedErrorProperty: id }));
  },
  async project(id: number) {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + `increasevuproject/${id}/`;
    fetch(url, config)
      .then(async (res) => {
        console.log({ ViewedProject: id });
      })
      .catch((error) => console.log({ ViewedErrorProject: id }));
  },
};

export const detailProjectFront = (id: number, currency_to) => {
  return async (
    dispatch: (arg0: { type: string; payload: any; isLoading: boolean }) => void
  ) => {
    const url =
      api +
      "listingbyvisitorbyslug/" +
      id +
      `/?currency_from=GMD&currency_to=${currency_to}`;

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data listingbyvisitorbyslug", data);
        dispatch({
          type: DETAIL_PROPERTY,
          payload: data,
          isLoading: false,
        });
      })
      .catch((error) => console.log("error", error));
  };
};
