import { DETAIL_PROJECT, FETCH_PROJECTS_FRONT } from "../types";

export const projectsFrontReducer = (
  state = { isLoading: true, data: {results: [], count:0} },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECTS_FRONT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const detailProjectFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case DETAIL_PROJECT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
