import React from 'react';
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./AgencieSmartHouse.css";
import smart from '../../../images/smarts.png'
import { Location, Show } from 'react-iconly'
import {NavLink} from 'react-router-dom'
import ImgLit from '../../../images/icons/lit.png'
import ImgBain from '../../../images/icons/bain.png'
import ImgEat from '../../../images/icons/eat.png'
import cuisine from '../../../images/cuisine.png'
import salon from '../../../images/salon.png'
import imeuble from '../../../images/imeuble.png'

const Smarthouse: React.FC = () => {
    return(
        <div className="container-fluid body-background-dashboard">
            <HeaderAdmin />
        <div className="row">
            <div className="nav-container no-view-mobile">
                <NavbarVertical />
            </div>
            <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
                <div className="projets-container bloc-principal-body-content">
                    <div className="profile-containers">
                        <div className="container-profile-edit">
                            <h1 className="agencie-smart-house">AGENCIE SMART HOUSE</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-md-12">
                            <p className="smart-house">SMART HOUSE <span className='text-senegal'>Senegal, Dakar 17700</span></p>
                            <div className="container-text-agencie">
                            <p className="text-agencie">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Ut sagittis ut vitae amet quam nisl, viverra amet. 
                            Consectetur amet, odio cursus porta. Diam eu, 
                            urna et ut et ac. Eu, tincidunt nascetur vestibulum nec, 
                            nisi mi duis. Scelerisque accumsan, tristique nullam posuere r
                            utrum amet pharetra ut congue. Dignissim diam massa 
                            eget sed et aliquet condimentum mattis. Sagittis a amet 
                            malesuada a molestie nec quisque. Varius nunc arcu, 
                            et morbi in. Tempus etiam gravida at porttitor senectus. 
                            Molestie ullamcorper aliquam purus cursus pharetra, interdum. 
                            Lectus eget nulla risus elit. Aliquam quam orci, nisl non, maecenas.

                            </p>
                            </div>
                            </div>
                        </div>
                        <div className='container-detail-image-text'>
                        <div className='row'>
                             <div className='col-md-4'>
                                 <img src={smart} className='agencie-image-smart' alt='Smart House' />
                             </div>
                             <div className='col-md-8'>
                             <div className='container-detail-smart'>
                                <div className='d-flex justify-content-between'>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            CEO :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            Adama Kane
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Email :
                                        </h3>
                                        <p className='les-sous-titres'>
                                        smart@gmail.com
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Website :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            smart.com
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            FAX :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            33 800 00 00
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            OFFICE : 
                                        </h3>
                                        <p className='les-sous-titres'>
                                            33 800 000 00
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Mail Box :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            15 000
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Address :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            Senegal
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Tax Numbers :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            5325
                                        </p>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Numbers of Agents :
                                        </h3>
                                        <p className='les-sous-titres'>
                                            150
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Facebook :
                                        </h3>
                                        <NavLink to="https://facebook.com/">
                                        <p className='les-sous-titres-color'>
                                            https://facebook.com/
                                        </p>
                                        </NavLink>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            Twitter :
                                        </h3>
                                        <NavLink to="https://twitter.com/">
                                        <p className='les-sous-titres-color'>
                                          https://twitter.com/
                                        </p>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            linkdine :
                                        </h3>
                                        <NavLink to="https://linkedin.com/">
                                        <p className='les-sous-titres-color'>
                                         https://linkedin.com/
                                        </p>
                                        </NavLink>
                                    </div>
                                    <div className='detail-smart'>
                                        <h3 className='les-titres-smart'>
                                            google :
                                        </h3>
                                        <NavLink to="https://googleplus.com/">
                                        <p className='les-sous-titres-color'>
                                        https://googleplus.com/
                                        </p>
                                        </NavLink>
                                    </div>
                                </div>
                             </div>
                             </div>
                        </div>
                        </div>
                        <div className='container-rental'>
                            <div className='d-flex rental-sale'>
                            <NavLink to='#'><p className='text-rental'>Rental</p></NavLink>
                            <NavLink to='#'><p className='text-sale'>Sale</p></NavLink>
                            </div>
                            <NavLink to="#" className="no-link">
                            <div className="card mt-4 ml-4 card-item-listing card-item-listing-mobile">
                                <img src={cuisine} className="card-img-top img-listing-card-item" alt="Listing"/>
                                <div className="card-body position-relative">
                                 <h5 className="card-title title-card-item-listing">Bluebell Real Estate, apartment</h5>
                                    <p className="card-text span-for-sale-item">For sale</p>
                                      <p className="text-prix-card-item-listing">$1660</p>
                                    <div className="d-flex py-4 display-block-image-responsive">
                                    <div className="d-flex"><img src={ImgLit} alt="Icon" /> <p className="para-text-div-border pl-2">5 beds</p></div>
                                         <div className="mx-3 d-flex mx-none-responsive"><img src={ImgEat} alt="Icon" /> <p className="para-text-div-border pl-2">1 kitchens</p></div>
                                           <div className="d-flex"><img src={ImgBain} alt="Icon" /> <p className="para-text-div-border pl-2">2 bathroom</p></div>
                                            </div>
                                            <div className="m-0">
                                             <div className="bottom-card-item-agencie border-top">
                                                    <div className="d-flex justify-content-between">
                                                        <Location set="light" primaryColor="#A1ABC9"/>
                                                        <p className="text-market-card-item-listing">Quice market Nigeria</p>
                                                    </div>
                                                    <div>
                                                <Show set="bold" primaryColor="#666666"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </NavLink>
                            <NavLink to="#" className="no-link">
                                <div className="card mt-4 ml-4 card-item-listing card-item-listing-mobile">
                                    <img src={imeuble} className="card-img-top img-listing-card-item" alt="Listing"/>
                                    <div className="card-body position-relative">
                                    <h5 className="card-title title-card-item-listing">Bluebell Real Estate, apartment</h5>
                                        <p className="card-text span-for-sale-item">For sale</p>
                                        <p className="text-prix-card-item-listing">$1660</p>
                                        <div className="d-flex py-4 display-block-image-responsive">
                                        <div className="d-flex"><img src={ImgLit} alt="Icon" /> <p className="para-text-div-border pl-2">5 beds</p></div>
                                            <div className="mx-3 d-flex mx-none-responsive"><img src={ImgEat} alt="Icon" /> <p className="para-text-div-border pl-2">1 kitchens</p></div>
                                            <div className="d-flex"><img src={ImgBain} alt="Icon" /> <p className="para-text-div-border pl-2">2 bathroom</p></div>
                                                </div>
                                                <div className="m-0">
                                                <div className="bottom-card-item-agencie border-top">
                                                        <div className="d-flex justify-content-between">
                                                            <Location set="light" primaryColor="#A1ABC9"/>
                                                            <p className="text-market-card-item-listing">Quice market Nigeria</p>
                                                        </div>
                                                        <div>
                                                    <Show set="bold" primaryColor="#666666"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink to="#" className="no-link">
                                <div className="card mt-4 ml-4 card-item-listing card-item-listing-mobile">
                                    <img src={salon} className="card-img-top img-listing-card-item" alt="Listing"/>
                                    <div className="card-body position-relative">
                                    <h5 className="card-title title-card-item-listing">Bluebell Real Estate, apartment</h5>
                                        <p className="card-text span-for-sale-item">For sale</p>
                                        <p className="text-prix-card-item-listing">$1660</p>
                                        <div className="d-flex py-4 display-block-image-responsive">
                                        <div className="d-flex"><img src={ImgLit} alt="Icon" /> <p className="para-text-div-border pl-2">5 beds</p></div>
                                            <div className="mx-3 d-flex mx-none-responsive"><img src={ImgEat} alt="Icon" /> <p className="para-text-div-border pl-2">1 kitchens</p></div>
                                            <div className="d-flex"><img src={ImgBain} alt="Icon" /> <p className="para-text-div-border pl-2">2 bathroom</p></div>
                                                </div>
                                                <div className="m-0">
                                                <div className="bottom-card-item-agencie border-top">
                                                        <div className="d-flex justify-content-between">
                                                            <Location set="light" primaryColor="#A1ABC9"/>
                                                            <p className="text-market-card-item-listing">Quice market Nigeria</p>
                                                        </div>
                                                        <div>
                                                    <Show set="bold" primaryColor="#666666"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Smarthouse;