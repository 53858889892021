import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import baseUrl from "../../../../Http/backend-base-url";
import api from "../../../../Http/global-vars";
import { articleList } from "../../../../redux/Article/articleAction";
import ArticleSkeleton from "./ArticleSkeleton";
import { CustomPagination } from "../../../../modules/Pagination/PaginationV2";

type Props = {
  onChange: any;
  name: string;
  setValue: any;
  type: string;
};
const CustomFilterText = (props: Props) => {
  const { onChange, name, setValue, type } = props;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    setValue(value);
    // filterHandler(value)
  };
  return (
    <input
      // ref={ref}
      type={type}
      name={name}
      min={0}
      onChange={dataSelected.bind(this, onChange)}
      // value={value}
      placeholder={name}
      className="filter text-filter form-control"
    />
  );
};

const ArticleTable = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [author, setAuthor] = useState("");
  const [articleData, setArticleData] = React.useState();
  const { data = { results: [], count: 0 }, isLoading } = useSelector(
    (state: RootStateOrAny) => state.article
  );
  const dispatch = useDispatch();

  const getArticle = (data: any) => {
    setArticleData(data);
  };

  useEffect(() => {
    dispatch(articleList({ page, limit, q: title, author, date }));
  }, [page, limit, title, author, date]);

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <NavLink
          to={{
            pathname: `/admin/show-article-detail/${row.id}`,
            state: { row },
          }}
          style={{ color: "#9B51E0" }}
          className="mr-2"
          onClick={() => {
            getArticle(row);
          }}
          title="Show this article"
        >
          <IconlyPack.Show />
        </NavLink>
        <NavLink
          to={{
            pathname: `/admin/article-detail-update/${row.id}`,
            state: { row },
          }}
          style={{ color: "#2D3748" }}
          className="mr-2"
          onClick={() => {
            getArticle(row);
          }}
          title="Edit this article"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>

        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className="mr-2"
          onClick={() => onDelete(row.id)}
          title="Delete this article"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const getDateRegister = (cell, row) => {
    return moment(row?.date).format("YYYY-MM-DD");
  };
  const onDelete = (id) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to delete this news?",
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#bfc0c9",
      confirmButtonText: "YES",
      cancelButtonText: "NON",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Axios.delete(`${api}article/${id}/`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(articleList({}));
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "News deleted successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          // allowOutsideClick: false,
          timer: 3000,
        });
      }
    });
  };
  const photoFormatter = (cell, row) => {
    return (
      <img
        src={
          row?.image === null
            ? `https://ui-avatars.com/api/?name=${row?.title}`
            : `${baseUrl + row?.image}`
        }
        alt="avatar"
        height="50"
        width="50"
      />
    );
  };

  const renderPaginationPanel = (props) => {
    return (
      <div className="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const onChangeData = (key, value:any) => {
    if (key === "title") {
      console.log(key, value);
      setTitle(value);
    } else if (key === "date") {
      if (value) {
        setDate(moment(value).format("DD-MM-YYYY"));
      } else {
        setDate("");
      }
      
    } else if (key === "author") {
      setAuthor(value);
    }
    setPage(1);
  };

  const getCustomTextFilter = (filterHandler, nom) => {
    return (
      <CustomFilterText
        onChange={filterHandler}
        name={nom}
        setValue={(value) =>
          nom === "title"
            ? onChangeData("title", value)
            : nom === "author"
            ? onChangeData("author", value)
            : onChangeData("date", value)
        }
        type={nom === "title" || nom === "author" ? "text" : "date"}
      />
    );
  };

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}

        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {/* {article_data.length ? ( */}
            <>
              {isLoading && <ArticleSkeleton />}
              {!isLoading && (
                <div className="table-container-activite">
                  <BootstrapTable
                    data={data?.results}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="180"
                      dataField="id"
                      isKey={true}
                      hidden
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="60"
                      dataField="title"
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Image
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="180"
                      dataField="title"
                      // filter={{ type: "TextFilter" }}
                      filter={{
                        type: "CustomFilter",
                        getElement: (filterHandler) =>
                          getCustomTextFilter(filterHandler, "title"),
                      }}
                    >
                      Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="writen_by"
                      // filter={{ type: "TextFilter" }}
                      filter={{
                        type: "CustomFilter",
                        getElement: (filterHandler) =>
                          getCustomTextFilter(filterHandler, "author"),
                      }}
                    >
                      Written by
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="created_at"
                      dataFormat={(cell, row) => getDateRegister(cell, row)}
                      // filter={{ type: "TextFilter" }}
                      filter={{
                        type: "CustomFilter",
                        getElement: (filterHandler) =>
                          getCustomTextFilter(filterHandler, "date"),
                      }}
                    >
                      Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="id"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTable;
