import api from "../../Http/global-vars";
import { FETCH_CATEGORY_ARTICLE_LIST } from "../types";

export const categoryArticleList = () => {
  return async (dispatch) => {
    const url = api + "categoryarticles/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_CATEGORY_ARTICLE_LIST,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
