import axios from "axios";
import { partner } from "./Endpoints";

export default {
  async onAddPartner(data, config) {
    const response = await axios.post(partner.onPostPartner(), data, config);
    return response.data;
  },
  async onEditPartner(id, data, config) {
    const response = await axios.put(partner.onPutPartner(id), data, config);
    return response.data;
  },
  async onDeletePartner(id, config) {
    const response = await axios.delete(partner.deletePartner(id), config);
    return response.data;
  },
};
