import React from 'react'
import './ParaAdmin.css'
import Avatarspara from "../../../images/Avatarspara.png";
import arrowback from "../../../images/arrowback.png";
import HeaderAdmin from '../headerAdmin/HeaderAdmin';
import NavbarVertical from '../nav/NavbarVertical';
import { Link } from 'react-router-dom'


const Paradetail: React.FC = () => {
    return (
      <div className="container-fluid body-background-dashboard">
        <HeaderAdmin />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavbarVertical />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
            <div className="projets-container bloc-principal-body-content">
              <div className="profile-containers">
                <Link to="/admin/setting">
                  <h1 className="mon-profil">
                    <img
                      src={
                        arrowback
                      }
                      alt="parametre"
                    />
                    Administrator
                    detail
                  </h1>
                </Link>
                <div className="d-flex align-items-center flex-column ">
                  <div className="mb-auto para-image-admin">
                    <img
                      src={
                        Avatarspara
                      }
                      alt="parametre"
                    />
                  </div>
                  <div className="text-jones-admin">
                    <h1 className="text-abdou">
                      Abdou
                      Ba
                    </h1>
                  </div>
                </div>
              </div>
              <div className="profile-containers mt-3">
                <form className="form-input-parametre">
                  <div className="container-input-address">
                    <h2 className="information-personnelle-para infos-perso-input">
                      Personal
                      informations
                    </h2>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control-profil"
                            name="last_name"
                            placeholder="Diop"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control-profil"
                            name="first_name"
                            placeholder="Abdou"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Date
                          </label>
                          <input
                            name="date"
                            className="form-control-profil"
                            placeholder="11/02/2020"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                          Address
                          </label>
                          <input
                            type="Addresse"
                            name="addresse"
                            className="form-control-profil"
                            placeholder="Mbao"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Email
                          </label>
                          <input
                            type="Email"
                            name="email"
                            className="form-control-profil"
                            placeholder="email"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="infos-perso-input">
                          <label className="form-label-text">
                            Phone
                          </label>
                          <input
                            type="Telephone"
                            className="form-control-profil"
                            name="telephone"
                            placeholder="7700000"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="profile-containers mt-3">
                <h2 className="infor-personnelle-para infos-perso-input">
                  Permission
                </h2>
                <div className="form-group-container d-flex mb-3">
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Dashboard
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Listing
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Projects
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Agencies
                    </label>
                  </div>
                </div>
                <div className="form-group-container d-flex mb-3">
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Users
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Blog
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Contact
                    </label>
                  </div>
                  <div className="form-group col-md-2 ml-3 form-control-para">
                    <input
                      type="checkbox"
                      id="agreeTerms"
                      className="form-custom-para mt-2 border-5 "
                    />
                    <label
                      htmlFor="agreeTerms"
                      className="custom-label-para mt-2 label-para"
                    >
                      Settings
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <button className="btn-taf-primary">
                  save
                  modifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Paradetail;