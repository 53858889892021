import { AiOutlinePlusCircle } from "react-icons/ai";
import AmenetiesTable from "./AmenetiesTable";
import AddAmeneties from "./ModalParametre/AddAmeneties";

function Ameneties() {
  return (
    <div className="profile-containers p-1">
      <div className="form-input-parametre">
        <div className="row">
          <div className="col">
            <div className="container-profile-edit-reglage d-flex justify-content-between mt-5 mb-5">
              <h1 className="meet-team-title">Amenities</h1>
              <button
                type="button"
                className="btn btn-add-amin-setting"
                data-toggle="modal"
                data-target="#AddAmeneties"
                // onClick={() => setTypeForm("create")}
              >
                <AiOutlinePlusCircle className="btn-addadmin-icon" />
                <span className="">Add Amenities</span>
              </button>
            </div>
            <AmenetiesTable />
            <div
              className="modal fade modal-faq AddAmeneties"
              id="AddAmeneties"
              data-backdrop="static"
              aria-labelledby="AddAmenetiesModalLabel"
              aria-hidden="true"
            >
              <AddAmeneties type="Add" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ameneties;
