import { Delete, EditTwoTone } from "@material-ui/icons/";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import UsersData from "./AgencieData";

const BlogTable = () => {
  const agences = useSelector((state: RootStateOrAny) => state.agences);
  // console.log(agences);

  const [usersData, setUsersData] = React.useState(UsersData);

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const photoFormatter = (cell, row) => {
    return <img src={cell} alt="avatar" className="user-img" />;
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.agence_name}</span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <NavLink to="/accueil" style={{ color: "#9B51E0" }} className="mr-2">
          <IconlyPack.Show />
        </NavLink>
        <NavLink to="#" style={{ color: "#2D3748" }} className="mr-2">
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>
        <NavLink to="#" style={{ color: "#E53E3E" }} className="mr-2">
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}
        <div className="filtres-container">
          <h6 className="filter-label">Filter</h6>
          <div className="filter-input-group">
            <input
              type="text"
              className="filter-input"
              placeholder="Entrer un nom"
            />
          </div>
          <div className="filter-input-group">
            <input
              type="text"
              className="filter-input"
              placeholder="Entrer un nom"
            />
          </div>
        </div>
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {usersData.length ? (
              <>
                <div className="table-container-activite">
                  <BootstrapTable
                    data={agences?.data}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="50"
                      dataField="image"
                      isKey={true}
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Brands
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="name"
                      dataFormat={(cell, row) => nameFormatter(cell, row)}
                    >
                      Agence name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="address"
                    >
                      Address
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="ceo"
                    >
                      CEO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="tax"
                    >
                      Tax number
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="agent_number"
                    >
                      Number of agents
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="total_property"
                    >
                      Total property
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="id"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </>
            ) : (
              <>
                <h2 className="activite-table-title-no-finish">
                  Aucun projet Terminés
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogTable;
