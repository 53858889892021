import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import AjouterListing from "./AjouterListing";

const ModifierListing = () => {
  const { state } = useLocation<DatasListings>();
  const history = useHistory();
  useEffect(() => {
    if (!state) {
      history.goBack();
    }
  }, [state]);
  return <AjouterListing property={state} />;
};

export default ModifierListing;
