import React from "react";
import { Call, ChevronDown, Home, Message, User } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import baseUrl from "../../Http/backend-base-url";
import Logo from "../../images/logo.png";
import Facebook from "../../images/socials/facebook.png";
import Instagram from "../../images/socials/insta.png";
import Twitter from "../../images/socials/twitter.png";
import { useCurrency } from "../../redux/store";
import "./Header.scss";
import { capitalizeText } from "../../utils/utils";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const token = window.localStorage.getItem("userToken");
  const categories_projects = useSelector(
    (s: any) => s.project_types?.data || []
  );
  const socialmediasfront = useSelector(
    (state: RootStateOrAny) => state.socialmediasfront
  );
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userToken");
    window.localStorage.clear();
    //  @ts-ignore
    window.location = "/login";
  };

  const currency = useCurrency();

  const onChangeDevise = (e) => {
    dispatch({
      type: "FETCH_LOCATION_FOR_CONNECTED_USER",
      payload: e.target.value,
    });
  };

  return (
    <section id="header-component">
      <div className="fixed-top-web fixed-top">
        <div className="div-content-header-contact flex-xl-row align-items-xl-center div-content-header-contact-mobile py-lg-1">
          <div className="d-lg-flex justify-content-between  gap-xl">
            <div className="flex-sb-m w-full-mobile gap-xl">
              <div className="p-0 flex-m">
                <label
                  className="no-link text-header view-sm mb-0"
                  htmlFor="currency"
                >
                  Currency
                </label>
                <select
                  name="currency"
                  id="currency"
                  className="ml-1 py-1 px-2 fs-13"
                  value={currency}
                  onChange={onChangeDevise}
                  style={{
                    border: "none",
                    borderRadius: 5,
                    width: "100%",
                  }}
                >
                  <option value="XOF">XOF</option>
                  <option value="GMD">GMD</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
              <div className="header-contact">
                <Call
                  set="light"
                  primaryColor="#646464"
                  style={{
                    width: "16px",
                    marginRight: "0.2rem",
                  }}
                />
                <a
                  className="no-link text-header mr-2"
                  href="tel:+220776-2333"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +220776-2333
                </a>
                |&nbsp;
                <a
                  className="no-link text-header mr-2"
                  href="tel:+220 3762333"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +220 3762333
                </a>
              </div>
            </div>
            <div className="flex-sb-m flex-col-mobile w-full-mobile mb-1">
              <div className="header-contact mr-3 home-svg">
                <Home
                  set="light"
                  primaryColor="#646464"
                  style={{
                    width: "18px",
                    marginRight: "0.2rem",
                  }}
                />
                <p className="text-header mr-3 mb-0">
                  15-17 Kaira Avenue, Madiba Mall Brufut
                </p>
              </div>
              <div className="header-contact mr-3">
                <Message
                  set="light"
                  primaryColor="#646464"
                  style={{
                    width: "18px",
                    marginRight: "0.2rem",
                  }}
                />
                <a
                  className="no-link text-header mr-2"
                  href="mailto:info@tafafricaglobal.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@tafafricaglobal.com
                </a>
              </div>
            </div>
          </div>
          <div className="flex-sb-m">
            <div className="header-contact">
              <div className="dis-flex justify-content-between">
                <a
                  href={socialmediasfront?.data[0]?.instagram ?? "#"}
                  className="socials-icons-header"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="social reseaux header"
                    className="img-social-icon-header ml-img-mobile mr-3"
                  />
                </a>
                <a
                  href={socialmediasfront?.data[0]?.twitter ?? "#"}
                  className="socials-icons-header"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Twitter}
                    alt="social reseaux header"
                    className="img-social-icon-header mr-2"
                  />
                </a>
                <a
                  href={socialmediasfront?.data[0]?.facebook ?? "#"}
                  className="socials-icons-header mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="social reseaux header"
                    className="img-social-icon-header"
                  />
                </a>
              </div>
            </div>
            <div className="header-contact border-none">
              <div className="mr-3">
                <a
                  href="https://resident.estatemate.co.za/login"
                  target={"_blank"}
                  title="Login to property management app for residential estates & complexes"
                  className="menu-link-contact-us ml-2"
                  rel="noopener noreferrer"
                >
                  Property management
                </a>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light headering">
          <a
            className="navbar-brand ml-logo-desktop navbar-brand-costum"
            href="/"
          >
            <img
              src={Logo}
              alt="logo-taf"
              style={{ width: "60px", height: "60px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto desktop-menu-listing ml-auto">
              <li className="menu-item">
                <NavLink
                  to="/"
                  exact
                  className="menu-link"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/about-us"
                  className="menu-link"
                  activeClassName="active"
                >
                  About
                </NavLink>
              </li>
              {/* <li className="menu-item">
                <NavLink
                  to="/projects"
                  className="menu-link"
                  activeClassName="active"
                >
                  Projects
                </NavLink>
              </li> */}
              <li className="menu-item">
                <div className="dropdown">
                  <button
                    className="menu-link dropdown-toggle menu-link-select-header"
                    type="button"
                    id="dropdownButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Projects
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownButton"
                  >
                    <NavLink
                      className="dropdown-item dropdown-item-menu-header"
                      exact
                      to={`/projects`}
                    >
                      All projects
                    </NavLink>
                    {categories_projects.map((cp) => {
                      return (
                        <NavLink
                          exact
                          key={cp.id}
                          className="dropdown-item dropdown-item-menu-header"
                          to={`/projects/${cp.id}/${cp.name}`}
                        >
                          {capitalizeText(cp.name)}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/listing"
                  className="menu-link"
                  activeClassName="active"
                >
                  Rentals & Resales
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  to="/blog"
                  className="menu-link"
                  activeClassName="active"
                >
                  Blog
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  activeClassName="active"
                  to="/contact"
                  className="menu-link"
                >
                  Contact us
                </NavLink>
              </li>
              <li className="menu-item">
                <a
                  href="http://www.tafafricafoundation.org/"
                  target="_black"
                  className="menu-link"
                >
                  TAF Foundation
                </a>
              </li>
              <li className="menu-item">
                <a
                  href="https://tafsalone.com/"
                  target="_black"
                  className="menu-link"
                >
                  TAF Salone
                </a>
              </li>
              
              <li className="menu-item">
                <a
                  href="https://tafnigeria.com/"
                  target="_black"
                  className="menu-link"
                >
                  TAF Nigeria
                </a>
              </li>
            </ul>
            <div className="bloc-icon-menu">
              {!user?.data?.user_type && (
                <div>
                  <NavLink to="/login" className="btn btn-icon-menu-header" aria-label="login">
                    <User set="light" />
                  </NavLink>
                </div>
              )}
              {!!user?.data?.user_type && (
                <div className="connected-user-container">
                  <div className="connected-user-content ml-auto no-view-mobile">
                    <div className="dropdown dis-flex">
                      <button
                        className="user-acount-dashboard-header"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="user-acount-dashboard-header-display">
                          <img
                            src={
                              user.data.avatar ===
                              "/mediafiles/avatars/default.png"
                                ? `https://ui-avatars.com/api/?name=${user.data?.fullname}`
                                : `${baseUrl + user.data.avatar}`
                            }
                            width="45"
                            className="user-avatar"
                            alt="User Avatar"
                          />
                          <ChevronDown set="light" />
                        </div>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-custom"
                        style={{ left: -130 }}
                        aria-labelledby="dropdownMenuButton"
                      >
                        <NavLink
                          to="/"
                          activeClassName="aze"
                          className="dropdown-item item-dropdown-custom"
                        >
                          {user?.data?.fullname ?? "no data"}
                        </NavLink>
                        {user?.data?.user_type !== "usager" && (
                          <>
                            <div className="dropdown-divider"></div>
                            <NavLink
                              className="dropdown-item item-dropdown-custom"
                              to="/dashboard"
                            >
                              Dashboard
                            </NavLink>
                          </>
                        )}
                        <div className="dropdown-divider"></div>
                        <button
                          type="button"
                          className="dropdown-item item-dropdown-custom"
                          onClick={(e) => logout(e)}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
