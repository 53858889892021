import React from "react";
import { BsPlusSquare } from "react-icons/bs";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import ModalAddTestimonials from "./Modal/ModalAddTestimonials";
import "./Testimonials.css";
import TestimonialsTable from "./TestimonialsTable";

const Testimonials: React.FC = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="profile-containers-testimonials">
                <h1 className="liste-utilisateurs-title">Testimonials</h1>
                <button
                  className="btn-taf-partner"
                  data-toggle="modal"
                  data-target="#addtestimonialsModal"
                >
                  <BsPlusSquare className="mr-2 btn-taf-partner-icon" />
                  <span className="">Add opinion</span>
                </button>
              </div>
              <div className="container-profile-edit-setting mt-5">
                {/* <div className="container-profile-edit-reglage">
                                
                            </div> */}

                {/* ========================= TESTIMONIALS TABLES ======================= */}

                <TestimonialsTable />

                {/* ========================= TESTIMONIALS MODALS ======================= */}
                <div
                  className="modal fade modal-faq modal-faq-contact"
                  id="addtestimonialsModal"
                  aria-labelledby="addAdminModalLabel"
                  aria-hidden="true"
                >
                  <ModalAddTestimonials />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
