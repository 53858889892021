import baseUrl from "../Http/backend-base-url";
import defaultImg from "../images/equipe.png"

export function getUrl(text) {
  // regex for extracting url from string
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
  // regex for extracting image url from string
  const regexImageUrl = /(https?:\/\/.*\.(?:png|jpg))/;
  // match url
  const url = text.match(urlRegex);

  if (url) {
    const checkifUrlNotImageUrl = regexImageUrl.test(url);
    if (checkifUrlNotImageUrl) {
      return null;
    }
    return url[0];
  }
  return null;
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function capitalizeText(text: string) {
  if (!text) return "";
  // return text.toLowerCase().replace(/(^\w|\.\s+\w)/g, function (c) {
  //   return c.toUpperCase();
  // });
  // Capitalize the first letter of the entire text
  text = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

  // Split the text by sentences while keeping the delimiters
  let sentences = text.split(/([.!?]\s*)/);

  // Capitalize the first letter of each sentence
  for (let i = 0; i < sentences.length; i++) {
    if (sentences[i].length > 0) {
      sentences[i] =
        sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1);
    }
  }

  // Join the sentences back together
  let capitalizedText = sentences.join("");

  return capitalizedText;
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const getImageFndt = (image) => {
  if (image && !image?.includes("default.png")) {
    if (image.indexOf("http") === 0) {
      if (image.includes(" ")) {
        return image.replace(/ /g, "%20");
      } else {
        return image
      }
    }
    return `${baseUrl}${image}`;
  }
  return defaultImg;
}