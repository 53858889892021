import api from "../../Http/global-vars";
import { ME } from "../types";

export const fetchMe = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "me/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data)
          window.localStorage.setItem("userID", data?.id);
          dispatch({
            type: ME,
            payload: data,
          });
        })
        .catch((error) => {});
    }
  };
};
