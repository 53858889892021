import { yupResolver } from "@hookform/resolvers/yup";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../../Http/backend-base-url";
import groupphoto from "../../../../images/Groupphoto.png";
import { fetchPartners } from "../../../../redux/Partner/PartnerAction";
import { PartnerDatas } from "../../../../repositories/user/PartnerDatas";
import PartnerServices from "../../../../services/Partner";

function ModalAddPartner(props) {
  const dispatch = useDispatch();
  // const socialmedias = useSelector((state: RootStateOrAny) => state.socialmedias);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [image, setImage] = React.useState<any>();
  const [imageDefault, setImageDefault] = React.useState<any>();
  const [hideImageDefault, setHideImageDefault] = React.useState<boolean>();

  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    link_url: yup.string().required("This field is required"),
  });

  const { register, handleSubmit, setValue, formState, clearErrors, reset } =
    useForm<any>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });
  const { errors } = formState;

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    return chosenFiles.map((f) => f.fileObject);
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );

    return (
      <div className="upload-dropzone-row row">
        <div className="col-md-10 offset-md-1">
          {/* {text} */}
          <label htmlFor={`dropzoneImagePartner${props.modalId}`}>{text}</label>
          <input
            style={{
              opacity: 0,
              cursor: "pointer",
            }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              console.log("chosenFiles", chosenFiles[0]);
              if (chosenFiles[0]) {
                setHideImageDefault(true);
              } else {
                setHideImageDefault(false);
              }
              setImage(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id={`dropzoneImagePartner${props.modalId}`}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props?.datas?.id) {
      setValue("name", props?.datas?.name);
      setValue("link_url", props?.datas?.link_url);
      setImageDefault(props?.datas?.logo);
      setHideImageDefault(false);
    }
  }, [props?.datas]);

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(() => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const handleReset = () => {
    if (!props?.datas?.id) {
      reset();
      setHideImageDefault(true);
    }
  };

  const onSubmitPartner = (data: PartnerDatas) => {
    setIsSuccessfullySubmittedForm(true);
    setSubmitedForm(true);

    const fd = new FormData();
    if (image) {
      fd.append("logo", image);
    }
    fd.append("name", data.name);
    fd.append("link_url", data.link_url);
    if (userID) {
      fd.append("created_by", userID);
    }

    const request = props?.datas?.id
      ? PartnerServices.onEditPartner(props?.datas?.id, fd, config)
      : PartnerServices.onAddPartner(fd, config);
    request
      .then((res) => {
        if (res.status === 201 || 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: props?.datas?.id
              ? "Partner updated successfully!"
              : "Partner saved successfully!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            // allowOutsideClick: false,
            timer: 3000,
          }).then((result) => {
            if (result.isConfirmed) {
              $(".modal").modal("hide");
              handleReset();
            }
          });
          setHideImageDefault(false);
          dispatch(fetchPartners());
          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Errorr",
          html: "Partner not saved successfully!,Please review the data entered",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        // reset();
        setSubmitedForm(false);
      });
  };

  return (
    <div
      className="modal-dialog modal-lg modal-dialog-centered"
      data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
    >
      <div
        className="modal-content faq-modal-content"
        data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
      >
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="parametreModal"
          >
            <BsArrowLeft
              className="btn-modal-arrowback"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            />
            {props?.datas?.id
              ? `Update this partner: ${props?.datas?.name}`
              : "Add a partner"}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form
          className="form-input-parametre"
          onSubmit={handleSubmit(onSubmitPartner)}
        >
          <div className="container-input-address container-input-modal-member">
            <div className="form-group-add-project-group-container row">
              <div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
                <label
                  htmlFor="projectImage"
                  className="form-add-project-label"
                >
                  Logo
                </label>
                <Dropzone
                  getUploadParams={() => ({
                    url: "https://httpbin.org/post",
                  })}
                  InputComponent={InputChooseFile}
                  getFilesFromEvent={getFilesFromEvent}
                  accept="image/*"
                  maxFiles={100}
                  styles={{
                    dropzone: {
                      minHeight: 150,
                      maxHeight: 300,
                      border: "2px dashed #d9d9d9",
                      background: "#F3F3F3",
                      overflow: "hidden",
                      opacity: 1,
                    },
                  }}
                  ref={register}
                />
                <small className="text-danger fw-bold d-flex">
                  Max dimension 200x110px
                </small>
                {!hideImageDefault && (
                  <img
                    src={imageDefault && baseUrl + imageDefault}
                    alt=""
                    height="150"
                    width="150"
                    className="my-2"
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Name</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="Name"
                    name="name"
                    data-testid="nameId"
                    ref={register}
                  />
                  {errors.name && (
                    <div
                      className="alert alert-danger sia-alert-danger closer mt-3"
                      role="alert"
                    >
                      {errors.name?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Link URL</label>
                  <input
                    type="text"
                    className="form-control-profil form-control-showoff"
                    placeholder="Link"
                    name="link_url"
                    data-testid="linkUrlId"
                    ref={register}
                  />
                  {errors.link_url && (
                    <div
                      className="alert alert-danger sia-alert-danger closer mt-3"
                      role="alert"
                    >
                      {errors.link_url?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn-back-primary"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleReset()}
              >
                Back
              </button>
              {!isSuccessfullySubmittedForm ? (
                <button className="btn-taf-primary" data-testid="saveBtnId">
                  {props?.datas?.id ? "Update" : "Save"}
                </button>
              ) : (
                <button className="btn-taf-primary">
                  <i className="fas fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                  Progress
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalAddPartner;
