import api from "../../Http/global-vars";
import TestimonialsData from "../../repositories/user/TestimonialsData";
import {
  FETCH_ADD_TESTIMONIALS,
  FETCH_FRONT_TESTIMONIALS,
  FETCH_TESTIMONIALS,
} from "../types";

export const testimonialsAction = () => {
  return async (
    dispatch: (arg0: { type: string; payload: TestimonialsData }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "testimonial/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_TESTIMONIALS,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchTestimonials = () => {
  return async (
    dispatch: (arg0: { type: string; payload: TestimonialsData }) => void
  ) => {
    const url = api + "testimonialfront/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_FRONT_TESTIMONIALS,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const addtestimonials = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "testimonial/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_ADD_TESTIMONIALS,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
