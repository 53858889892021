import api from "../../Http/global-vars";
import { FETCH_COUNTRIES_LIST } from "../types";

export const countriesList = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "country/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_COUNTRIES_LIST,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
