import React, { useEffect } from "react";
import "./Dashboard.css";
import LineChart from "./LineChart/LineChart";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import homedashbord from "../../../images/icons/homedashbord.png";
import home3 from "../../../images/icons/home3.png";
import home4 from "../../../images/icons/home4.png";
import NavBarVertical from "../nav/NavbarVertical";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { dashboardData } from "../../../redux/Graphe/GrapheAction";
import { RootState } from "../../../redux/store";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  let {
    dashboard = {
      properties_for_rent_count: 0,
      properties_for_sale_count: 0,
      reservations_count: 0,
      projects_count: 0,
    },
    isLoading,
  } = useSelector((state: RootState) => state.grapheData);

  useEffect(() => {
    dispatch(dashboardData());
  }, [dispatch]);

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />

      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVertical />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-md-3 px-0">
          <div className="container-fluid">
            <div className="projets-container bloc-principal-body-content">
              <div className="dashboard-card-container d-flex flex-column flex-md-row">
                <NavLink to={"/admin/properties"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={homedashbord} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total property</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {dashboard?.properties_for_sale_count}{" "}
                      <small className="dasboard-card-title">On sale</small>
                    </h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/properties"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={homedashbord} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total property</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {dashboard?.properties_for_rent_count}{" "}
                      <small className="dasboard-card-title">For rent </small>
                    </h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/reservations"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={home3} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total reservations</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {dashboard?.reservations_count}
                    </h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/projets"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={home4} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total projects</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {dashboard?.projects_count}
                    </h3>
                  </div>
                </NavLink>
              </div>
              <div className="dashboard-chart-container">
                <LineChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
