/*  */
import React, { useEffect } from "react";
import DatasAmeneties from "../../../../repositories/user/ameneties/DatasAmeneties";
import UseRequestAmenetiesForm from "../request/UseRequestAmenetiesForm";

type propsType = {
  type: string;
  item?: DatasAmeneties;
};
function AddAmeneties({ type, item }: propsType) {
  const {
    register,
    setValue,
    clearErrors,
    isSuccessfullySubmittedForm,
    reset,
    formState,
    onSubmit,
    unregister,
  } = UseRequestAmenetiesForm();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  useEffect(() => {
    if (item?.id) {
      register("id");
      setValue("id", item?.id);
      setValue("name", item?.name);
    } else {
      unregister("id");
    }
  }, [item, setValue, type]);

  const handleReset = () => {
    if (!item?.id) {
      reset();
    }
  };

  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5 className="modal-title titre-question-reponse" id="AboutModal">
            {type}
            &nbsp;&nbsp;amenity{" "}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="modal-body">
            <div className="container-input-address-modal">
              <div className="row">
                <div className="col-md-12">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">Name</div>
                    <input
                      className="form-control-profil text-dark"
                      placeholder="Name"
                      name="name"
                      data-testid="nameId"
                      ref={register}
                    />
                    {formState?.errors?.name && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState?.errors?.name?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
              onClick={() => handleReset()}
            >
              Back
            </button>

            <div className="d-flex justify-content-end">
              {!isSuccessfullySubmittedForm ? (
                <button
                  className="btn-taf-primary"
                  type="submit"
                  data-testid="sendBtnId"
                >
                  Save
                </button>
              ) : (
                <span className="btn-taf-primary">
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                  Progress...
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAmeneties;
