import { DEFAULT_CURRENCY } from "../../utils/convert_currency";
import { FETCH_LOCATION_FOR_CONNECTED_USER } from "../types";

export const getDatasLocationForConnectedUserReducer = (
  state = DEFAULT_CURRENCY,
  action
) => {
  switch (action.type) {
    case FETCH_LOCATION_FOR_CONNECTED_USER:
      return action.payload;
    default:
      return state;
  }
};
