import Axios from "axios";
import api from "../../Http/global-vars";
import { LocationFormData } from "../../repositories/user/LocationFormData";
import { FETCH_LOCATIONS, FETCH_LOCATIONS_FRONT } from "../types";

export const fetchLocations = () => {
  return async (
    dispatch: (arg0: { type: string; payload: LocationFormData }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "location/?limit=1000";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_LOCATIONS,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchLocationsFront = () => {
  return async (
    dispatch: (arg0: { type: string; payload: LocationFormData }) => void
  ) => {
    const url = api + "locationfront/?limit=1000";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_LOCATIONS_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const listLocationsDatasToTest = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  try {
    return await Axios.get(`${api}location`);
  } catch (e) {
    return [];
  }
};
