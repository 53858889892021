import { FETCH_FEATURED_PROJECT_OR_PROPERTY } from "../types";

export const allfeaturedFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_FEATURED_PROJECT_OR_PROPERTY:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};