import axios from "axios";
import { location } from "./Endpoints";

export default {
  async onAddLocation(data, config) {
    const response = await axios.post(location.onPostLocation(), data, config);
    return response.data;
  },
  async onEditLocation(id, data, config) {
    const response = await axios.put(location.onPutLocation(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteLocation(id, config) {
    const response = await axios.delete(location.deleteLocation(id), config);
    // console.log(response)
    return response.data;
  },
};
