import { FETCH_ADMINS } from "../types";

export const adminsReducer = (
  state = { isLoading: true, data: { results: [], count: 0 }  },
  action
) => {
  switch (action.type) {
    case FETCH_ADMINS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
