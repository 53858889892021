import axios from "axios";
import { project } from "./Endpoints";

export default {
  async onAddProject(data, config) {
    const response = await axios.post(project.onPostProject(), data, config);
    return response.data;
  },
  async onEditProject(id, data, config) {
    const response = await axios.put(project.onPutProject(id), data, config);
    // console.log(response)
    return response.data;
  },

  async onDeleteProject(id, config) {
    const response = await axios.delete(project.onDeleteProject(id), config);
    // console.log(response)
    return response.data;
  },
  async onAvantProject(id, type, config) {
    const response = await axios.get(project.onMiseAvant(id, type), config);
    // console.log(response)
    return response.data;
  },
};
