import api from "../../Http/global-vars";
import { PartnerDatas } from "../../repositories/user/PartnerDatas";
import { FETCH_PARTNER, FETCH_PARTNER_FRONT } from "../types";

export const fetchPartners = () => {
  return async (
    dispatch: (arg0: { type: string; payload: PartnerDatas }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "partner/";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_PARTNER,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const fetchPartnersFront = () => {
  return async (
    dispatch: (arg0: { type: string; payload: PartnerDatas }) => void
  ) => {
    const url = api + "partnerfront/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_PARTNER_FRONT,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
