import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ArrowLeft } from "react-iconly";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import Bathroom2 from "../../../images/icons/bathtub.png";
import Bed2 from "../../../images/icons/bed.png";
import ChatImg from "../../../images/icons/chat-listing.png";
import Kitchen2 from "../../../images/icons/kitchen.png";
import PaperImg from "../../../images/icons/paper-listing.png";
import ShowImg from "../../../images/icons/show-listing.png";
import { Amineties } from "../../user/listing/AmnetieItem";
import { Gallery } from "../../user/projets/DetailsProjet";
import PlayerVideo from "../../user/shared/PlayerVideo";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import { GoogleMap } from "./GoogleMap";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import api from "../../../Http/global-vars";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { listReservations, listReservationsByProperty } from "../../../redux/Reservations/reservationAction";
import { fetchReviewsByProperty } from "../../../redux/reviews/reviewsAction";

const DetailsListingAdmin = (props) => {
  const { id } = useParams<any>();
  const commentRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const property_datas = location.state?.row;
  // const reviews = location.state?.reviews?.data;
  // const reservations = location.state?.reservations?.data;
  const reviews =
    useSelector((state: RootStateOrAny) => state.review_property?.data) || [];
  const reservationCount =
    useSelector((state: RootStateOrAny) => state.reservationByProperty?.data?.count) ||
    0;

  // const review_datas =
  //   reviews && reviews.filter((x: any) => x.property_id === property_datas?.id);
  
  const [propertyItem, setPropertyItem] = useState<any>(property_datas);
  const [comments, setComments] = useState([]);

  const defaultProps = {
    center: {
      lat: parseFloat(propertyItem?.latitude || 0),
      lng: parseFloat(propertyItem?.longitude || 0),
    },
    zoom: 11,
  };

  const pictures =
    propertyItem?.pictures?.length > 0 ? propertyItem?.pictures : [];
  useEffect(() => {
    if (propertyItem?.id) {
      dispatch(listReservationsByProperty({ page: 1, limit: 100,id: propertyItem?.id }));
    }
  }, [propertyItem]);

  const detailProject = async (id: number) => {
    const url = api + "listingbyvisitorbyslug/" + id;
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch(fetchReviewsByProperty(data?.id));
        setPropertyItem(data);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (id) {
      detailProject(id);
    }
  }, [id]);
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <div className="d-flex align-items-center">
                  <Link to="/admin/properties" className="btn btn-add-listing">
                    <ArrowLeft set="light" primaryColor="#B0B0B0" />
                  </Link>
                  <h1 className="mon-profil pl-2">
                    {" "}
                    {propertyItem?.property_name}
                    {propertyItem?.property_type?.name
                      ? ", " + propertyItem?.property_type?.name
                      : ""}{" "}
                  </h1>
                </div>
              </div>
              <div className="bloc-principal-body-content p-0">
                <div className="dashboard-card-container d-flex flex-column flex-md-row">
                  <div className="dashboard-card card-details-listing">
                    <div className="">
                      <img src={ShowImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total views</h1>
                      <h3 className="dasboard-card-montant">
                        {propertyItem?.vus}{" "}
                        <small className="dasboard-card-title">Views </small>
                      </h3>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (commentRef.current) {
                        //@ts-ignore
                        commentRef.current.scrollIntoView();
                      }
                    }}
                    className="dashboard-card card-details-listing cursor-pointer"
                  >
                    <div className="">
                      <img src={ChatImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total comment</h1>
                      <h3 className="dasboard-card-montant">
                        {reviews.length}{" "}
                        <small className="dasboard-card-title">
                          {reviews.length > 1 ? "Comments" : "Comment"}{" "}
                        </small>
                      </h3>
                    </div>
                  </div>
                  <NavLink
                    className="dashboard-card card-details-listing"
                    to={{
                      pathname: `/admin/property/reservations/${propertyItem?.slug}`,
                      state: { property: propertyItem },
                    }}
                  >
                    <div className="">
                      <img src={PaperImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total reservation</h1>
                      <h3 className="dasboard-card-montant">
                        {reservationCount}{" "}
                        <small className="dasboard-card-title">
                          {reservationCount > 1
                            ? "Reservations"
                            : "Reservation"}
                        </small>
                      </h3>
                    </div>
                  </NavLink>
                </div>
                <div className="project-container ">
                  <Gallery
                    pictures={pictures}
                    image={propertyItem?.image}
                    name={propertyItem?.property_name}
                  />
                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Detail & features</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-item">
                        <img src={Bed2} alt="bed" />
                        <h6 className="det-feat-title">
                          {propertyItem?.bedroom}
                          &nbsp; BEDROOM
                        </h6>
                      </div>
                      <div className="det-feat-item">
                        <img src={Bathroom2} alt="bathroom-img" />
                        <h6 className="det-feat-title">
                          {propertyItem?.bathroom}
                          &nbsp; BATHROOM
                        </h6>
                      </div>
                      <div className="det-feat-item">
                        <img src={Kitchen2} alt="bathroom-img" />
                        <h6 className="det-feat-title">
                          {propertyItem?.kitchen}
                          &nbsp; KITCHEN
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Description</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-ite">
                        <p
                          className="cla-text"
                          dangerouslySetInnerHTML={{
                            __html: propertyItem?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title">Amenities</h2>
                    <Amineties amneties={propertyItem?.ameneties} />
                  </div>
                  {!!propertyItem?.video_presentation && (
                    <div className="card-listing mt-4 p-3">
                      <h2 className="mid-listing-title mb-3">Property video</h2>
                      <PlayerVideo
                        url={propertyItem?.video_presentation}
                        showControls={false}
                      />
                    </div>
                  )}
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title mb-3">Map</h2>

                    <GoogleMap defaultProps={defaultProps} />
                  </div>
                  {!!propertyItem?.virtual_video && (
                    <div className="card-listing mt-3 p-3">
                      <div className="flex-sb">
                        <h2 className="mid-listing-title mb-3">
                          Virtual visit
                        </h2>
                      </div>
                      <PlayerVideo
                        url={propertyItem?.virtual_video}
                        showControls={false}
                      />
                    </div>
                  )}
                  <div ref={commentRef} className="card-listing mt-3 p-3">
                    <h2 className="mid-listing-title mb-3">
                      Comments ({reviews?.length})
                    </h2>
                    <div className="container-taf">
                      <div className="row">
                        {!!reviews?.length &&
                          reviews?.map((review_data: any) => {
                            return (
                              <div className="col-12">
                                <div className="bloc-div-item-service">
                                  <div>
                                    <img
                                      src={
                                        review_data?.image
                                          ? `${baseUrl + review_data?.image}`
                                          : `https://ui-avatars.com/api/?name=${review_data?.name}`
                                      }
                                      alt="service"
                                      className="img-service"
                                    />
                                  </div>
                                  <div className="pl-3">
                                    <div className="d-flex">
                                      <h3 className="titre-section-service">
                                        {review_data?.name} <br />
                                        <span
                                          style={{ fontSize: 12, opacity: 0.6 }}
                                        >
                                          {review_data?.email}
                                        </span>{" "}
                                        <br />
                                        <span className="text-date-listing w-100">
                                          {moment(
                                            review_data.created_at
                                          ).format("YYYY-MM-DD")}
                                        </span>
                                      </h3>
                                    </div>
                                    <p className="text-section-services">
                                      {review_data?.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsListingAdmin;
