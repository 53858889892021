import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../Http/global-vars";
import { listProperties } from "../../../redux/ListProperty/listPropertyAction";
import DatasPropertyTypes from "../../../repositories/user/propertyTypes/DatasPropertyTypes";

export function getErrorFromResponse(
  ex: any,
  defaultMessage = "Oops! Une erreur est survenue"
) {
  const data = ex?.response?.data;
  if (data) {
    if (typeof data === "string") return data;
    if (data?.message) return data?.message;
    const errors: string[] = [];
    const keys = Object.keys(data);
    keys.map((d) => {
      const mess = data[d][0];
      errors.push(`${d}: ${mess}`);
    });
    if (errors.length > 0) {
      return errors.join(" \n");
    }
  }

  return `${defaultMessage}. ${ex?.message || ""}`;
}

function ImportProperties() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [placeholder_file, setPlaceholder_file] = useState("Import excel file");
  const property_types = useSelector(
    (state: RootStateOrAny) => state.property_types
  );
  const locations = useSelector((state: RootStateOrAny) => state.locations);
  const dispatch = useDispatch();
  //   const [error, setError] = React.useState(null);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        fichier: yup.mixed().required("File is required").nullable(),
        property_type: yup.string().required("The property type is required"),
        location_id: yup.string().required("The location is required"),
      }),
    []
  );

  const { handleSubmit, formState, clearErrors, reset, register, setValue } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors]);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const handleChangeFile = (e) => {
    console.log("object", e.target.files);
    setValue("fichier", e.target.files[0]);
    setPlaceholder_file(e.target.files[0]?.name);
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log("data", data);
    const fd = new FormData();
    // data?.category.forEach((element) => {
    //   fd.append("category", element);
    // });
    fd.append("property_type", data?.property_type);
    fd.append("location_id", data?.location_id);
    fd.append("fichier", data?.fichier);
    await Axios.post(`${api}importpropertyexcel/`, fd, requestConfig)
      .then(async (res) => {
        console.log("res", res);
        if (
          (res.status === 200 || res.status === 201) &&
          res?.data?.status === "success"
        ) {
          await dispatch(listProperties({}));
          await Swal.fire({
            icon: "success",
            title: "Import file success.",
            showConfirmButton: false,
            timer: 1000,
          });
          await $(".close").click();
          //   toast.success("Mot de passe modifié avec succés.");
          await e.target.reset();
          await reset();
          await setIsLoading(false);
          //   setOptionShop("");
          await setPlaceholder_file("Import excel file");
        } else if (res?.data?.message) {
          Swal.fire({
            icon: "error",
            title: res?.data?.message,
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const message = getErrorFromResponse(
          error,
          "Oops! Une erreur est survenue lors de la soumission. Veuillez vérifier si le modèle est respecté."
        );
        Swal.fire({
          icon: "error",
          title: message,
          showConfirmButton: false,
          timer: 5000,
        });
      });
  };

  const handleReset = () => {
    reset();
    setPlaceholder_file("Import excel file");
  };

  return (
    <div className="modal-dialog modal-md modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="ImportPropertyModal"
          >
            Import Property
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="container-input-address-modal">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <div className="form-label-text text-label-modal">
                      File <sup className="text-danger h6">*</sup>
                    </div>
                    {/* <input
                      className="form-control-profil text-dark importInput"
                      placeholder="Name"
                      name="name"
                      data-testid="nameId"
                      // ref={register}
                    /> */}
                    <div className="custom-file">
                      <input
                        type="file"
                        name="fichier"
                        className="custom-file-input input-custom-import-file"
                        id="customFile"
                        accept=".xlsx"
                        onChange={handleChangeFile}
                        // @ts-ignore
                        {...register("fichier")}
                      />
                      <label
                        className="custom-file-label label-custom-import-file"
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "10px",
                        }}
                        htmlFor="customFile"
                      >
                        {placeholder_file}
                      </label>
                      {formState?.errors?.fichier && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {formState?.errors?.fichier?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label-text">
                      Location <sup className="text-danger h6">*</sup>
                    </label>
                    <select
                      className="form-control-profil importInput"
                      id="exampleFormControlSelect3"
                      name="location_id"
                      ref={register}
                    >
                      <option value={""} hidden>
                        Select an option
                      </option>
                      {locations?.data?.length > 0 &&
                        locations.data?.map((item: any) => {
                          return (
                            <option
                              key={`${item.id}-location--`}
                              value={item.id}
                            >
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                    {formState?.errors?.location_id && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState?.errors?.location_id?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3" style={{ marginBottom: ".5rem" }}>
                    <label className="form-label-text">
                      Property type <sup className="text-danger h6">*</sup>
                    </label>
                    <select
                      className="form-control-profil importInput"
                      id="exampleFormControlSelect3"
                      name="property_type"
                      ref={register}
                    >
                      <option value={""} hidden>
                        Select an option
                      </option>
                      {property_types?.data?.length > 0 &&
                        property_types.data?.map((item: DatasPropertyTypes) => {
                          return (
                            <option key={`${item.id}-property`} value={item.id}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                    {formState?.errors?.property_type && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState?.errors?.property_type?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
              onClick={() => handleReset()}
            >
              Back
            </button>

            <div className="d-flex justify-content-end">
              {!isLoading ? (
                <button
                  className="btn-taf-primary"
                  type="submit"
                  data-testid="sendBtnId"
                >
                  Save
                </button>
              ) : (
                <span className="btn-taf-primary">
                  <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                  Progress...
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ImportProperties;
