import React, { Suspense, lazy, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import Agencieadmin from "./components/admin/AgencieAdmin/AgencieAdmin";
import Agenciesmarthouse from "./components/admin/AgencieAdmin/AgencieSmartHouse";
import Smarthouse from "./components/admin/AgencieAdmin/SmartHouse";
import Articleadmin from "./components/admin/Article/ArticleAdmin";
import Articledetail from "./components/admin/Article/ArticleDetail";
import ArticledetailUpdate from "./components/admin/Article/ArticleDetailUpdate";
import CategoryArticles from "./components/admin/Article/CategoryArticles";
import ShowArticledetail from "./components/admin/Article/ShowArticledetail";
import Contactadmin from "./components/admin/ContactAdmin/ContactAdmin";
import Dashboard from "./components/admin/Dashboard/Dashboard";
import AjouterListing from "./components/admin/ListingAdmin/AjouterListing";
import DetailsListingAdmin from "./components/admin/ListingAdmin/DetailsListingAdmin";
import Listingadmin from "./components/admin/ListingAdmin/ListingAdmin";
import ModifierListing from "./components/admin/ListingAdmin/ModifierListing";
import Reservations from "./components/admin/ListingAdmin/Reservations";
import ReservationsByProject from "./components/admin/ListingAdmin/ReservationsByProject";
import ReservationsByProperty from "./components/admin/ListingAdmin/ReservationsByProperty";
import LocationPropertyAccueil from "./components/admin/LocationPropriete/LocationPropertyAccueil";
import Newsletter from "./components/admin/Newsletter/Newsletter";
import ListSliders from "./components/admin/ParaAdmin/ListeSliders";
import Parametreadmin from "./components/admin/ParaAdmin/ParaAdmin";
import Paradetail from "./components/admin/ParaAdmin/ParaDetail";
import Profiladmin from "./components/admin/ProfileAdmin/ProfileAdmin";
import AjouterProjet from "./components/admin/ProjetAdmin/AjouterProjet";
import DetailsProjetsAdmin from "./components/admin/ProjetAdmin/DetailsProjetAdmin";
import ModifierProjet from "./components/admin/ProjetAdmin/ModifierProjet";
import Projetadmin from "./components/admin/ProjetAdmin/ProjetAdmin";
import Testimonials from "./components/admin/Testimonials/Testimonials";
import Useradmin from "./components/admin/Users/Users";

import Showresultat from "./components/user/front-home/ShowResultat";

import isAuthenticate from "./modules/secure/secure";
import { fetchAbouts, fetchAboutsFront } from "./redux/AboutAdmin/AboutAction";
import {
  memberList,
  memberListFront,
} from "./redux/AddNewMemberSetting/ourTeamMemberAction";
import { agences } from "./redux/Agence/agenceAction";
import { articleList, articleListFront } from "./redux/Article/articleAction";
import { listProperties } from "./redux/ListProperty/listPropertyAction";
import {
  listPropertiesToFront,
  listPropertiesToFrontGET,
} from "./redux/ListPropertyToFront/listPropertyToFrontAction";
import {
  fetchLocations,
  fetchLocationsFront,
} from "./redux/Location/LocationAction";
import { fetchNewsletterAdmin } from "./redux/NewsletterAdmin/NewsletterAdminAction";
import {
  fetchPartners,
  fetchPartnersFront,
} from "./redux/Partner/PartnerAction";
import { projectTypeAction } from "./redux/ProjectType/projectTypeAction";
import { fetchProjectTypes } from "./redux/ProjectTypes/ProjectTypesAction";
import { fetchPromote, fetchPromoteGet } from "./redux/Promote/PromoteAction";
import { propertyTypes } from "./redux/PropertyType/propertyTypeAction";
import { propertyTypesFront } from "./redux/PropertyTypeFront/propertyTypeFrontAction";
import { listReservations } from "./redux/Reservations/reservationAction";
import { responseContactList } from "./redux/ResponseContact/ResponseContactAction";
import { fetchShowOf, fetchShowOfFront } from "./redux/ShowOf/ShowOfAction";
import { fetchSlider, fetchSlidersFront } from "./redux/Slider/SliderAction";
import {
  fetchSocialMedias,
  fetchSocialMediasFront,
} from "./redux/SocialMedia/SocialMediaAction";
import {
  fetchTestimonials,
  testimonialsAction,
} from "./redux/Testimonials/TestimonialsAction";
import { fetchAdmins } from "./redux/admins/adminsAction";
import { ameneties, amenetiesToFront } from "./redux/ameneties/amenetieAction";
import { categoryArticleList } from "./redux/categoryArticles/categoryArticlesAction";
import { contactList } from "./redux/contact/contactAction";
import { countriesList } from "./redux/countries/countriesAction";
import { fetchFeaturedProjectOrProperty } from "./redux/featuredProjectOrProperty/featuredProjectOrPropertyAction";
import {
  getCurrentUserInfos,
  getDatasLocationForConnectedUser,
} from "./redux/getLocationForConnectedUser/getLocationForConnectedUserAction";
import { fetchProjects } from "./redux/projects/projectAction";
import { fetchProjectsFront } from "./redux/projectsFront/projectsFrontAction";
import { fetchReviews } from "./redux/reviews/reviewsAction";
import { RootState, useCurrency } from "./redux/store";
import {
  fetchTafdata,
  fetchTafdataFront,
} from "./redux/tafDataAdmin/tafDataAction";
import {
  fetchtimelineFront,
  fetchtimelines,
} from "./redux/timelineAdmin/TimeLineAction";
import { fetchMe } from "./redux/user/userActions";
import { convertCurrency } from "./utils/convert_currency";
import NotFoundPage from "./NotFoundPage";
import { getConvertCode } from "./redux/convert/convertAction";
import { dashboardData } from "./redux/Graphe/GrapheAction";
import LoaderSuspense from "./modules/Loader/LoaderSuspense";
import AdminLayout from "./components/admin/Layout/AdminLayout";
import { adsList, adsListFront } from "./redux/ads/adsAction";
import AdsAdmin from "./components/admin/AdsAdmin/AdsAdmin";
import AddOrEditAds from "./components/admin/AdsAdmin/AddOrEditAds";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );
const HomePage = Loader(
  React.lazy(() => import("./components/user/front-home/FrontHome"))
);
const Login = Loader(React.lazy(() => import("./components/auth/Login")));
const ModifierPassword = Loader(
  React.lazy(() => import("./components/auth/ModifierPassword"))
);
const ResetPassword = Loader(
  React.lazy(() => import("./components/auth/ResetPassword"))
);
const AboutUs = Loader(
  React.lazy(() => import("./components/user/about-us/AboutUs"))
);
const Blog = Loader(lazy(() => import("./components/user/blog/Blog")));
const DetailBlog = Loader(
  lazy(() => import("./components/user/blog/DetailBlog"))
);
const Checkout = Loader(
  lazy(() => import("./components/user/checkout/Checkout"))
);
const Contact = Loader(lazy(() => import("./components/user/contact/Contact")));
const Listing = Loader(lazy(() => import("./components/user/listing/Listing")));
const ListingItem = Loader(
  lazy(() => import("./components/user/listing/ListingItem"))
);
const ProfilUser = Loader(
  lazy(() => import("./components/user/profil/ProfilUser"))
);
const DetailsProjet = Loader(
  lazy(() => import("./components/user/projets/DetailsProjet"))
);
const Projets = Loader(lazy(() => import("./components/user/projets/Projets")));

const App: React.FC = () => {
  const [userInfos, setUserInfos] = React.useState<any>(null);
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const { origin, data = { results: [], count: 0 } } = useSelector(
    (state: RootState) => state.list_properties_front
  );
  const device = useSelector((state: RootState) => state.localisation_of_user);
  const currency = useCurrency();
  const gmb = useSelector((state: RootState) => state.gmb);
  const promote = useSelector((state: RootState) => state.promote?.data);
  useEffect(() => {
    sessionStorage.removeItem("shopromoteModal");
    sessionStorage.removeItem("showAdsModal");
    window.scrollTo(0, 0);
  }, []);

  const token = window.localStorage.getItem("userToken");
  const isLogInExpired = isAuthenticate();
  const userType = window.localStorage.getItem("userType");
  React.useEffect(() => {
    getCurrentUserInfos().then((ui) => {
      setUserInfos(ui);
      dispatch(getDatasLocationForConnectedUser(ui?.currency));
    });
  }, []);
  useEffect(() => {
    if (currency && userInfos) {
      dispatch(
        fetchPromote({
          currency_from: "GMD",
          currency_to: userInfos?.currency || currency,
        })
      );
    }
  }, [currency, userInfos]);
  useEffect(() => {
    dispatch(adsListFront());
    dispatch(fetchProjectTypes());
    dispatch(propertyTypesFront());
    dispatch(amenetiesToFront());
    dispatch(fetchSocialMediasFront());
    dispatch(categoryArticleList());
    dispatch(fetchLocationsFront());
  }, [dispatch]);

  useEffect(() => {
    if (token && (userType === "admin" || userType === "superadmin")) {
      // dispatch(listProperties({}));
      dispatch(propertyTypes());
      // dispatch(testimonialsAction());
      dispatch(projectTypeAction());
      // dispatch(fetchProjects({}));
      dispatch(ameneties());
      // dispatch(agences());
      // dispatch(listReservations());
      // dispatch(fetchAdmins());
      dispatch(fetchLocations());
      // dispatch(fetchAbouts());
      // dispatch(fetchTafdata());
      // dispatch(fetchtimelines());
      // dispatch(memberList());
      // dispatch(fetchPartners());
      // dispatch(fetchSocialMedias());
      // dispatch(articleList({}));
      dispatch(countriesList());
      dispatch(categoryArticleList());
      // dispatch(fetchSlider());
      // dispatch(fetchShowOf());
      // dispatch(contactList());
      // dispatch(responseContactList());
      // dispatch(fetchNewsletterAdmin());
    }
  }, [dispatch, token, userType]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
    }
  }, [dispatch, token]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/login" component={Login} />
        {/* <Route exact={true} path="/register" component={Register} /> */}
        <Route exact={true} path="/" component={HomePage} />
        <Route exact={true} path="/show-resultats" component={Showresultat} />
        <Route exact={true} path="/about-us" component={AboutUs} />
        <Route exact={true} path="/listing" component={Listing} />
        <Route exact={true} path="/projects" component={Projets} />
        <Route exact={true} path="/projects/:id/:name" component={Projets} />
        <Route
          exact={true}
          path="/project-details/:slug"
          component={DetailsProjet}
        />
        <Route exact={true} path="/blog/category/:slug" component={Blog} />
        <Route exact={true} path="/blog" component={Blog} />
        <Route exact={true} path="/contact" component={Contact} />
        <Route exact={true} path="/blog/:id" component={DetailBlog} />
        <Route exact={true} path="/profil-user" component={ProfilUser} />
        <Route exact={true} path="/listings/:slug" component={ListingItem} />
        <Route exact={true} path="/checkout" component={Checkout} />
        {/* <Route exact={true} path="/dashboard" component={Dashboard} /> */}
        <Route path="/dashboard">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Dashboard />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/profiles">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Profiladmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/setting">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Parametreadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/para-admin-detail">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Paradetail />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/contact">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Contactadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/newsletter">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Newsletter />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/users">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Useradmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/agencies">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Agencieadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/agencie/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Agenciesmarthouse />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/agencie-smart/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Smarthouse />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/articles">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Articleadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/article-add">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Articledetail />
            </AdminLayout>
          )}
        </Route>
        <Route path={`/admin/article-detail-update/:id`}>
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ArticledetailUpdate />
            </AdminLayout>
          )}
        </Route>
        <Route path={`/admin/show-article-detail/:id`}>
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ShowArticledetail />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/projets">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Projetadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/ajouter-projet">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <AjouterProjet />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/modifier-projet/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ModifierProjet />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/properties">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Listingadmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/property/reservations/:slug">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ReservationsByProperty />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/project/reservations/:slug">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ReservationsByProject />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/details-property/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <DetailsListingAdmin />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/project-details/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <DetailsProjetsAdmin />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/reservations">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Reservations />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/add-property">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <AjouterListing />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/modifier-listing/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ModifierListing />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/location-property">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <LocationPropertyAccueil />
            </AdminLayout>
          )}
        </Route>

        <Route path="/modifierpassword" component={ModifierPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/category-articles" component={CategoryArticles} />

        <Route path="/admin/sliders">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <ListSliders />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/testimonials">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <Testimonials />
            </AdminLayout>
          )}
        </Route>
        <Route path="/admin/ads">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <AdsAdmin />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/ads-add">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <AddOrEditAds />
            </AdminLayout>
          )}
        </Route>

        <Route path="/admin/ads-edit/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AdminLayout>
              <AddOrEditAds />
            </AdminLayout>
          )}
        </Route>

        {/* Not Found */}
        <Route path="/404" component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
