import React, { useEffect } from "react";
import Champstable from "./LesTable/ChampsTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalLocation from "./LesModals/ModalLocation";
import { useDispatch } from "react-redux";
import { fetchLocations } from "../../../redux/Location/LocationAction";

const LocationPropriete: React.FC =
  () => {
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(fetchLocations());
    }, [dispatch])
    return (
      <div className="container-table-location">
        <button
          type="button"
          data-toggle="modal"
          data-target="#locationModal"
          className="btn btn-add-amin-setting ml-auto"
        >
          <AiOutlinePlusCircle className="btn-addadmin-icon" />
          <span className="">
          Add Location
          </span>
        </button>
        <div
          className="modal fade modal-faq modal-faq-contact"
          id="locationModal"
          aria-labelledby="addAdminModalLabel"
          aria-hidden="true"
        >
          <ModalLocation/>
        </div>
        <div className="profile-containers">
          <div className="containter-table-admin mt-5">
              <Champstable />
          </div>
        </div>
      </div>
    );
  };

export default LocationPropriete;
