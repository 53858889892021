import React from "react";
import PropertyTypeTable from "./LesTable/PropertyTypeTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalPropertyType from "./LesModals/ModalPropertyType";

const PropertyType: React.FC =
  () => {
    return (
      <div className="container-table-location">
        <button
          type="button"
          data-toggle="modal"
          data-target="#ProprietetypeModal"
          className="btn btn-add-amin-setting ml-auto"
        >
          <AiOutlinePlusCircle className="btn-addadmin-icon" />
          <span className="">
          Add Property Types
          </span>
        </button>
        <div
          className="modal fade modal-faq modal-faq-contact"
          id="ProprietetypeModal"
          aria-labelledby="addAdminModalLabel"
          aria-hidden="true"
        >
          <ModalPropertyType />
        </div>
        <div className="profile-containers">
          <div className="containter-table-admin mt-5">
              <PropertyTypeTable />
          </div>
        </div>
      </div>
    );
  };

export default PropertyType;
