import api from "../../Http/global-vars";
import { FilterType } from "../Article/articleAction";
import { FETCH_ADMINS } from "../types";

export const fetchAdmins = ({ page = 1, limit = 10 }: FilterType) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + `adminuser/?page=${page}&limit=${limit}`;
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();

        dispatch({
          type: FETCH_ADMINS,
          payload: data,
          // .filter((user) => !user?.deleted)
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ADMINS,
          payload: {
            results: [],
            count: 0,
          },
        });
        console.log("error", error?.response);
      });
  };
};
