import axios from "axios";
import { property } from "./Endpoints";

export default {
  async onAddProperties(data, config) {
    const response = await axios.post(
      property.onPostProperties(),
      data,
      config
    );
    return response.data;
  },
  async onEditProperties(id, data, config) {
    const response = await axios.put(
      property.onPutProperties(id),
      data,
      config
    );
    // console.log(response)
    return response.data;
  },

  async onDeleteProperties(id, config) {
    const response = await axios.delete(
      property.onDeleteProperties(id),
      config
    );
    // console.log(response)
    return response.data;
  },
  async onAvantPropretie(id, type, config) {
    const response = await axios.get(property.onMiseAvant(id, type), config);
    // console.log(response)
    return response.data;
  },
};
