import { FETCH_LOCATIONS, FETCH_LOCATIONS_FRONT } from "../types";

export const locationsReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};

export const locationsFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_LOCATIONS_FRONT:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};