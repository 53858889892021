import React from "react";
import "./Skeleton.css";

interface SkeletonTextProps {
  variant: "text";
  width?: number | String;
  height?: number | String;
  margin?: String | number;
  className?: string;
}

interface SkeletonShapeProps {
  variant: "rect" | "circle";
  width?: number | String;
  height?: number | String;
  margin?: String | number;
  className?: string;
}

type SkeletonProps = SkeletonTextProps | SkeletonShapeProps;
export default function Skeleton(props: SkeletonProps) {
  const style: any = {};
  if (props.variant) {
    style.width = props.width;
    style.height = props.height;
    style.margin = props.margin;
  }

  return (
    <div
      className={`Skeleton Skeleton-${props.variant} ${props.className ?? ""}`}
      style={style}
    />
  );
}
